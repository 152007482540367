import React from 'react'
import TdLibController from './../../../Controllers/TdLibController';

export async function GetChannels() {

  
        const chats = await TdLibController.send({
            '@type': 'getChats',
            chat_list: { '@type': 'chatListMain' },
            limit:50
        });
        //   console.log(chats)
        let chatsNew= chats.chat_ids.map(item => {
            if(item < 0){
                return item
            } 
         })
         return chatsNew
        // console.log(chatsNew)
        // chatsNew.map(item=>{
        //     if(item != "undefined"){
        //           GetChatDetails(item) 
        //     }
         
        // })
        // ChatStore.set(chat);
    
}

export default GetChannels