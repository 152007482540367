import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
import cryptlib from 'cryptlib'
import { GetCookie } from './../GetCookie'
import { async } from 'tdweb';



export async function StoreChannelByCoin(TgId, Username, Title, PackId) {


    TgId = TgId.toString();
    TgId  = TgId.replace('-100','');

    TgId=Number(TgId)
    PackId=Number(PackId)
    // console.log(TgId, Username, Title, PackId)
    let apitoken = GetCookie('RegisterMembersgram')
    // console.log(apitoken)
    // console.log(TgId)
    const iv = 'D%<{&A^bjha+VA#M'
    const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);
    const user = { "data": { username: Username, tgChannelId: TgId, planeid: PackId, title: Title } }
    let data = cryptlib.encrypt(JSON.stringify(user), key, iv);

    // console.log(data)


    try {

        let result = await axios.post("https://webapi.membersgram.com/api/v2/storechannelbycoin/",
            {
                 data
            },
            {
                headers: {
                    lan: '',
                    versionc: '',
                    market: 'WebAppP',
                    apitoken: apitoken,
                    'Content-Type': 'application/json',

                }
            },
        );

        let mydata = result.data

        // console.log(mydata)
        var iv1 = 'D%<{&A^bjha+VA#M';
        var key1 = cryptlib.getHashSha256(iv1, 32)
        let resultRegister = JSON.parse(cryptlib.decrypt(mydata, key1, iv1))
        // console.log(resultRegister)
        return (resultRegister)
    } catch (error) {
        return (`err in StoreChannelByCoin : ${error}`)
    }






}

