import React from 'react'
import { useState, useEffect } from 'react'
import BottomNavigation from './BottomNavigation'
import Packages from './Packages'
import './Container.css'
import Header from './Header'
import Channels from './Channels'
import Profile from './Profile'
import Loading from './Loading'
import UserStore from '../../Stores/UserStore';
import { useHistory, Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import Notice from './Notice'
import Payment from './Payment'
import Pay from './Pay'
import LogOut from './LogOut'
import NoInternet from './NoInternet'
import "./fonts/font.css"
import Orders from './Orders'
import OrderDetails from './OrderDetails'
import Coin from './Coin'
import BannedUser from './BannedUser';
import LeftCalc from './LeftCalc'
import { async } from 'tdweb'
import { getSrc, saveOrDownload, getBlob, loadChatContent } from './../../Utils/File';
import FileStore from './../../Stores/FileStore';
// import { getPhotoFromChat, getSupergroupId, isSupergroup } from '../../Utils/Chat';


import {
    getChatUsername, getChatSubtitle, isChannelChat, getChatTitle, getChatLetters, getPhotoFromChat, getChatPhoneNumber,
} from '../../Utils/Chat';
import { loadProfileMediaViewerContent } from '../../Utils/File';

const Container = () => {


    // document.addEventListener('contextmenu', event => event.preventDefault());


    window.onpopstate = function (e) {
        // console.log(e)
        seturl(window.location.pathname)
    };

    const [Tab, setTab] = useState('join')
    const [Index, SetIndex] = useState(0);
    const [ListFromServer, SetListFromServer] = useState('')
    const [ListToShow, SetListToShow] = useState('')
    const [LimitTimeToUi, setLimitTimeToUi] = useState(0)
    const [DesiredTime, setDesiredTime] = useState(5)
    const [ShowSpeed, setShowSpeed] = useState('Medium')

    const [AutojonStatus, setAutojonStatus] = useState(false)
    const [DisableNextButton, setDisableNextButton] = useState(false)
    const [Limit, setLimit] = useState(false)
    const [CoinJoin, setCoinJoin] = useState(0)
    const [DelayForJoin, setDelayForJoin] = useState('Medium')
    const [RadioState, setRadioState] = useState('Medium')
    const [RangeValue, setRangeValue] = useState(1)
    const [ShowSliderState, setShowSliderState] = useState(false)
    const [CoinFilledState, setCoinFilledState] = useState(false)
    const [ChatList, setChatList] = useState([])
    const [loading, setloading] = useState(true);
    const [Searching, setSearching] = useState([])
    const [Price, setPrice] = useState([])
    const [Sku, setSku] = useState([])
    const [State, setState] = useState({})
    const [Photo, setPhoto] = useState(false)
    const [CoinS, setCoinS] = useState(0)

    const [PriceNotice, setPriceNotice] = useState([])
    const [SkuNotice, setSkuNotice] = useState([])
    const [UserChat, setUserChat] = useState([])
    const [JoinUser, setJoinUser] = useState([])
    const getMyProfile = async () => {
        setloading(false)

    }

    const MyID = UserStore.getMyId();
    let IsTerminate = localStorage.getItem("terminate")

    useEffect(() => {

        let IsTerminate = localStorage.getItem("terminate")
        if (MyID != null || IsTerminate === 'true') {
            getMyProfile()

        }

    }, [MyID])
    const [url, seturl] = useState('')
    let blob

    useEffect(() => {


     


        // let IsTerminate = localStorage.getItem("terminate")
        // if (IsTerminate === 'true') {
        //     // localStorage.setItem('cache', localStorage.getItem('myfiles'))
        // } else {
        //     let MyID

        //     const intervalID = setInterval(() => {
        //         MyID = UserStore.getMyId()
        //         if (!MyID) {
        //             MyID = UserStore.getMyId()
        //         } else {
        //             console.log(MyID)

        //             clearInterval(intervalID)

        //         }

        //     }, 10);
        //     // let me = JSON.parse(localStorage.getItem('me'))
        //     // console.log(me)

        //     const interval = setInterval(() => {

        //         let full = UserStore.get(MyID)
        //         const photo = getPhotoFromChat(MyID);
        //           loadProfileMediaViewerContent(MyID, [photo]);
        //         // console.log(full)
        //         // let photo = getPhotoFromChat(full.id)
        //         let PhoneNumber = getChatPhoneNumber(full.id)
        //         localStorage.setItem('phoneNumber',PhoneNumber)
        //         // console.log(photo)
        //         if(photo.small){

            
        //         let blob = getSrc(photo.small)
        //         // console.log(blob)
        //         if (!blob) {
        //             loadProfileMediaViewerContent(MyID, [photo]);
        //             blob = getSrc(photo.small)
        //         } else {
        //             console.log(blob)
        //             localStorage.setItem('profilePhoto', blob)
        //             const resourceURL = blob;

        //             // Use fetch to retrieve the resource content
        //             fetch(resourceURL)
        //                 .then(response => response.blob())
        //                 .then(blob => {
        //                     const reader = new FileReader();
        //                     reader.onload = function () {
        //                         const dataURL = reader.result; // The data URL
        //                         console.log(dataURL);
        //                         localStorage.setItem('profilePhoto', dataURL)

        //                     };
        //                     reader.readAsDataURL(blob);
        //                 })
        //                 .catch(error => {
        //                     console.error('Error fetching resource:', error);
        //                 });
        //             clearInterval(interval)
        //         } 
        //        }else{
        //         localStorage.setItem('profilePhoto', '123')

        //        }

        //     }, 10);
        // }
        seturl(window.location.pathname)
    }, [blob])
    let Online = window.navigator.onLine

    return (
        <Router>

            {
                loading ? <Loading /> :

                    !Online ?
                        <NoInternet />
                        :

                        <div className='ContainerMembersgram'

                        > 
                            <LeftCalc JoinUser={JoinUser} setJoinUser={setJoinUser} UserChat={UserChat} setUserChat={setUserChat} />
                            {url == '/ChannelsList' || url == "/Notice" || url == '/Profile' || url == "/Payment" || url == "/Pay" || url == "/Orders" || url == "/OrderDetails" || url == "/Coin" || url == "/LogOut" ?
                                null
                                :
                                <div className='ContainerMembersgramHeader'>
                                    <Header />

                               
                                </div>
                            }
                            <div className='ContainerMembersgramContent'>
                                <Switch>



                                    <Route path="/Coin">
                                        {
                                            // IsTerminate === 'true' ? 
                                            // <div className='Auto'><CoinHeader coins={CoinS} />  <BannedUser /></div> :
                                            <Coin setDisableNextButton={setDisableNextButton} setCoinJoin={setCoinJoin} CoinJoin={CoinJoin} setDelayForJoin={setDelayForJoin} DelayForJoin={DelayForJoin} setDesiredTime={setDesiredTime}
                                                DisableNextButton={DisableNextButton} AutojonStatus={AutojonStatus} setAutojonStatus={setAutojonStatus} setRadioState={setRadioState} RadioState={RadioState} DesiredTime={DesiredTime}
                                                SetIndex={SetIndex} Index={Index} url={url} seturl={seturl} setLimit={setLimit} Limit={Limit} setRangeValue={setRangeValue} RangeValue={RangeValue} setShowSliderState={setShowSliderState}
                                                ShowSliderState={ShowSliderState} setCoinFilledState={setCoinFilledState} CoinFilledState={CoinFilledState} setLimitTimeToUi={setLimitTimeToUi} LimitTimeToUi={LimitTimeToUi} setTab={setTab} Tab={Tab}
                                                ListFromServer={ListFromServer} SetListFromServer={SetListFromServer} SetListToShow={SetListToShow} ListToShow={ListToShow} setCoinS={setCoinS} CoinS={CoinS} setShowSpeed={setShowSpeed} ShowSpeed={ShowSpeed} />}
                                    </Route>
                                    <Route path="/OrderDetails">
                                        <OrderDetails seturl={seturl} />
                                    </Route>
                                    <Route path="/Orders">
                                        <Orders />
                                    </Route>

                                    <Route path="/LogOut">
                                        <LogOut seturl={seturl} url={url} />
                                    </Route>
                                    <Route path="/Payment">
                                        {
                                            // IsTerminate === 'true' ? <BannedUser /> :
                                            <Payment seturl={seturl} url={url} />}
                                    </Route>
                                    <Route path="/Pay">
                                        {IsTerminate === 'true' ? <BannedUser /> : <Pay />}
                                    </Route>
                                    <Route path="/Profile">
                                        <Profile seturl={seturl} url={url} setPhoto={setPhoto} Photo={Photo}/>
                                    </Route>
                                    <Route path="/Notice" >
                                        {IsTerminate === 'true' ? <BannedUser /> : <Notice seturl={seturl} url={url} SkuNotice={SkuNotice} setSkuNotice={setSkuNotice}
                                            PriceNotice={PriceNotice} setPriceNotice={setPriceNotice} />}

                                    </Route>
                                    <Route path="/ChannelsList">
                                        {IsTerminate === 'true' ? <BannedUser /> :
                                            <Channels setChatList={setChatList} Price={Price} setPrice={setPrice}
                                                ChatList={ChatList} seturl={seturl} Sku={Sku} setSku={setSku}
                                                setSearching={setSearching} Searching={Searching}
                                                State={State} setState={setState} />}

                                    </Route>
                                    <Route path="/">
                                        {IsTerminate === 'true' ? <BannedUser url={url} /> : <Packages />}

                                    </Route>

                                </Switch>
                            </div>
                            {url == '/ChannelsList' || url == "/Notice" || url == "/Payment" || url == "/Pay" || url == "/OrderDetails" || url == "/LogOut" ?
                                null
                                :
                                <div className='ContainerMembersgramFooter'>
                                    <BottomNavigation url={url} seturl={seturl} />

                                </div>
                            }
                        </div>

            }
        </Router>



    )
}

export default Container