import React from 'react'
import { useEffect } from 'react';
import cryptlib from 'cryptlib'
import TdLibController from '../../Controllers/TdLibController';
import { async } from 'tdweb';
import { useState } from 'react';
import { GetCookie } from './GetCookie'
import { GetListJoin } from './Apis/GetListJoin'
import './Coin.css'
import CoinsDouble from '../../Assets/CoinsDouble.svg'
import CoinsDoubleDisable from '../../Assets/CoinsDoubleDisable.svg'
import AutoIcon from '../../Assets/AutoIcon.svg'
import AutoJoinDisable from '../../Assets/AutoJoinDisable.svg'
import NextIcon from '../../Assets/NextIcon.svg'
import NextIconDisable from '../../Assets/NextIconDisable.svg'
import Stop from '../../Assets/Stop.svg'
import speed from '../../Assets/Speed.svg'
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import More from '../../Assets/more.svg'
import Coinitem from './Coinitem';
import CoinHidden from './CoinHidden'
import CoinTooMuchLeft from './CoinTooMuchLeft'
import { Skeleton } from '@material-ui/lab';
import CoinHeader from './CoinHeader'
import CoinFilled from './CoinFilled'
import CoinEndList from './CoinEndList'
import CoinLimit from './CoinLimit'
import CoinAutoJoin from './CoinAutoJoin'
import UserStore from "../../Stores/UserStore";
import { isChannelChat, isChatMember, isGroupChat } from '../../Utils/Chat';
import Snackbar from '@material-ui/core/Snackbar';
import { BottomSheet } from 'react-spring-bottom-sheet'
import Divider from '@material-ui/core/Divider';
import { JoincChannel } from './Apis/JoincChannel'
import Autojoinbottomsheet from "../../Assets/Autojoinbottomsheet.svg"
import Warning from "../../Assets/warning.png"
import Loading from './Loading'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import { ReportChannelAPI } from './Apis/ReportChannel'
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Box from "@material-ui/core/Box";
import { styled } from "@material-ui/core/styles";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CoinTransfer from './CoinTransfer';
import BannedUser from './BannedUser';

const Coin = ({ url, seturl, ListFromServer, setDelayForJoin, DelayForJoin, setTab, Tab,
  SetListFromServer, SetListToShow, setCoinJoin, CoinJoin, setRangeValue, RangeValue,
  setShowSliderState, ShowSliderState, setCoinFilledState, CoinFilledState, setLimitTimeToUi, LimitTimeToUi,
  ListToShow, SetIndex, Index, setLimit, Limit, setRadioState, RadioState, setDesiredTime, DesiredTime,
  DisableNextButton, setDisableNextButton, setAutojonStatus, AutojonStatus, setCoinS, CoinS, setShowSpeed, ShowSpeed }) => {

  const [openSpeed, setOpenSpeed] = useState(false);
  const [searchCompleted, setSearchCompleted] = useState(false);
  let IsTerminate = localStorage.getItem("terminate")
  const urlParams = new URLSearchParams(window.location.search);
  let TabStatus = urlParams.get('Status');
  const SetTabsStatusJoin = () => {
    setTab('join')
    urlParams.set('Status', 'join');
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  }
  const SetTabsStatusTransfer = () => {
    setTab('transfer')

    // urlParams.searchParams.set('Status', 'transfer');
    urlParams.set('Status', 'transfer');
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  }
  const toggleDrawer = (newOpen) => () => {
    setOpenSpeed(newOpen);
  };
  const [LimitTimeTransfer, setLimitTimeTransfer] = useState('')
  const [PhoneError, setPhoneError] = useState(false)
  const [CoinError, setCoinError] = useState(false)
  const [CountryCode, setCountryCode] = useState('')
  const drawerBleeding = 80;
  const Ontochmove = (e) => {
    // Add event listener for touchmove event
    let element = document.querySelector('#SwipeableDrawer')
    element.addEventListener("touchmove", handleTouchMove);

    // Event handler function for touchmove event
    function handleTouchMove(event) {
      // Access the touch object from the event
      const touch = event.touches[0];
      // console.log(touch)
      // Retrieve the coordinates of the touch
      const touchX = touch.clientX;
      const touchY = touch.clientY;

      // Do something with the touch coordinates
      // console.log("Touch X:", touchX);
      // console.log("Touch Y:", touchY);
      if (touch.screenY > 200) {
        if (touch.target.className == "SelectJoinSpeedContainer") {
          setOpenSpeed(false);
        }

      }
    }
  }

  const handleClickAway = () => {
    // Do something here
    setOpenSpeed(false);
  };

  // This is used only for the example
  // const container =
  // window !== undefined ? () => window().document.body : undefined;
  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: '#E7E7E7',
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'blue',
  }));



  const [HiddenChannel, setHiddenChannel] = useState(false)

  let apitoken = GetCookie('RegisterMembersgram')


  const [EndListJoin, setEndListJoin] = useState(false)
  const [JoinThisStatus, setJoinThisStatus] = useState(false)
  const [LimitTime, setLimitTime] = useState(0)
  const [Photo, setPhoto] = useState(false)
  // const [ManualUserTimeJoin, setManualUserTimeJoin] = useState(1)
  const [Loadingstate, setLoadingstate] = useState(false)





  useEffect(() => {
    // console.log(TabStatus,url,window.location.pathname)
    if (TabStatus === 'transfer') {
      SetTabsStatusTransfer()
      // setTab('transfer')
      if (url !== window.location.pathname) {
        seturl(window.location.pathname)
      }

    } else {
      SetTabsStatusJoin()
      // setTab('join')
      seturl(window.location.pathname)
    }
    setLoadingstate(true)
    if (IsTerminate === 'true') {
      // setTab('transfer')
      StopAutoJoin()
      setLoadingstate(false)

    } else {
      let NowTime = (new Date()).getTime();
      let LimitFinal = localStorage.getItem("LimitFinal")
      // console.log(LimitFinal)
      if (LimitFinal > NowTime) {


        // console.log(LimitFinal, NowTime)
        if (localStorage.getItem("timer") == 'true') {
          // console.log(22222)
          localStorage.setItem("timer", false);
          clearInterval(timer1);
          setLimitTime(false)
          setLimit(false)
          timer(localStorage.getItem("LimitTimeSec"))
        } else {
          setLimitTime(localStorage.getItem("LimitTimeSec"))

          // console.log(1)
          timer(localStorage.getItem("LimitTimeSec"))

        }
        setLoadingstate(false)
      } else {
        // console.log(LimitFinal, NowTime)

        localStorage.setItem("LimitTimeSec", false);
        localStorage.setItem("timer", false);
        setLoadingstate(false)

      }

      seturl(window.location.pathname)
      GetList();
      let Speed = localStorage.getItem("Speed");
      // console.log(Speed)
      if (Speed == null) {
        // console.log(Speed)
        setRadioState('Medium')
        setDelayForJoin('Medium')
        setShowSpeed('Medium')

      } else if (Speed == '5' || Speed == '10' || Speed == '20' || Speed == '30' || Speed == '40' || Speed == '50' || Speed == '60') {
        setRadioState('Desired')
        setDelayForJoin('Desired')
        setShowSliderState(true)
        setShowSpeed('Desired')

        // console.log(Speed)
        if (Speed == '5') {
          setRangeValue(1)
          setDesiredTime(5)
        } else if (Speed == '10') {
          setRangeValue(2)
          setDesiredTime(10)
        }
        else if (Speed == '20') {
          setRangeValue(3)
          setDesiredTime(20);
        }
        else if (Speed == '30') {
          setRangeValue(4)
          setDesiredTime(30);
        }
        else if (Speed == '40') {
          setRangeValue(5)
          setDesiredTime(40);
        }
        else if (Speed == '50') {
          setRangeValue(6)
          setDesiredTime(50);
        }
        else if (Speed == '60') {
          setRangeValue(7)
          setDesiredTime(60);
        }

      }
      else {
        setRadioState(Speed)
        setDelayForJoin(Speed)
        setShowSpeed(Speed)
      }

      setCoinFilledState(false)
      if (localStorage.getItem("FirstJoin") == null) {
        localStorage.setItem("FirstJoin", false);
      }
    }

  }, [])


  // function millisToMinutesAndSeconds(millis) {
  //   var minutes = Math.floor(millis / 60000);
  //   var seconds = ((millis % 60000) / 1000).toFixed(0);
  //   return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  // }

  let timer1
  const timer = (limit) => {
    var sec = limit;

    // var time = new Date().getTime(); // get your number
    // var date = new Date(time); // create Date object
    // var limit = 52
    // console.log(date, time);

    let NowTime = (new Date()).getTime();
    // limit = limit * 1000
    let LimitFinal = NowTime + limit * 1000
    localStorage.setItem("LimitFinal", LimitFinal);
    // console.log(NowTime, limit * 1000)
    setLimitTime(localStorage.getItem("LimitTimeSec"))
    setLimit(true)
    timer1 = setInterval(function () {

      sec--;
      if (sec < 0) {
        clearInterval(timer1);
        setLimitTime(false)
        setLimit(false)
        localStorage.setItem("LimitTimeSec", false);
        localStorage.setItem("timer", false);
      } else {
        localStorage.setItem("timer", true);
        localStorage.setItem("LimitTimeSec", sec);
        setLimit(true)
        // console.log(sec)
        SecToMin(sec)
        return sec
      }

    }, 1000);

  }
  const NextItem = async () => {
    setDisableNextButton(true)
    if (ListFromServer.length > Index + 1) {
      SetIndex(prevState => prevState + 1);
      SetListToShow([ListFromServer[Index + 1]])
      return true
    } else {
      setEndListJoin(true)
      setLoadingstate(true)

      let res = await GetList()
      if (res == true) {
        setTimeout(() => {
          setLoadingstate(false)
        }, 1000);
      } else {
        setTimeout(() => {
          setLoadingstate(false)
        }, 1000);
      }
      SetIndex(0);

    }
    setDisableNextButton(false)

  }

  const GetList = async () => {
    setLoadingstate(true)
    SetListToShow([])

    let list = await GetListJoin(apitoken)
    let array
    if (list.status == "success") {
      array = list.data.data
      const iv = 'D%<{&A^bjha+VA#M'
      const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);
      let data = cryptlib.encrypt(JSON.stringify(array), key, iv);
      // console.log(array)
      localStorage.setItem("List", data);
      setCoinS(list.data.user.coins)
      localStorage.setItem("UserCoins",list.data.user.coins)
      let FirstChannel = await SearchPublicChat(array[Index].username)
      // console.log(FirstChannel)
      if (FirstChannel.code == 429) {
        setLimit(true)
        let thenum = FirstChannel.message.match(/\d+/)[0]
        setLimitTime(thenum)

        // setLoadingstate(false)
        setEndListJoin(false)
        clearInterval(timer1);

        localStorage.setItem("timer", true);
        setLimitTime(thenum)
        timer(thenum)


        return false

      } else {
        localStorage.setItem("LimitTime", null);
        // console.log(FirstChannel)
        setLimit(false)
        setLimitTime(false)
        SetListToShow([array[0]])
        SetListFromServer(array)
        setEndListJoin(false)
        setLoadingstate(false)
        return true
      }


    } else {
      GetSnackbar('Network Error')

      // console.log(list)
      setLoadingstate(true)
      return false
    }
    setLoadingstate(false)
  }


  const SearchPublicChat = async (username) => {
    let Result = await TdLibController.send({
      '@type': 'searchPublicChat',
      username: username
    })
    return Result
  }
  const Join = async (id) => {
    return await TdLibController.send({
      '@type': 'joinChat',
      chat_id: id
    });
  }
  const JoinThis = async () => {
    // console.log(Index)

    setJoinThisStatus(true)
    // console.log(ListFromServer[Index].username)
    let rea = await SearchPublicChat(ListFromServer[Index].username)
    // console.log(rea)
    let username = { username: ListFromServer[Index].username }
    const obj3 = { ...rea, ...username };
    if (!rea.message && rea.id) {
      let id = rea.id
      const isMember = isChatMember(id);
      if (isMember == false) {
        let JoinResponse = await Join(id)
        // console.log(JoinResponse)
        if (Object.values(JoinResponse)[0] == "ok") {
          if (localStorage.getItem("FirstJoin") == 'false') {
            ToggleOpenBSheetWarning()
            localStorage.setItem("FirstJoin", true);
          }
          let getapi = await JoincChannel(obj3, ListFromServer[Index], "normal_join")
          if (getapi.status == 'success') {
            setCoinS(prevState => prevState + 2)
            let NextResponse = NextItem(Index);
            if (NextResponse == true) {
              setJoinThisStatus(false)
              return true
            } else {
              // endList
            }

          } else {
            console.log('error in api join')
          }


        } else {
          if (JoinResponse.code == 429) {
            setLimit(true)
            let thenum = JoinResponse.message.match(/\d+/)[0]
            setLimitTime(thenum)
            if (localStorage.getItem("timer") == "false" || localStorage.getItem("timer") == null) {
              localStorage.setItem("timer", true);
              timer(thenum)
            }
          }
          else if (JoinResponse.code == 400) {
            if (JoinResponse.message == "CHANNELS_TOO_MUCH") {
              setCoinFilledState(true)

            }

          }
        }
      } else {
        // قبلا عضو شده
        NextItem(Index);
        GetSnackbar('You are already a member of this channel/group');
        setJoinThisStatus(false)
        // console.log("Already join")
        return "Already join"
      }
    } else {
      // محدودیت سرچ
      // console.log(rea)
      // alert(rea.message)
      if (rea.code == 429) {
        setLimit(true)
        let thenum = rea.message.match(/\d+/)[0]
        // console.log(thenum)
        thenum = parseInt(thenum)
        setLimitTime(thenum)
        if (localStorage.getItem("timer") == "false" || localStorage.getItem("timer") == null) {
          localStorage.setItem("timer", true);
          timer(thenum)
        }

        // console.log(2)
        return thenum

      } else if (rea.code == 400) {
        if (rea.message == "USERNAME_NOT_OCCUPIED") {
          NextItem(Index);
          setJoinThisStatus(false)

          return true
        }

      }

    }





    setJoinThisStatus(false)
  }


  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function demo(time) {
    let status;
    for (let i = 0; i < time; i++) {
      // console.log(i)
      // console.log(localStorage.getItem("autojoin"))
      if (localStorage.getItem("autojoin") == 'false') {
        status = false
        break;
      } else {
        console.log(`Waiting ${i} seconds...`);
        await sleep(1000);
        status = true
      }

    }
    return status
  }
  const AutoJoinNextItem = (i) => {
    let List = localStorage.getItem("List");

    var iv1 = 'D%<{&A^bjha+VA#M';
    var key1 = cryptlib.getHashSha256(iv1, 32)
    let MyList = JSON.parse(cryptlib.decrypt(List, key1, iv1))

    // console.log(MyList)
    // console.log(MyList.length, i + 1)
    let statusnext;
    setDisableNextButton(true)
    if (MyList.length > i + 1) {

      SetIndex(prevState => prevState + 1);

      SetListToShow([MyList[i + 1]])
      statusnext = true
    } else {

      setEndListJoin(true)
      setTimeout(() => {
        // setLoadingstate(true)
      }, 1000);
      GetList()
      SetIndex(0);
      SaveDelayTime()
      statusnext = false
    }
    setDisableNextButton(false)
    return statusnext
  }
  const AutoJoinThis = async (MyIndex) => {
    let Status;
    let speed
    let DelayForJoininFunc = localStorage.getItem("Speed");
    // console.log(DelayForJoininFunc)
    // console.log('Index'+MyIndex)
    if (DelayForJoininFunc == 'Slowly') {
      let random = Math.floor(Math.random() * (20 - 0 + 1) + 0)
      speed = random + 12
    } else if (DelayForJoininFunc == 'Medium') {
      let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
      speed = random + 7
    } else if (DelayForJoininFunc == 'Quick') {
      let random = Math.floor(Math.random() * (4 - 0 + 1) + 0)
      speed = random + 3
    } else {
      // console.log(DelayForJoininFunc)

      if (DelayForJoininFunc == '60' || DelayForJoininFunc == '50' || DelayForJoininFunc == '40' || DelayForJoininFunc == '30' || DelayForJoininFunc == '20' || DelayForJoininFunc == '10' || DelayForJoininFunc == '5') {
        speed = DelayForJoininFunc
      } else {
        // اگر وجود نداشت
        localStorage.setItem("Speed", 'Medium');

        let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
        speed = random + 7

      }
      console.log(DelayForJoininFunc)


      // speed = RangeValue
    }
    // console.log(speed)

    let List = localStorage.getItem("List");
    var iv1 = 'D%<{&A^bjha+VA#M';
    var key1 = cryptlib.getHashSha256(iv1, 32)
    let MyList = JSON.parse(cryptlib.decrypt(List, key1, iv1))
    let NextStatus
    // if (i == 0) {
    //   Index = i
    // }
    let rea = await SearchPublicChat(MyList[MyIndex].username)
    // console.log(rea)
    localStorage.setItem("LimitTimeSec", null);
    let username = { username: MyList[MyIndex].username }
    const obj3 = { ...rea, ...username };
    // console.log(obj3)
    let DelayBeforeJoin = await demo(speed);
    // console.log(DelayBeforeJoin)
    if (DelayBeforeJoin == true) {
      if (!rea.message && rea.id) {
        let id = rea.id
        const isMember = isChatMember(id);
        if (isMember == false) {
          let JoinResponse = await Join(id)
          // console.log(JoinResponse)
          // console.log(Object.values(JoinResponse)[0])
          if (Object.values(JoinResponse)[0] == "ok") {
            let getapi = await JoincChannel(obj3, MyList[MyIndex], "auto_join")
            if (getapi.status == 'success') {
              setCoinJoin(prevState => prevState + 2)
              setCoinS(getapi.data.user.coins)
              localStorage.setItem("UserCoins",getapi.data.user.coins)
              NextStatus = AutoJoinNextItem(MyIndex);
              // console.log(MyIndex, NextStatus)
              if (NextStatus == false) {
                let list = await GetList()
                if (list == true) {
                  // SetMyIndex(0)
                  Status = 'end'
                  return Status

                }

              } else {
                // setJoinThisStatus(false)
                Status = true
                return Status

              }
              return Status


            } else {
              console.log('error in api join')
              Status = true
            }

            return Status

          } else {
            if (JoinResponse.code == 429) {
              setLimit(true)
              let thenum = JoinResponse.message.match(/\d+/)[0]
              // console.log(thenum)
              thenum = parseInt(thenum)
              setLimitTime(thenum)
              localStorage.setItem("LimitTimeSec", thenum);
              if (localStorage.getItem("timer") == "false" || localStorage.getItem("timer") == null) {
                localStorage.setItem("timer", true);
                timer(thenum)
              }


              Status = { status: 'limit', time: thenum }
            } else if (JoinResponse.code == 400) {
              if (JoinResponse.message == "CHANNELS_TOO_MUCH") {
                Status = "CHANNELS_TOO_MUCH"
              }
              if (JoinResponse.message == "INVITE_REQUEST_SENT") {
                Status = "INVITE_REQUEST_SENT"
              }



            }
            // console.log(Status)
            return Status
          }
          // return Status
        } else {
          // قبلا عضو شده
          NextStatus = AutoJoinNextItem(MyIndex);
          if (NextStatus == false) {
            // StopAutoJoin()
            let list = await GetList()
            if (list == true) {
              Status = 'end'
            } else {
              // setJoinThisStatus(false)
              Status = true
            }
          }
          GetSnackbar('You are already a member of this channel/group');
          Status = "Already join"
        }
      } else {
        // محدودیت سرچ

        // console.log(rea)
        if (rea.code == 429) {
          setLimit(true)
          let thenum = rea.message.match(/\d+/)[0]
          // console.log(thenum)
          thenum = parseInt(thenum)
          localStorage.setItem("LimitTimeSec", thenum);
          // setLimitTime(thenum)
          if (localStorage.getItem("timer") == "false" || localStorage.getItem("timer") == null) {
            localStorage.setItem("timer", true);
            timer(thenum)
          }

          Status = { status: 'limit', time: thenum }

        } else if (rea.code == 200) {
          NextStatus = AutoJoinNextItem(MyIndex);
          // console.log(NextStatus)
          Status = true
        } else if (rea.code == 400) {
          if (rea.message == "USERNAME_NOT_OCCUPIED")
            NextStatus = AutoJoinNextItem(MyIndex);
          // console.log(NextStatus)
          Status = 'USERNAME_NOT_OCCUPIED'
        }
        return Status
      }
      // 
      return Status

    }
    else if (DelayBeforeJoin == false || DelayBeforeJoin == undefined) {
      Status = false
    }
    // console.log(Status)
    return Status
  }

  const StartAutoJoin = async (status, i = undefined, limit = undefined) => {
    let timer2
    let timer3
    let MyIndex = 0
    if (Index > MyIndex) {
      MyIndex = Index
    }
    let List = localStorage.getItem("List");

    var iv1 = 'D%<{&A^bjha+VA#M';
    var key1 = cryptlib.getHashSha256(iv1, 32)
    let MyList = JSON.parse(cryptlib.decrypt(List, key1, iv1))

    if (status == true) {


      setAutojonStatus(true)

      let timer = localStorage.getItem("timer");
      let NowTime = (new Date()).getTime();
      let LimitFinal = localStorage.getItem("LimitFinal")
      localStorage.setItem("autojoin", true);
      if (LimitFinal > NowTime) {
        // console.log(LimitFinal, NowTime)
        if (localStorage.getItem("timer") == 'true') {
          let k = 0;
          timer2 = setInterval(() => {
            if (localStorage.getItem("autojoin") == 'true') {
              k++
              let LimitTimeSec = localStorage.getItem("LimitTimeSec");
              LimitTimeSec = parseInt(LimitTimeSec)
              // console.log(timer, LimitTimeSec)


              // console.log(LimitTimeSec, k)


              if (LimitTimeSec > 0) {
                setLimit(true)

                // console.log(LimitTimeSec, k)
                // console.log(LimitTimeSec)
              } else {
                clearInterval(timer2);
                localStorage.setItem("LimittimeSec", false);
                StartAutoJoin(true)
                // console.log(LimitTimeSec)


              }
            } else {
              clearInterval(timer2);
              // localStorage.setItem("LimitTimeSec", null);
            }


          }, 1000)
        } else {

          let k = 0;
          timer2 = setInterval(() => {
            if (localStorage.getItem("autojoin") == 'true') {
              k++
              let LimitTimeSec = localStorage.getItem("LimitTimeSec");
              LimitTimeSec = parseInt(LimitTimeSec)
              // console.log(timer, LimitTimeSec)


              // console.log(LimitTimeSec, k)


              if (LimitTimeSec > 0) {
                setLimit(true)

                // console.log(LimitTimeSec, k)
                // console.log(LimitTimeSec)
              } else {
                clearInterval(timer2);
                localStorage.setItem("LimittimeSec", false);
                StartAutoJoin(true)
                // console.log(LimitTimeSec)


              }
            } else {
              clearInterval(timer2);
              // localStorage.setItem("LimitTimeSec", null);
            }


          }, 1000)
        }
      } else {



        let length = MyList.length


        const Looper = async () => {
          let localstatusofautojoin = localStorage.getItem("autojoin");
          // console.log(localstatusofautojoin)

          if (localstatusofautojoin == 'true') {


            if (MyIndex < length) {
              // console.log('looper', MyIndex)

              let response = await AutoJoinThis(MyIndex)
              // console.log(response)
              if (response == true) {
                MyIndex++;
                Looper()
              } else if (response == "Already join") {
                MyIndex++;
                Looper()
              } else if (response == "CHANNELS_TOO_MUCH") {
                StopAutoJoin()
                setCoinFilledState(true)
                // timer(response)
              }
              else if (response == "INVITE_REQUEST_SENT") {
                MyIndex++;
                Looper()
              } else if (response == 'end') {
                // StartAutoJoin(true, 0, false)
                MyIndex = 0
                Looper()
              } else if (response == "USERNAME_NOT_OCCUPIED") {
                MyIndex++;
                Looper()
              } else if (response == false || response == undefined) {
                MyIndex++;
                Looper()
              } else if (response.status) {
                if (response.status == "limit") {
                  // let res = timer(response.time + 2)

                  // if (LimitTimeSec != 'null' || LimitTimeSec != 'false' || LimitTimeSec!=null) {
                  let k = 0
                  timer3 = setInterval(() => {
                    let timer = localStorage.getItem("timer")

                    if (localStorage.getItem("autojoin") == 'true') {
                      // console.log(timer)
                      // if (timer == "true" ) {
                      setLimit(true)

                      let LimitTimeSec = localStorage.getItem("LimitTimeSec");
                      // setLimitTime(Limittimesec) 
                      // console.log(LimitTimeSec)
                      // SecToMin(Limittimesec)

                      k++
                      if (LimitTimeSec > 0) {
                        // console.log(LimitTimeSec, k)
                        // SecToMin(LimitTimeSec)
                      } else {
                        clearInterval(timer3);
                        localStorage.setItem("LimitTimeSec", false);
                        // StartAutoJoin(true)
                        // console.log(response.time, LimitTimeSec)
                        MyIndex++;
                        Looper()

                      }
                      // }else{
                      //   console.log(435345345)
                      // }

                    } else {
                      // localStorage.setItem("LimitTimeSec", false);
                      // localStorage.setItem("timer", false);
                      clearInterval(timer3);
                      // clearInterval(timer1);
                    }


                  }, 1000)
                  // }else{
                  //   clearInterval(timer3);
                  //   localStorage.setItem("LimitTimeSec", false);
                  //   MyIndex++;
                  //   Looper()
                  // }


                }


              }



            } else {



            }
            // }else{
            //   console.log('dfgdfgdfg')
            // }

          } else {
            // console.log(localstatusofautojoin)
          }

        }
        if (status == true && localStorage.getItem("autojoin") == 'true') {
          Looper();
          // console.log(6)
          // console.log('call in auto')
        }
      }


    } else if (status == false) {
      clearInterval(timer2);
      clearInterval(timer3);

    }
  }
  const StopAutoJoin = async () => {
    // console.log('stop')
    localStorage.setItem("autojoin", false);
    setAutojonStatus(false)
    StartAutoJoin(false)
    setCoinJoin(0)

  }



  const SaveDelayTime = () => {
    setOpenSpeed(false)

    let Unsaved = localStorage.getItem("SpeedUnSaved");
    // let DesiredValueUnSaved = localStorage.getItem("DesiredValueUnSaved");
    localStorage.setItem("Speed", Unsaved);
    // localStorage.setItem("DesiredValue", DesiredValueUnSaved);
    setDesiredTime(Unsaved)
    setShowSpeed(RadioState)
  }

  const [openBottomSheet, setOpenBottomSheet] = useState(true)
  function onDismiss() {
    setTimeout(() => {
      setOpenSpeed(false)
    }, 1000);

  }

  const SecToMin = (d) => {

    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    m = String(m).padStart(2, "0");
    h = String(h).padStart(2, "0");
    s = String(s).padStart(2, "0");

    setLimitTimeToUi({ h, m, s })
  }

  const [OpenBSheetStartAuto, setOpenBSheetStartAuto] = useState(false)
  const CloseOpenBSheetStartAuto = () => {
    setOpenBSheetStartAuto(false)
  }
  const ToggleOpenBSheetStartAuto = () => {
    setOpenBSheetStartAuto(true)
  }

  const [OpenBSheetWarning, setOpenBSheetWarning] = useState(false)
  const CloseOpenBSheetWarning = () => {
    setOpenBSheetWarning(false)
  }
  const ToggleOpenBSheetWarning = () => {
    setOpenBSheetWarning(true)
  }
  //  radio button habdle
  const HandleRadioButton = (input, value) => {
    // console.log(input, value)


    // let Speed = localStorage.getItem("Speed");
    // console.log(Speed)
    let Speed;
    if (input == 'Slowly') {
      // 0-20 + 12

      // setDelayForJoin('Slowly')
      Speed = 'Slowly'
      setRadioState('Slowly')
      setShowSliderState(false)
    } else if (input == 'Medium') {
      // 0-5 + 7
      Speed = 'Medium'
      // setDelayForJoin('Medium')

      setRadioState('Medium')
      setShowSliderState(false)
    } else if (input == 'Quick') {
      // 0-4+3
      Speed = 'Quick'

      // setDelayForJoin('Quick')
      setRadioState('Quick')
      setShowSliderState(false)
    } else if (input == 'Desired') {
      // 5  10  20  30 40 50 60

      if (value == undefined) { value = '1' }
      // setDelayForJoin(5)
      if (value == '1') {
        setDesiredTime(5);
        Speed = 5
      }
      else if (value == '2') {
        setDesiredTime(10);
        Speed = 10
      }
      else if (value == '3') {
        setDesiredTime(20);
        Speed = 20
      }
      else if (value == '4') {
        setDesiredTime(30);
        Speed = 30
      }
      else if (value == '5') {
        setDesiredTime(40);
        Speed = 40
      }
      else if (value == '6') {
        setDesiredTime(50);
        Speed = 50
      }
      else if (value == '7') {
        setDesiredTime(60);
        Speed = 60
      }
      // console.log(RangeValue)
      setRadioState('Desired')
      setShowSliderState(true)
      // setDelayForJoin('Desired')
    }
    // console.log(Speed)

    localStorage.setItem("SpeedUnSaved", Speed);
    // localStorage.setItem("DesiredValueUnSaved", value);

  }
  //  radio button habdle

  // slider

  const ChangeRangeValue = () => {
    let sliderEl4 = document.querySelector("#range4")
    sliderEl4.addEventListener("input", (event) => {
      let tempSliderValue = event.target.value;
      // console.log(tempSliderValue)
      setRangeValue(tempSliderValue)
      HandleRadioButton('Desired', tempSliderValue)
      // 5  10  20  30 40 50 60
      // return;


    })

  }
  // slider

  // dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickmenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosemenu = () => {
    setAnchorEl(null);
    // alert(navigator)
    // console.log(navigator)


  };
  const ShareChannelLink = (link) => {
    if (navigator.share) {
      //  alert("Congrats! Your browser supports Web Share API");
      navigator
        .share({
          url: link,
          text: link,
          title: link,
        })
        .then(() => {
          //  alert("Sharing successfull");
        })
        .catch(() => {
          //  alert("Sharing failed");
        });
    } else {
      //    alert("Sorry! Your browser does not support Web Share API");
    }
  }
  const ReportChannel = async (obj) => {
    // console.log(1)
    // console.log(obj)
    // .username,id:ListToShow[0].tgChannelId

    // let message='Thank you for your report'
    let report = await ReportChannelAPI(obj);
    // console.log(report)
    if (report.status == 'success') {
      GetSnackbar('Thank you for your report')
      NextItem()
    } else {
      GetSnackbar('Somthing went wrong')
    }
    // 
  }
  // dropdown
  // snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  const handleClick = (newState) => {
    setState({ open: true, ...newState });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [SnackMessage, setSnackMessage] = useState('')
  const GetSnackbar = (message) => {
    setSnackMessage(message)
    handleClick({
      vertical: 'bottom',
      horizontal: 'center',
    })
    setTimeout(() => {
      handleClose()
    }, 3000);
  }
  // snackbar
  // console.log(ListToShow[0])
  return (

    <div className='Auto'>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={SnackMessage}
        key={vertical + horizontal}

      />
      <BottomSheet open={OpenBSheetWarning} onDismiss={CloseOpenBSheetWarning}>
        <div className='WarningBottomSheetStart'>
          <div className='WarningBottomSheetStartTitle'>
            Warning
          </div>
          <img className='WarningBottomSheetStartTitleImage' src={Warning} />
          <div className='WarningBottomSheetStartText'>If you left any channel or group, 4 coins will be deducted from your account for each exit, and your account will be blocked if you repeat it.</div>
          <div className='WarningBottomSheetStartButtons'>
            <button className='WarningBottomSheetStartStart' onClick={() => { CloseOpenBSheetWarning(); CloseOpenBSheetStartAuto() }}>Got it</button>
          </div>
        </div>
      </BottomSheet>
      <BottomSheet open={OpenBSheetStartAuto} onDismiss={CloseOpenBSheetStartAuto}>
        <div className='AutoJoinBottomSheetStart'>
          <div className='AutoJoinBottomSheetStartTitle'>
            <img className='AutoJoinBottomSheetStartTitleImage' src={Autojoinbottomsheet} /> Auto Join
          </div>
          <div className='AutoJoinBottomSheetStartText'>Do you want to start automatic join?</div>
          <div className='AutoJoinBottomSheetStartButtons'>
            <button className='AutoJoinBottomSheetStartCancel' onClick={() => CloseOpenBSheetStartAuto()}>Cancel</button>
            <button className='AutoJoinBottomSheetStartStart' onClick={() => { StartAutoJoin(true); CloseOpenBSheetStartAuto() }}>Start</button>
          </div>
        </div>
      </BottomSheet>


      <SwipeableDrawer
        // onClick={(e)=>handleClickAway(e)}
        id="SwipeableDrawer"
        // container={container}
        anchor="bottom"
        open={openSpeed}
        onClose={() => {
          toggleDrawer(false);
        }}
        onDismiss={() => {
          setOpenSpeed(false)
        }}
        onSwipe={() => {
          setOpenSpeed(false)
        }}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={!searchCompleted}
        SwipToClose={true}
        SwipeableDrawer={true}
        ModalProps={{
          keepMounted: false
        }}
        disableDiscovery={true}
        onTouchMove={(e) => {
          Ontochmove(e)
        }}
        draggable={true}
      // onTouchStart={(e) => {
      //   console.log(e)
      // }}
      // onTouchEnd={(e) => {
      //   console.log(e)
      // }}
      >
        {/* <div  style={{position:"absolute",top:"0",width:"100%",height:"48px"}}
      
            > 
             </div> */}
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller
            // onTouchMove={(e) => {
            //   Ontochmove(e)
            //  }}
            onTouchMove={(e) => {
              Ontochmove(e)
            }}
          />

        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        ></StyledBox>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className='SelectJoinSpeedContainer' >
            <div className='SelectJoinSpeedTitle'>
              Change speed
            </div>
            <div className='SelectJoinSpeedDes'>
              Note: Due to the increase in membership speed, the possibility of your account being blocked by Telegram increases
            </div>
            <div className='SelectJoinSpeedItems'>
              <div >
                <p className='ParaRadio' onClick={(e) => HandleRadioButton('Slowly')}>
                  <input type="radio" id="Slowly" onChange={(e) => HandleRadioButton('Slowly')} name="radio-group" checked={RadioState == 'Slowly' ? true : null} />
                  <label htmlFor="Slowly" >Slowly</label>
                </p>
                <p className='ParaRadio' onClick={(e) => HandleRadioButton('Medium')}>
                  <input type="radio" id="Medium" onChange={(e) => HandleRadioButton('Medium')} name="radio-group" checked={RadioState == 'Medium' ? true : null} />
                  <label htmlFor="Medium">Medium (default)</label>
                </p>
                <p className='ParaRadio' onClick={(e) => HandleRadioButton('Quick')}>
                  <input type="radio" id="Quick" onChange={(e) => HandleRadioButton('Quick')} name="radio-group" checked={RadioState == 'Quick' ? true : null} />
                  <label htmlFor="Quick" >Quick</label>
                </p>
                <p className='ParaRadio' onClick={(e) => HandleRadioButton('Desired')}>
                  <input type="radio" id="Desired" onChange={(e) => HandleRadioButton('Desired')} name="radio-group" checked={RadioState == 'Desired' ? true : null} />
                  <label htmlFor="Desired" >Desired</label>
                </p>
              </div>


            </div>
            {
              ShowSliderState ?
                <div className='SlideForJoinSpeed'>

                  <div className="wrapper">
                    <div className="content">

                      {/* <Slider value={value} onChange={handleChange} aria-labelledby="continuous-slider" /> */}

                      <div className="range">
                        <div className="range-slider">
                          <label className='LAbleSlider' htmlFor="range">
                            Time between each membership:
                            {RangeValue == "7" ? "60" : RangeValue == "6" ? "50" : RangeValue == "5" ? "40" : RangeValue == "4" ? "30" : RangeValue == "3" ? "20" : RangeValue == "2" ? "10" : RangeValue == "1" ? "5" : null}</label>
                          {/* {DesiredTime == "60" || DesiredTime == "50" || DesiredTime == "40" || DesiredTime == "30" || DesiredTime == "20" || DesiredTime == "10" || DesiredTime == "5" ? DesiredTime : null} second</label> */}
                          <br />
                          <input
                            type="range"
                            min="1"
                            max="7"
                            value={RangeValue}
                            className="range-input"
                            id="range4"
                            step="1"
                            key="1"
                            onInput={ChangeRangeValue}
                            onChange={ChangeRangeValue}

                          />
                          {/* <SliderCustom changeValue={changeValue} setchangeValue={setchangeValue} /> */}
                          {/* <input
                          type="range"
                          min="1"
                          max="7"
                          value={changeValue}
                          // className="range-input"
                          // id="range4"
                          step="1"
                          key="1"
                          onTouchMove={(e) => setchangeValue(e.target.value)}
                          onChange={(e) => setchangeValue(e.target.value)}
                          // onInput={(e) => setchangeValue(e.target.value)}
                        /> */}

                          <div className="sliderticks">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>

                        {/* <div className="value4">0</div> */}
                      </div>
                    </div>
                  </div>


                </div> : null
            }

            <div className='SelectJoinSpeedButtons'>
              <button className='SelectJoinSpeedButtonsSecondry' onClick={() => setOpenSpeed(false)} >Cancel</button>
              <button className='SelectJoinSpeedButtonsPrimary' onClick={() => SaveDelayTime()}>Save</button>
            </div>
          </div>
        </ClickAwayListener>





      </SwipeableDrawer>





      {
        Loadingstate ? <Loading /> :
          <div className='JoinContainer'>
            <CoinHeader coins={CoinS} />

            <div className='TabsHeader'>
              <div className='TabsHeaderItem' onClick={() => SetTabsStatusJoin()}>
                <span className={Tab == 'join' ? 'TabSpanActive' : 'TabSpanDisable'} >
                  Join
                  {Tab == 'join' ? <div className='TabActivateBorder'></div> : <div className='TabDeActivateBorder'></div>}
                </span>

              </div>
              <div className='TabsHeaderItem' onClick={() => SetTabsStatusTransfer()}>
                <span className={Tab == 'transfer' ? 'TabSpanActive' : 'TabSpanDisable'} >
                  Transfer
                  {Tab == 'transfer' ? <div className='TabActivateBorder'></div> : <div className='TabDeActivateBorder'></div>}
                </span>

              </div>
            </div>

            {Tab == 'join' ?
              IsTerminate === 'true' ?
                <BannedUser  SetTabsStatusTransfer={SetTabsStatusTransfer} />
                :
                <>
                  <div className='JoinShowChannelTop'>
                    <div className='MoreJoin'>

                      {
                        ListToShow.length >= 1 ?
                          AutojonStatus || HiddenChannel || Limit ? null
                            :
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickmenu}>
                              <img src={More} />
                            </Button>
                          : null
                      }
                      {ListToShow.length >= 1 && !Limit ?

                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClosemenu}
                        >
                          <MenuItem onClick={handleClosemenu}>

                            <div className='DropDownShowChannel'>
                              <div className=''><img className='DropDownImageChannel' src={Photo} /></div>
                              <div className='DropDownDesChannel'>
                                <div className='DropDownDesTitle'>{ListToShow[0] ? ListToShow[0].title.length > 32 ? ListToShow[0].title.substr(0, 25) + '...' : ListToShow[0].title : null}</div>
                                <div className='DropDownDesUsername'>@{ListToShow[0] ? ListToShow[0].username : null}</div>
                              </div>
                            </div>
                          </MenuItem>
                          <Divider />
                          <MenuItem className="MenuItemContainer" onClick={handleClosemenu}>
                            <a className='MenuItem' target="_blank" href={`https://telegram.me/${ListToShow[0] ? ListToShow[0].username : null}`}>
                              <OpenInNewOutlinedIcon className="DropDownImage" /> View the channel</a></MenuItem>
                          <MenuItem className="MenuItemContainer" onClick={() => { handleClosemenu(); ShareChannelLink(`https://telegram.me/${ListToShow[0] ? ListToShow[0].username : null}`) }} >
                            <ShareOutlinedIcon className="DropDownImage" /> Share</MenuItem>
                          <MenuItem className="MenuItemContainer" onClick={(e) => { handleClosemenu(); ReportChannel(ListToShow[0] ? ListToShow[0] : null) }}>
                            <ReportOutlinedIcon className="DropDownImage" /> Channel report</MenuItem>

                        </Menu>
                        : null}

                    </div>


                    {

                      EndListJoin ?
                        <CoinEndList />
                        :
                        Limit && AutojonStatus == false ?
                          <CoinLimit />
                          :

                          CoinFilledState ?
                            <CoinFilled />
                            :
                            AutojonStatus ?
                              Limit ?
                                <CoinAutoJoin Limit={true} CoinJoin={CoinJoin} DelayForJoin={DelayForJoin} RadioState={ShowSpeed} />
                                :
                                <CoinAutoJoin Limit={false} CoinJoin={CoinJoin} DelayForJoin={DelayForJoin} RadioState={ShowSpeed} />

                              :
                              ListToShow.length >= 1 ?

                                ListToShow[0].hidden == true ?
                                  ListToShow.map((item, i) =>
                                    <CoinHidden List={item} key={i} setHiddenChannel={setHiddenChannel} JoinThisStatus={JoinThisStatus} Photo={Photo} setPhoto={setPhoto} />
                                  )
                                  //  <CoinHidden />
                                  :
                                  ListToShow.map((item, i) =>
                                    // <CoinTooMuchLeft setHiddenChannel={setHiddenChannel}/>

                                    <Coinitem List={item} key={i} setHiddenChannel={setHiddenChannel} JoinThisStatus={JoinThisStatus} Photo={Photo} setPhoto={setPhoto} />
                                  )

                                :
                                <div className='ChannelDetailsJoin' key={0}>


                                  <Skeleton variant="circle" width={127} height={127} className='Skeletoncircular' >
                                  </Skeleton>


                                  <div className='JoinShowChannelTitle'>

                                    <Skeleton variant="text" width={120} height={16} />
                                  </div>
                                  <div className='JoinShowChannelLink'>
                                    <Skeleton variant="text" width={140} height={16} />

                                  </div>
                                </div>

                    }






                  </div>


                  <div className='AutoJoinButtonContainer'>
                    {

                      // DisableNextButton ?
                      //   <button className='AutoJoinButtonDisable' >
                      //     Join + <img src={CoinsDoubleDisable} />
                      //   </button> :
                      //   EndListJoin ?
                      //     <button className='AutoJoinButtonDisable' >
                      //       Waiting to be out of restriction ...
                      //     </button>



                      //     :
                      //     JoinThisStatus ?
                      //       <button className='AutoJoinButtonDisable' >
                      //         Join + <img src={CoinsDoubleDisable} />
                      //       </button>
                      //       :
                      // ListToShow.length >= 1 ?
                      Limit ?

                        <button className='ManualJoinButtonDisable' >
                          {LimitTimeToUi.h != undefined ? LimitTimeToUi.h : null}
                          :
                          {LimitTimeToUi.m != undefined ? LimitTimeToUi.m : null}
                          :
                          {LimitTimeToUi.s != undefined ? LimitTimeToUi.s + " " : null}

                          to continue again
                          {/* {LimitTime} */}

                        </button> :
                        JoinThisStatus ?
                          <button className='ManualJoinButtonDisable' >
                            Join + <img src={CoinsDoubleDisable} />
                          </button>
                          :
                          AutojonStatus ?
                            <button className='ManualJoinButtonDisable' >
                              Joining...
                              {/* + <img src={CoinsDoubleDisable} /> */}
                            </button>
                            :
                            CoinFilledState ?
                              <button className='ManualJoinButtonDisable' >
                                Join + <img src={CoinsDoubleDisable} />


                              </button>
                              :
                              <button className='ManualJoinButton' onClick={(e) => JoinThis()}>
                                Join + <img src={CoinsDouble} />
                              </button>
                      // :
                      // <Skeleton variant="text" className='SkeletonButtonJoin' />

                    }

                  </div>
                  <div className='AutoJoinTwoButtonRow'>
                    {
                      // ListToShow.length >= 1 ?
                      // JoinThisStatus ?
                      //   <button className='DisableAutoJoinSecButton' >
                      //     <img src={AutoIcon} />
                      //     Auto Join
                      //   </button>
                      //   :
                      JoinThisStatus ?
                        <button className='DisableAutoJoinSecButton' >
                          <img src={AutoJoinDisable} />
                          Auto Join
                        </button>
                        // <button className='ActiveAutoJoinSecButton'
                        //   onClick={(e) => ToggleOpenBSheetStartAuto(true)}>
                        //   <img src={AutoJoinDisable} />
                        //   Auto Join
                        // </button>

                        :
                        AutojonStatus ?
                          <button className='StopAutoJoinSecButton'
                            onClick={(e) => StopAutoJoin()}>
                            <img src={Stop} />
                            Stop
                          </button>
                          :
                          CoinFilledState ?
                            <button className='DisableAutoJoinSecButton' >
                              <img src={AutoIcon} />
                              Auto Join
                            </button>
                            :
                            Limit ?
                              <button className='ActiveAutoJoinSecButton'
                                onClick={(e) => ToggleOpenBSheetStartAuto(true)}>
                                <img src={AutoJoinDisable} />
                                Auto Join
                              </button> :
                              <button className='AutoJoinSecButton'
                                onClick={(e) => ToggleOpenBSheetStartAuto(true)}>
                                <img src={AutoIcon} />
                                Auto Join
                              </button>
                      // :
                      // <Skeleton variant="text" className='SkeletonButtonAutoJoin' />


                      // <button className={AutojonStatus ? 'DisableAutoJoinSecButton' : 'AutoJoinSecButton'}
                      //   onClick={AutojonStatus ? (e) => StopAutoJoin() : (e) => StartAutoJoin(true)}>
                      //   <img src={AutoIcon} />
                      //   Auto Join
                      // </button>
                    }

                    {
                      // ListToShow.length >= 1 ?
                      AutojonStatus ?
                        <button className='JoinSpeed' onClick={(e) => setOpenSpeed(true)}>
                          <img className='JoinspeedImage' src={speed} />
                          Join speed
                        </button>
                        :
                        Limit ?
                          <button className='DisableAutoJoinSecButton'>
                            <img src={NextIconDisable} />
                            Next
                          </button> :

                          JoinThisStatus ?
                            <button className='DisableAutoJoinSecButton'>
                              <img src={NextIconDisable} />
                              Next
                            </button>
                            :
                            CoinFilledState ?
                              <button className='DisableAutoJoinSecButton'>
                                <img src={NextIconDisable} />
                                Next
                              </button>
                              :
                              <button className='AutoJoinSecButtonNext' onClick={(e) => NextItem()}>
                                <img src={NextIcon} />
                                Next
                              </button>



                      // :
                      // <Skeleton variant="text" className='SkeletonButtonAutoJoin' />
                      // JoinThisStatus || EndListJoin ?

                      //  :
                      // DisableNextButton ?
                      //   <button className='AutoJoinSecButtonNext'DisableAutoJoinSecButton>
                      //     <div className="loadmini"></div>

                      //   </button>
                      //   :
                      // <button className='AutoJoinSecButtonNext' onClick={(e) => NextItem()}>
                      //   <img src={NextIcon} />NextIconDisable
                      //   Next
                      // </button>
                    }

                  </div>
                </>

              : <CoinTransfer
                setCountryCode={setCountryCode}
                CountryCode={CountryCode}
                PhoneError={PhoneError}
                setPhoneError={setPhoneError}
                setCoinError={setCoinError}
                CoinError={CoinError}
                setLimitTimeTransfer={setLimitTimeTransfer}
                LimitTimeTransfer={LimitTimeTransfer}
                setCoinSP={setCoinS}
              />
            }
          </div>


      }
    </div>

  )
}

export default Coin;
