import React from 'react'
import "./PaymentItem.css"
import './fonts/font.css'
import Snackbar from '@material-ui/core/Snackbar';
// import Button from '@material-ui/core/Button';

import moment from 'jalali-moment'

const PaymentItem = ({ data }) => {
  
    
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (newState)  => {
        setState({ open: true, ...newState });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };


    const TokenClicked=(token)=>{
        handleClick({
            vertical: 'bottom',
            horizontal: 'center',
        })
        setTimeout(() => {
            handleClose() 
        }, 3000);
        // console.log(token)
        navigator.clipboard.writeText(token);
       
    }
    return (
        <div className='PaymentItem'>



            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message="The token was copied"
                key={vertical + horizontal}
                bodyStyle={{ maxWidth: '100%', height: '200px' }}
            />

            <div className='PaymentItemRow'>
                <div className='TitlePayment'>Product</div>
                <div className='ValuePayment'>{data.member_count} Member</div>
            </div>
            <div className='PaymentItemRow'>
                <div className='TitlePayment'>Price</div>
                <div className='ValuePayment'>{data.price} $</div>
            </div>
            <div className='PaymentItemRow'>
                <div className='TitlePayment'>Date</div>
                <div className='ValuePayment'> {moment.from(data.date, 'fa', 'YYYY/MM/DD').format('YYYY/MM/DD')}</div>
               
                
            </div>
            <div className='PaymentItemRow'>
                <div className='TitlePayment'>ID</div>
                <div className='ValuePayment'>
                    {data.username}
                </div>
            </div>
            <div className='PaymentItemRow'>
                <div className='TitlePayment'>Store</div>
                <div className='ValuePayment'>{data.market}</div>
            </div>
            <div className='PaymentItemRow'>
                <div className='TitlePayment'>Token</div>
                <div className='ValuePayment BlueValue' 
                
                onClick={()=>{
                    TokenClicked(data.token); 
                  
                }}>
          
                  
                    {data.token.length > 16 ? data.token.substr(0, 16) : data.token}

                </div>
            </div>
        </div>
    )
}

export default PaymentItem