import React from 'react';
import { useState, useEffect } from 'react'
import './JoinBot.css';
import Icon from '../../Assets/joinbot.svg'

import limit from '../../Assets/limit.png'

import UserStore from '../../Stores/UserStore';
import axios from 'axios';
import cryptlib from 'cryptlib'
import { hello } from './Apis/FirstRegister';
import { TokenRegister } from './Apis/TokenRegister';
import { openArchive, openChat, searchChat } from '../../Actions/Client';
import { getChatSender, getChatUserId } from '../../Utils/Chat';
import AppStore from '../../Stores/ApplicationStore';
import TdLibController from '../../Controllers/TdLibController';
import ChatStore from '../../Stores/ChatStore';
import { getLastMessageContent } from '../../Utils/Chat';
import Loading from './Loading'
import { SetCookie } from './SetCookie';
import { GetCookie } from './GetCookie'
import { async } from 'tdweb';
import { changeChatDetailsVisibility } from '../../Actions/Chat';
import SearchChannel from './Action/SearchChannel'
import Snackbar from '@material-ui/core/Snackbar';

function JoinBot({ ChangeStatusRegisterMembersgram }) {
    const [loading, setloading] = useState(false);
    const [LoadingBig, setLoadingBig] = useState(true);
    const [User, setUser] = useState(false);
    const [LimitTime, setLimitTime] = useState(0)
    const [Limit, setLimit] = useState(false)
    const [LimitTimeToUi, setLimitTimeToUi] = useState('')
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
    const handleClick = (newState) => {
        setState({ open: true, ...newState });
    };
    const handleClose = () => {
        setState({ ...state, open: false });
    };
    const [SnackMessage, setSnackMessage] = useState('')
    const GetSnackbar = (message) => {
        setSnackMessage(message)
        handleClick({
            vertical: 'bottom',
            horizontal: 'center',
        })
        setTimeout(() => {
            handleClose()
        }, 3000);
    }

    const TokenRegisterfunc = async (result, userProfile) => {
        // console.log(userProfile)
        const iv = 'D%<{&A^bjha+VA#M'
        const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);
        const user = {
            data: {
                phonenumber: userProfile.phone_number,
                verifyCode: result
            }
        }
        // console.log(userProfile.phone_number, result)
        let encryptedText = cryptlib.encrypt(JSON.stringify(user), key, iv);
        let TokenRegistervar = await TokenRegister(encryptedText)
        // console.log(TokenRegistervar)
        return TokenRegistervar
        // SetCookie('RegisterMembersgram', TokenRegistervar.data.data.token, 1000)
    }
    // const openChatDetails = () => {
    //     // const { chatId } = this.state;
    //     const chat = ChatStore.get(1841346296);
    //     if (!chat) return;

    //     const { isSmallWidth } = AppStore;

    //     if (isSmallWidth) {
    //         openChat(1841346296, null, true);
    //     } else {
    //         changeChatDetailsVisibility(true);
    //     }
    // };
    // const getToken = async (result) => {
    //     let responsecode = await TokenRegisterfunc(result, User)
    //     console.log(responsecode)
    //     return responsecode
    // }
    const handleSubmit = async () => {
        // openChatDetails()
        localStorage.removeItem("Notice", null);

        localStorage.removeItem("RegisterMembersgram", null);
        localStorage.removeItem("autojoin", null);
        localStorage.removeItem("MyIndex", null);
        SetCookie("RegisterMembersgram", "", null);
        SetCookie("false", "", null);

        let Bot = await SearchChannel('Verify_mem_bot')
        setloading(true)
        // let Bot = await SearchChannel('')
        // console.log(Bot)
        if (Bot.code == 200) {
            GetSnackbar('Username is invalid');

        } else if (Bot.code == 400) {
            GetSnackbar("USERNAME_INVALID");
        } else if (Bot.code == 429) {
            let thenum = Bot.message.match(/\d+/)[0]
            // console.log(thenum)
            setLimitTime(thenum)
            setLimit(true)
            timer(thenum)
        } else if (Bot.id) {
            const MyID = UserStore.getMyId();
            let encryptedText
            // console.log(User)
            const iv = 'D%<{&A^bjha+VA#M'
            const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);
            const user = {
                data: {
                    phonenumber: User.phone_number,
                    tg_id: MyID,
                    market: 'google'
                }
            }
            // console.log(user)
            encryptedText = cryptlib.encrypt(JSON.stringify(user), key, iv);
            let hello1 = await hello(encryptedText)
            let firstRegisterStatus = await hello1
            // console.log(firstRegisterStatus)
            // alert(firstRegisterStatus.data.added_coin)
            // if (firstRegisterStatus.data.added_coin) {
              if (firstRegisterStatus.data.added_coin > 0) {
                // alert('15coin !!!')
                localStorage.setItem('NewUser',true)
            }else{
                localStorage.setItem('NewUser',false)

            }  
            // }
            
            if (firstRegisterStatus.status) {

                let responseawait = await TdLibController.send({
                    '@type': 'sendMessage',
                    chat_id: Bot.id,
                    reply_to_message_id: 0,
                    input_message_content: {
                        '@type': 'inputMessageContact',
                        contact: User

                    }
                });

                // console.log(responseawait)


                // setTimeout(() => {
                if (responseawait.id) {
                    //    const { chatId } = AppStore;
                    let result
                    // function greet() {
                    let j = 0
                    let i = setInterval(async () => {
                        // console.log(j)
                        try {
                            const chat = ChatStore.get(responseawait.chat_id);
                            // console.log(chat)
                            if (j < 5) {
                                if (chat.last_message.content.text) {

                                    // console.log(chat.last_message.content.text.text)
                                    let res = chat.last_message.content.text.text
                                    result = res.substring(26, 31);
                                    if (result) {
                                        clearInterval(i);
                                        let responsecode = await TokenRegisterfunc(result, User)
                                        // console.log(responsecode)
                                        // console.log(responsecode)
                                        if (responsecode.status == "success") {
                                            localStorage.setItem("RegisterMembersgram", true);
                                            ChangeStatusRegisterMembersgram()

                                            setloading(false)
                                        } else {
                                            GetSnackbar('Cant join the bot. Please try again later');

                                        }


                                    } else {
                                        GetSnackbar('Cant join the bot. Please try again later');
                                    }
                                } else {

                                }
                            } else {
                                clearInterval(i);
                                GetSnackbar('Cant join the bot. Please try again later');
                            }


                        } catch (err) {
                            GetSnackbar('error bot');
                            // setloading(false)
                        }
                        j++
                    }, 1000);




                } else {
                    GetSnackbar('Cant join the bot. Please try again later');

                }



                // }, 5000);
                // 

            }
        }
        setloading(false)
    };


    const GetProfileUser = () => {
        let userProfile = UserStore.get(UserStore.getMyId())
        if (userProfile) {
            console.log(userProfile)
            return userProfile
        } else {
            return false
        }
    }

    useEffect(() => {
        // timer(100)
        const interval = setInterval(() => {
            let pro = GetProfileUser()
            if (pro == false) {

            } else {
                setUser(pro)
                setloading(false)
                clearInterval(interval)
                setLoadingBig(false)
            }
        }, 3000);
    }, []);
    let timer1
    const timer = (limit) => {
        var sec = limit;
        timer1 = setInterval(function () {
            sec--;
            if (sec < 0) {
                clearInterval(timer1);
                setLimitTime(false)
                setLimit(false)
            } else {

                setLimitTime(sec)
                SecToMin(sec)

            }
        }, 1000);
    }
    const SecToMin = (d) => {

        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        m = String(m).padStart(2, "0");
        h = String(h).padStart(2, "0");
        s = String(s).padStart(2, "0");

        setLimitTimeToUi({ h, m, s })
    }

    const HandleLogout = () => {

        setloading(true)
        clearInterval(timer1);
        TdLibController.logOut();


    }
    return (
        <div className='JoinWithLoading'>
            {
                LoadingBig ? <Loading /> :
                Limit ?
                    <div className='JoinLimit' >
                         
                        <div className='JoinLimitHeader'>
                            <div> <img src={limit} /></div>
                            <div className='JoinLimitTitle'>Unable to join the bot</div>
                            <div className='JoinLimitDes'>Your account has been limited، you are unable to join the verification bot.
                                Please consider one of the following actions:
                            </div>
                            <div className='JoinLimitDes'>
                                1. Change your phone number.
                                2. Wait for your account to be released from the restriction and try again later.
                            </div>
                        </div>
                        <div className='JoinLimitFooter'>

                            <button className='ChangeNumberJoinLimit' onClick={(e) => HandleLogout()}>{loading ? <div className='LoadingContainerminiJoin'><div className="loadminijoin"></div></div> : 'Change the number'}</button>
                            <button className='RetryJoinLimit'>{LimitTimeToUi.h}:{LimitTimeToUi.m}:{LimitTimeToUi.s} remaining until retry</button>
                        </div>

                    </div>
                    :
                    <>

                        <div className='JoinBot' >



                            <div className='Icon' >
                                <img src={Icon} />
                            </div>

                            <div className='JoinHeader' >
                                Join the bot

                            </div>


                            <div className='JoinDescription'>
                                Please take the time to review our Privacy Policy and Terms of Use below </div> <div className='JoinButtonContainer' >
                                <button className='JoinButton'
                                    onClick={() => handleSubmit()} >
                                    {loading ? <div className='LoadingContainerminiJoin'><div className="loadminijoin"></div></div> : 'Join and get started'}
                                </button>

                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={open}
                            onClose={handleClose}
                            message={SnackMessage}
                            key={vertical + horizontal}

                        />
                    </>

            }


        </div>

    );
}

export default JoinBot;