import React, { useEffect,useState } from 'react'
import NoItem from './../../Assets/noitem.svg'
const OrderEmpty = ({text}) => {
  const [State, setState] = useState('')

  useEffect(() => {
    if(text == 'در حال انجام'){
      setState('processing')
    }else if(text == 'اتمام'){
      setState('done')
    }else if(text == "گزارش شده"){
      setState('reported')
    }else if(text == "توقف"){
      setState('stop')
    }else{
      setState('')
    }

}, [text])
  return (
    <div className='OrderEmpty'>
      <div className='OrderEmptyImg'>
        <img src={NoItem} />
      </div>
      <div className='OrderEmptyText'>
        There are no {State} orders
      </div>

    </div>
  )
}

export default OrderEmpty