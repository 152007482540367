import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
import cryptlib from 'cryptlib'
import { GetCookie } from './../GetCookie'
import { async } from 'tdweb';



export async function JoincChannel(chat,fromserver,model) {


// console.log(chat,fromserver)

// console.log(chat,fromserver,model)
    let apitoken = GetCookie('RegisterMembersgram')

    const iv = 'D%<{&A^bjha+VA#M'
    const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);
    const user = { data: {
        Id:fromserver.Id,
        _id:fromserver._id,
        username:fromserver.username,
        tgChannelId:fromserver.tgChannelId,
        chat:chat,
        joinstate:model

    } }
    let data = cryptlib.encrypt(JSON.stringify(user), key, iv);

    try {

        let result = await axios.post("https://webapi.membersgram.com/api/v2/joinchannel",
            {
                data:data
            },
            {
                headers: {
                    lan: '',
                    versionc: '',
                    market: 'WebAppP',
                    apitoken: apitoken,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                    'Content-Type':  'application/json',
                    // "Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                }
            },
        );
        // console.log(result)
        let mydata = result.data
        // let mydata = "0euCPaEzwuhZ7wuksnofMsAXoUsZPX+4AGvgH7rGVMGWNVLMH80cdNFZO87bhCiGsvPm2nq9yLJwKZ7MOJsUISsgrjClfe/CM5Zt5NtjpOs="
        //  let mydata =  "NYwaXJVJlXDwlwANVGhs8n8YYoSEvB6fj5ascRLyTbkB9txVptpZn1u3j4APwKfwws5u852YQatOh2XpzhjkCw/f/zCjLQdzDZQ2C+N2R/Nj6akTapImgD4NP0R4wi9LvqDMQjhHYqRqkfxxFdCuABkHzjKq0jfua4EOSpwhB9KwF4XnYvatx6FuforTR4ofmkaywKqxX1fZfeHF/6Zd6OEFJ66CcgUkVkGc4/CQD+ieBv21mFf1nz6OaIvt7SKk5ssEGICGJKkGe25aSv3b5D81g60T9N7FJw8J9vaH6Xh/gGxgMnjAQroIYynQiIlTAmGMHxlinGg2WRLR76OI2eYzfCO+cZ1Mft+zGOKxeqPqaidD0cLDSKisIVfU2v3osb1OkBhdyTe6ejAQAyWelSzj8vLjcrLXz8GPQIqP99h6Dbcde4nqL2AcKdY4alm8EQzFtiFfJdhO79Ty024rgPg21yK8gPsurTMvm2wNvmbgUOU7A25fxTpN2yS0ZKt4jNtTY8d59NsbV/XIydHsrilqHsbeefZB6wz9k+EugBpfweS8pVCBYB8EVdEAEV5e/Z0D5fUnM5rIL+YNQqTZOoE83aQLs5h+36O5h832G9X5XW5z783d2jpFKD6sCzqZXDYl/XJRKnOddj1W5kt3jM6/A5m0yHyJ68r7reB64b4ak14UU+9wmLvfV0V9JLzG8Brry05t/en3Sx3EBjHN7p1wZzHYlm3PU8FrEOMZPfdMEQNsCs3+/njGEAMF/UT0c0h2DgvgBdJJ5kpq+DkABc1ved/FWraIOSZBHEzcDAOb/OmwO6UVt+wMswLk4l2SXUtMU+XVjVwlDhZ4vC683i0Gg3xA0TkfrLSg/9AP/v8pqv1BKofE7cTxNFQMtzpWqZ4YkPAzO5WkEtGlGGIL5j4oiZlMMA83JtcOf9Zvye9ALFLMioo04bbvac00jGgqdPUZYqW+511j1LTdEaX+xQCDs5d2A0Cp3THXOHuBeBcDAt9hHl99sSiFgIDKPEB8CLglXx5wE5V6FdnuQfuWV0IFBWpAUVPpJEtFm0dm+a9Q0EMT8ZMeLbYs4LNBNbaYs1cc6uxXwAYfoNiOnz63ZlRhR4gUH1iJwbI8dPDXBbUR1OuTP0rIf60cycmeLna5zAoWwYXtnVWZIx4cMFd2me04u3UUlReAhaV7kRLJgqLYHJRMjYrfrCvkYwyvogcnFPtXNOdc6c01BfVp/mnhsNHnKaxb2UrMYeZE2aWoHnHrvzJkbap2PaANqQuAoU5J9115hnc6PQdMXOztamz66KGYQreWbMHrb5O8mL7gR/Bs+jY2mS9EpP1ouk0tos+qcgx44MLYFehbiLeYbNkYoD2aZ5imaJsRndpmsnihsgSi9rde1YxBBy8w6TRMBvHyqy7T0+KKRmSF5I223yrvyg=="

        var iv1 = 'D%<{&A^bjha+VA#M';
        var key1 = cryptlib.getHashSha256(iv1, 32)
        let resultRegister = JSON.parse(cryptlib.decrypt(mydata, key1, iv1))

        // console.log(resultRegister)
        return (resultRegister)
    } catch (error) {
        return (`err in login : ${error.message}`)
    }
}

