import React from 'react'
import NoticImg from './../../Assets/Notice.svg'
import './Notice.css'
import Checkbox from '@material-ui/core/Checkbox'
import Drawer from '@material-ui/core/Drawer';
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { GetMembers } from './Apis/GetMembers';
import GetChatDetails from './Action/GetChatDetails'
import { getSrc, loadChatContent } from './../../Utils/File';
import { getChatPhoneNumber } from "../../Utils/Chat";
import UserStore from "../../Stores/UserStore";
import {
    getChatUsername, getChatSubtitle, isChannelChat, getChatLetters, getChatTitle
} from '../../Utils/Chat';
import { Skeleton } from '@material-ui/lab';
import PayPalIcon from './../../Assets/paypal.svg'
import SecurePay from './../../Assets/SecurePay.svg'
import cryptlib from 'cryptlib'
import { async } from 'tdweb';
import { GetCookie } from './GetCookie'
import SearchChannel from './Action/SearchChannel'
import CoinNew from '../../Assets/Coin.svg'
import PersonIcon from '@material-ui/icons/Person';
import { StoreChannelByCoin } from './Apis/StoreChannelByCoin';
import CheckGreen from './../../Assets/checkgreen.svg'

const Notice = ({ seturl, url, PriceNotice, setPriceNotice, SkuNotice, setSkuNotice }) => {
    const [Checkboxval, setCheckboxval] = useState(false)
    const [ButtonStatus, setButtonStatus] = useState(false)
    const [TimerEnd, setTimerEnd] = useState(false)
    const [open, setOpen] = useState(false)
    const [Member, setMember] = useState('')
    const [PriceCoin, setPriceCoin] = useState('')
    const [PackId, setPackId] = useState('')
  const [openSuccess, setopenSuccess] = useState(false)
  const [Return, setReturn] = useState(false)
const GetBaak=()=>{
    setopenSuccess(false)
    history.push("/");
    seturl("/")
}
    const PayByCoin = async () => {
        let TgId = Data.id
        let Username = getChatUsername(Data.id)
        let Title = getChatTitle(Data.id)
        // let PackId = Package
        setOpen(false)
        let resp = await StoreChannelByCoin(TgId, Username, Title, PackId)
        // console.log(resp)
        if (resp.status == "success") {
          setopenSuccess(true)
          setReturn(true)
        } else if (resp.status == "error") {
          if (resp.code == 302) {
            setopenSuccess(true)
            setReturn('coin')
          } else if (resp.code == 300) {
            setopenSuccess(true)
            setReturn('error')
    
          } else if (resp.code == 301) {
            setopenSuccess(true)
            setReturn('error')
    
          } else if (resp.code == 303) {
            setopenSuccess(true)
            setReturn('error')
    
          }
          else if (resp.code == 304) {
            setopenSuccess(true)
            setReturn('error')
    
          }
    
    
        }
    
    
      }
    useEffect(() => {
        timer()
        seturl(window.location.pathname)

    }, [])
    let timer1
    const timer = () => {
        var sec = 10;

        timer1 = setInterval(function () {

            if (document.getElementById('safeTimerDisplay')) {
                document.getElementById('safeTimerDisplay').innerHTML = '(' + sec + 's)';
            } else {
                clearInterval(timer1);
            }

            sec--;
            if (sec < 0) {
                clearInterval(timer1);
                if (document.getElementById('safeTimerDisplay')) {
                    document.getElementById('safeTimerDisplay').innerHTML = '';
                }
                let check
                if (document.getElementById('checkbox')) {
                    check = document.getElementById('checkbox').checked;
                }


                if (check == true) {
                    localStorage.setItem("Notice", true)
                    setButtonStatus(true)
                }
                setTimerEnd(true)
            }



        }, 1000);
    }
    const HandleCheckbox = (e, TimerEnd) => {
        setCheckboxval(e)
        // console.log(e,TimerEnd)
        if (TimerEnd == true) {
            if (e == true) {
                CheckCheckBox(true)
            } else {
                CheckCheckBox(false)
            }
        }
    }
    const CheckCheckBox = (Checkboxval) => {
        if (Checkboxval) {
            setButtonStatus(true)
        } else {
            setButtonStatus(false)

        }
    }
    const history = useHistory();

    const urlParams = new URLSearchParams(window.location.search);
    let Package = urlParams.get('Package');
    let username = urlParams.get('username');
    let by_coin = urlParams.get('by_coin');
    // console.log(by_coin)
    if (!Package) {
        history.push("/");
    }
    if (!username) {
        history.push("/");
    }

    const [Photo, setPhoto] = useState([])
    const [Data, setData] = useState('')
    let apitoken = GetCookie('RegisterMembersgram')
    useEffect(() => {
        setTimeout(() => {
            if (apitoken) {
                GetMemerpack(apitoken)
            }
        }, 3000);
        const interval = setInterval(() => {
            if (!apitoken) {
                apitoken = GetCookie('RegisterMembersgram')
            } else {
                GetMemerpack(apitoken)
                clearInterval(interval)
            }
        }, 1000);
    }, [])



    const GetMemerpack = async (apitoken) => {

        let resp = await GetMembers(apitoken)
        // console.log(resp)
        if (resp) {
            if (by_coin == "true") {
                resp.data.coin_getmember.map(pack => {
                    if (pack._id == Package) {
                        // console.log(pack.sku,pack.price)
                        setMember(pack.member_count)
                        setPriceCoin(pack.coin)
                        setPackId(pack.Id)
                            // setPackId(pack.Id)
                        // setMember(pack.member_count)
                        // setPriceCoin(pack.coin)
                        
                    }
                })   
            }
            if (by_coin == "false") {
             resp.data.purchase_getmember.map(pack => {
                if (pack._id == Package) {
                    // console.log(pack.sku,pack.price,pack)
                    setSkuNotice(pack.sku)
                    setPriceNotice(pack.price)
                       setMember(pack.member_count)
                }
            })   
            }
            
        }
       
    }

    const GetData = async () => {
        // let element = await GetChatDetails(username);
        let chat = await SearchChannel(username)

        setData(chat)
        

    }
    useEffect(() => {
        setTimeout(() => {
            GetData()
        }, 50);
        setTimeout(() => {
            GetPhoto(Data)
        }, 80);


    }, [Data])

    const GetPhoto = (Data) => {
        if (Data.photo) {
            setPhoto(getSrc(Data.photo.small))
        }
    }
    let dataen;
    const GetPay = async () => {
        let phone = getChatPhoneNumber(UserStore.getMyId());
        phone = phone.replace('+', '')
        phone = phone.replace(/\s/g, '')
        let TgId=Data.id
        TgId= TgId.toString();
        TgId = TgId.replace('-100', '');
           TgId = parseInt(TgId)
        let data = {
            "phonenumber": phone,
            "sku": SkuNotice,
            "price": PriceNotice,
            "username": getChatUsername(Data.id),
            "tgChannelId":TgId,
            "title": Data.title
        }
        // console.log(data)
        // let respons = await GetPayApi(data)
        const iv = 'D%<{&A^bjha+VA#M'
        const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);

        dataen = cryptlib.encrypt(JSON.stringify(data), key, iv);
        // setDataEncript(dataen)
        // console.log(respons)
    }
    // useEffect(() => {
        //    if (Data.id) {

        if (getChatUsername(Data.id) &&  SkuNotice ) {
            GetPay()
        // setTimeout(() => {
        //     GetPay()
        // }, 3000);

        }


        // }
    // }, [])


    const Back = () => {
        clearInterval(timer1);
        seturl(`/ChannelsList?Package=${Package}&by_coin=${by_coin}`)
        history.push(`/ChannelsList?Package=${Package}&by_coin=${by_coin}`);
    }
    function onDismiss() {
        setOpen(false)
    }
    return (
        <div className='NoticeContainer'>
            <div>
                <Link
                    //  to={`/ChannelsList?Package=${Package}`} 
                    onClick={(e) => { Back(e) }}>
                    <ArrowBackSharpIcon

                        className='BackButtonNotice' />
                </Link>
                <div className='ImageOnTopNotice'>
                    <img src={NoticImg} className='ImageNotice' />
                </div>
                <div className='NoticeHeader'>
                    Member order notice
                </div>
                <div className='Paragraph'>
                    Depending on size of the order, completion of your order takes from few hours to few days
                </div>
                <div className='Paragraph'>
                    Note: Until the status of the order is "completed",Avoid doing these things. Otherwise your order will be canceled and the fee will not be refunded:
                </div>
                <div className='UlContainer'>
                    <ul className='ListNotice'>
                        <li>
                            Change channel type or group from public to private mode
                        </li>
                        <li>
                            Change the address (ID) of the channel or group
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div className='CheckboxText'>
                    <Checkbox
                        checked={Checkboxval}
                        onChange={(e) => HandleCheckbox(e.target.checked, TimerEnd)}
                        value="checkedB"
                        color="primary"
                        id='checkbox'
                    />
                    <label htmlFor='checkbox'>
                        I agree with the explanation
                    </label>

                </div>
                <div className='ButtonContainerNotice'>
                    {/* <Link to={`${ButtonStatus ?/Pay?Package=${Package} `} :null} className={ButtonStatus ? 'ButtonGotitNoticeActive' : 'ButtonGotitNoticeDeActive'} */}
                    {/* > */}
                    <button onClick={ButtonStatus ? (e) => setOpen(true) : null} className={ButtonStatus ? 'ButtonGotitNoticeActive' : 'ButtonGotitNoticeDeActive'} >
                         Got it&nbsp;<span id='safeTimerDisplay'></span>
                    </button>
                    <BottomSheet open={open} onDismiss={onDismiss} >
                        {/* <div className='MyBottomSheetPay'> */}
                        {
                            by_coin == 'false' ? 
                            <div className='MyBottomSheetPay'>
                            <div className='MyBottomSheetHeaderPay'>
                                <div className="RowBottomSheet">
                                    <div className="ImageChannelBottomSheet">
                                        {Photo.length != 0 ?
                                            <img src={Photo} className="ImageChannelPay" />
                                            :
                                            <Skeleton variant="circle" width={48} height={48} className='Skeletoncircular' >
                                            </Skeleton>
                                        }
                                        <div className="PackageAndChannelNameBottomSheet">
                                            <div className="PackageBottomSheet">
                                                {Member ? Member+ " Member" :
                                                    <Skeleton variant="text" width={120} height={16} />
                                                }


                                            </div>
                                            <div className="ChannelBottomSheet">

                                                {getChatUsername(Data.id) ? getChatUsername(Data.id) :
                                                    <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="PriceBottomSheet">
                                        {PriceNotice} $
                                    </div>
                                </div>
                            </div>
                            <div className='MyBottomSheetContentPay'>
                                <div>
                                    <img src={PayPalIcon} />
                                </div>
                                <div className="SecureTextAndIcon">
                                    <img src={SecurePay} className="SecureIcon" />
                                    Secure payment with PayPal
                                </div>

                            </div>
                            <div className='MyBottomSheetFooter'>
                                <a href={`https://webapi.membersgram.com/api/v2_2/paypal/webpurchasememberpaypal?data=${dataen}`} className='SecondryButtonPay' type="submit">Pay</a>
                                {/* <button className='SecondryButton' >Log out</button> */}
                            </div>
                        </div>:
                         <div className='MyBottomSheetPayCoin'>
                         <div className='MyBottomSheetPayCoinImageAndTitle'>
                           <div className='ImageChannelBottomSheet'>
                             {Photo.length != 0 ?
                               <img src={Photo} className="ImageChannelPay" />
                               :
                               <Skeleton variant="circle" width={48} height={48} className='Skeletoncircular' >
                               </Skeleton>
                             }
                           </div>
                           <div className='PayCoinTitleAndCount'>
                             <div className='PayCoinTitle'>
                               {
                                 Data ? getChatTitle(Data.id) ? getChatTitle(Data.id) :
                                   <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                                   :
         
                                   <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                               }
                             </div>
                             <div className='PayCoinMember'>
                               {getChatSubtitle(Data.id)}
                             </div>
                           </div>
                         </div>
                         <div className='MyBottomSheetPayCoinDetails'>
                           <div className='MyBottomSheetPayCoinDetailsItem'>
                             <div className='PayCoinTitleItem'>Price</div>
                             <div className='PayCoinValueItem'>{PriceCoin} <img className='CoinIconPayCoin' src={CoinNew} /></div>
                           </div>
                           <div className='MyBottomSheetPayCoinDetailsItem'>
                             <div className='PayCoinTitleItem'>Count</div>
                             <div className='PayCoinValueItem'>{Member} <PersonIcon className='PersonPayCoin' /></div>
                           </div>
                           <div className='MyBottomSheetPayCoinDetailsItem'>
                             <div className='PayCoinTitleItem'>Username</div>
                             <div className='PayCoinValueItem'> {
                               Data ? getChatUsername(Data.id) ? getChatUsername(Data.id) :
                                 <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                                 :
         
                                 <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                             }</div>
                           </div>
                         </div>
                         <div className='PayCoinSubmitContainer'>
                           <button className='PayCoinSubmit' onClick={(e) => { PayByCoin() }}>
                             Continue
                           </button>
                         </div>
                       </div>
                        }
                      
                    </BottomSheet>
                    <BottomSheet open={openSuccess}>
        <div className={Return == true ? 'MyBottomSheetPaySuccess' : 'MyBottomSheetPayUnSuccess'}>
          <div className='MyBottomSheetHeaderPaySuccess'>
            {Return == true ?
              <>
                <img src={CheckGreen} />
                Successful order
              </>
              :
              Return == 'coin' ?
                <>
                  Insufficient Balance
                </>

                :
                Return == 'error' ?
                  'Unsuccessful payment!'
                  :
                  null
            }




          </div>
          <div className='MyBottomSheetContentPaysuccess'>

            {Return == true ? <>
              <div>{Member}</div>
              <PersonIcon className='PersonIcon' />
              <div>
                were ordered for
              </div>

              <div className='ChannelIdPaySuccess'>
                {getChatUsername(Data.id)}
              </div>
            </>
              :
              Return == "coin" ?
                <div>
                  You don’t have enough coins to buy this bundle.
                </div>
                : null
            }

          </div>
          <div className='MyBottomSheetFooter'>
            <button className='SecondryButtonPay'
              onClick={(e) => GetBaak()} >Got it </button>

          </div>
        </div>
      </BottomSheet>
                </div>
            </div>

        </div>
    )
}

export default Notice