import React, { useEffect, useState } from 'react'
import PersonIcon from '@material-ui/icons/Person';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { Skeleton } from "@material-ui/lab";
import { getSrc } from './../../Utils/File';
import { getPhotoFromChat } from '../../Utils/Chat';
import { getChatLetters } from "../../Utils/Chat";
import { loadProfileMediaViewerContent } from '../../Utils/File';
import Loading from './Loading'
import { Link } from 'react-router-dom'
import TdLibController from './../../Controllers/TdLibController';


const OrdersItem = ({ data }) => {
  const [Photo, setPhoto] = useState(false)
  // const [Details, setDetails] = useState(false)

// console.log(data)
  



  const GetImage = async (id) => {
    // console.log(id)
  if(id == 'error'){
    setPhoto('123')
  } else if (id) {
      const photo = getPhotoFromChat(id);
      loadProfileMediaViewerContent(id, [photo]);
      let blob = getSrc(photo.small)

      loadProfileMediaViewerContent(id, [photo]);

      blob = getSrc(photo.small)
      if (!blob) {
        const interval = setInterval(() => {
          if (!blob) {

            loadProfileMediaViewerContent(id, [photo]);

            blob = getSrc(photo.small)
            // setPhoto('123')
            
          } else {
            clearInterval(interval)
            setPhoto(blob)

          }
        }, 1);


      } else { setPhoto(blob) }
    }

  }
  // console.log(Photo)
  useEffect(() => {
   
    // const username = getChatUsername(chatId);
  //  console.log(data)
   
    let promise = new Promise(function (resolve, reject) {
      let rea = TdLibController.send({
        '@type': 'searchPublicChat',
        username: data.username
      })
      resolve(rea)
    });
    promise.then(
      function (result) {
        if (result.photo) {
          GetImage(result.id)
        }else{
          GetImage('error')
        }; 
        // setDetails(result)
      },
      function (error) { GetImage('error') }
    );

  }, [data])
  // console.log(data)
  return (

    <Link to={`/OrderDetails?username=${data.username}&id=${data._id}`} className='OrdersItem' >
      <div className='OrdersPic'>
        {
          // data.photo ?
          //   data.image.image.length > 0 ?
          Photo == false?
              
              <Skeleton
              variant="circle"
              width={48}
              height={48}
              className="Skeletoncircular"
            />
         
              :
              Photo == '123'  ?  
             
              <div className="NotProfilePhotoOrders">
              <span className="NotProfilePhotoLetterOrders">
                {
                  // getChatLetters(data.title)
                  data.username.substr(0, 2).toUpperCase()
                }
              </span>
            </div>
            :
           

            <img src={Photo} className='OrderChannelImage' />
          // Details ?
          //   Details.map(item =>
          //     item.id == data.id ?
          //       <img src={item.photo.small} className='OrderChannelImage' />
          //       :
          //       null
          //   )


          //   :
          //   null

        }
      </div>
      <div className='OrdersPicAndId'>

        <div className='OrdersId'>
          <div>
            <span className='OrderSign'>@</span>
            <span className='OrderUsernameText'>{data.username.length>12 ?data.username.substr(0,12):data.username}
            </span>
          </div>
          <div className='OrdersMember'>

            <PersonIcon className='Personicon' />
            <span className='OrderReqCount'>{data.requestCount}
            </span>
          </div>
        </div>
        <div className='OrdersStatusShow'>
          {
            data.status == 'اتمام' ?
              data.requestCount > data.joinCount ?
                <div className='Stoped'>Stop <CancelIcon className='CancelIcon' /></div>

                :

                <div className='DoneOrders'>Done <CheckCircleIcon className="CheckCircleIcon" /></div>

              :
              data.status == "در حال انجام" ?
                <div className='InProcessOrder'>In Process <RefreshRoundedIcon className='RefreshIcon' /></div>
                :
                data.status == "گزارش شده" ?
                  <div className='Reported'>Report <ErrorIcon className='ErrorIcon' /> </div>
                  :
                  // data.status == "توقف" ?
                  //   <div className='Stoped'>Stoped <CancelIcon className='CancelIcon' /></div>
                  //   :
                  null
          }
        </div>
      </div>



    </Link>


  )
}

export default OrdersItem