import React from 'react'
import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
// import Country from './Country';
import TdLibController from './../../Controllers/TdLibController';
import {
    getChatUsername, getChatSubtitle, isChannelChat, getChatTitle, getChatLetters, getPhotoFromChat, getChatPhoneNumber,
} from '../../Utils/Chat';
import UserStore from "../../Stores/UserStore";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import data from './country.json';
import CloseIcon from '@material-ui/icons/Close';
import { CoinTransferRequest } from './Apis/CoinTransferRequest'
import { async } from 'tdweb';
import { BottomSheet } from 'react-spring-bottom-sheet'
import succesGreen from './../../Assets/succesGreen.svg'
const CoinTransfer = ({ setCountryCode, CountryCode, PhoneError, setPhoneError, setCoinError, CoinError, setLimitTimeTransfer, LimitTimeTransfer, setCoinSP }) => {
    const [open, setOpen] = React.useState(false);
    const [Country, setCountry] = useState(data);
    const [CoinCount, setCoinCount] = useState('')
    const [PhoneNumber, setPhoneNumber] = useState('')
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState('');
    const [openBottomSheet, setOpenBottomSheet] = useState(false)
    const [loading, setloading] = useState(false)
    let IsTerminate = localStorage.getItem("terminate")

    const HandleCoinCont = (value) => {
        setCoinError(false)
        setCoinCount(value)
    }
    const HandlePhoneNumber = (value) => {
        setPhoneError(false)
        setPhoneNumber(value)
    }
    const handleInputChange = e => {
        setSearchQuery(e.target.value);
        search(e.target.value);
    };

    const search = query => {
        let filteredResults = data.countries.filter(item =>

            item.name.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(filteredResults);
    };
    const selectCountry = (code) => {
        // console.log(code)
        code = code.replace('+', '')

        setCountryCode(code)
        handleClose()
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    // console.log(searchResults)
    const handleClose = () => {
        setOpen(false);
    };
    const getcode = async () => {
        let PhoneNumber
        let me
        if (IsTerminate === 'true') {
            PhoneNumber = localStorage.getItem('phoneNumber')
            // console.log(JSON.parse(me))
        } else {
            PhoneNumber = getChatPhoneNumber(UserStore.getMyId())
        }


        const countryCodePattern = /^\+(\d+)/;

        // Extract the country code
        const countryCodeMatch = PhoneNumber.match(countryCodePattern);
        const countryCode = countryCodeMatch ? countryCodeMatch[1] : '';

        setCountryCode(countryCode);
    }
    let timer1
    useEffect(() => {
        getcode()
        let Limit = localStorage.getItem("LimitTransfer");

        let NowTime = (new Date()).getTime();
        let Time = localStorage.getItem("LimitTransfertime")

        if (Limit > NowTime) {
            if (Time > 0) {

                setLimitTimeTransfer(Time)
                clearInterval(timer1);
                timer(Time)
                setCoinError("You can transfer coins only once per minute")
            } else {
                // console.log(2)
                setCoinError(false)
                clearInterval(timer1);
            }
        } else {
            // console.log(3)
            setCoinError(false)
            clearInterval(timer1);
        }

        //         if (Limit > NowTime) {
        // setLimitTimeTransfer(localStorage.getItem("LimitTransfertime")) 
        //  timer(localStorage.getItem("LimitTransfertime"))
        //         }else{
        //  setLimitTime(0) 

        //         }
    }, [])
    //  +1 6603455472

    const timer = (sec) => {
        let NowTime = (new Date()).getTime();
        let LimitFinal = NowTime + 60 * 1000
        localStorage.setItem("LimitTransfer", LimitFinal);
        localStorage.setItem("LimitTransferTimer", sec);

        timer1 = setInterval(function () {

            sec--;
            if (sec < 0) {
                clearInterval(timer1);
                setCoinError(false)
                // console.log(sec)
                setLimitTimeTransfer(0)
                localStorage.setItem("LimitTransferTimer", false);

            } else {
                setLimitTimeTransfer(sec)
                // console.log(sec)
                localStorage.setItem("LimitTransfertime", sec);

                // document.querySelector('#TimerTarget').innerHTML = "Remaining time: " + sec
            }

        }, 1000);
    }
    const Submit = async () => {
        setloading(true)
        // console.log(PhoneNumber, CountryCode, CoinCount)
        if (CountryCode != '') {
            if (PhoneNumber != '') {
                if (CoinCount != '') {
                    let SumPhone = CountryCode + PhoneNumber
                    let response = await CoinTransferRequest(SumPhone, CoinCount)
                    console.log(response)
                    if (response.status !== 'error') {
                        localStorage.setItem("UserCoins", response.data.user.coins)
                        setCoinSP(response.data.user.coins)
                    }


                    if (response.code == 302) {
                        if (response.messageErrors[0].type == 'coin') {
                            if (response.messageErrors[0].message == "You can transfer coins only once per minute") {
                                var sec = 60;

                                timer(sec)
                            }

                            setCoinError(response.messageErrors[0].message)
                        }
                        if (response.messageErrors[0].type == 'user') {
                            // if (response.messageErrors[0].message == "Number doesn't exist in app") {
                            //     setPhoneError("Number doesn't exist in app")
                            // } else if (response.messageErrors[0].message == "invalid value") {
                            //     setPhoneError("invalid value")
                            // }
                            setPhoneError(response.messageErrors[0].message)
                        }


                    } else if (response.code == 200) {
                        // alert(response.message)
                        setOpenBottomSheet(true)
                    }
                }
            }
        }

        setloading(false)

        // 
    }
    // console.log(PhoneError,LimitTimeTransfer)
    return (
        <div className='CoinTransferContainer'>
            <div className='CoinTransferHeader'>
                Coin transfer
            </div>
            <div className='CoinTransferRow'>
                <div className='CoinTransferCountry'>

                    <TextField id="outlined-basic"
                        // defaultValue={CountryCode}
                        value={CountryCode}
                        label=""
                        variant="outlined"
                        onClick={handleClickOpen}

                    />
                </div>
                <div className='CoinTransferPhone'>

                    {
                        PhoneError != false ?
                            <TextField
                                error
                                id="outlined-error-helper-text"
                                label="Receiver number"
                                value={PhoneNumber}
                                helperText={PhoneError}
                                variant="outlined"
                                onChange={(e) => HandlePhoneNumber(e.target.value)}
                            />
                            :
                            <TextField
                                id="outlined-basic"
                                label="Receiver number"
                                variant="outlined"
                                value={PhoneNumber}
                                onChange={(e) => HandlePhoneNumber(e.target.value)}
                            />
                    }


                </div>
            </div>
            <div className='CoinTransferRow'>

                <div className='CoinTransferCoinCount'>
                    {CoinError != false ?
                        <TextField
                            error
                            id="outlined-error-helper-text"
                            label="Coin count"
                            defaultValue="Hello World"
                            helperText={CoinError}
                            variant="outlined"
                            value={CoinCount}
                            onChange={(e) => HandleCoinCont(e.target.value)}
                        />

                        :
                        <TextField id="outlined-basic"
                            label="Coin count"
                            variant="outlined"
                            value={CoinCount}
                            onChange={(e) => HandleCoinCont(e.target.value)}
                        />
                    }


                </div>
            </div>
            <div className='CoinTransferRow'>
                {LimitTimeTransfer > 0 ?
                    <button className='TransferSubmitDisable'
                        id="TimerTarget" >
                        Remaining time:  {LimitTimeTransfer}
                    </button>
                    :
                    <button className='TransferSubmit' onClick={() => Submit()}>
                        {loading ? <div className='LoadingContainerminiJoin'><div className="loadminijoin"></div></div> : 'Transfer'}
                    </button>
                }

            </div>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}> */}
                <div className='SearchDiv'>
                    <input type='text' value={searchQuery}
                        className='SearchInput'
                        placeholder='Search country'
                        onChange={(e) => { handleInputChange(e) }} />
                    <CloseIcon onClick={handleClose} className='CloseModalIcon' />
                </div>

                {/* </DialogTitle> */}
                <DialogContent dividers>
                    <div className='ListOfCountry'>
                        {
                            searchResults.length > 0 && searchResults != '' ?
                                searchResults.map((item, i) =>
                                    <div className='ListCountryItem' key={i + 1}>
                                        <div key={i} onClick={() => selectCountry(item.code)} className='ListCountryItemLast'>
                                            {item.name}({item.code})
                                        </div>


                                    </div>
                                )
                                :
                                Country.countries.map((item, i) =>
                                    <div className='ListCountryItem'>
                                        <div key={i} onClick={() => selectCountry(item.code)} className='ListCountryItemLast'>
                                            {item.name}({item.code})
                                        </div>


                                    </div>
                                )
                        }
                    </div>



                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
            <BottomSheet open={openBottomSheet} onDismiss={() => setOpenBottomSheet(false)}>
                <div className='WarningBottomSheetStart'>
                    <div className='WarningBottomSheetStartTitle'>
                        <img src={succesGreen} className='SuccessIconTransfer' />  successful transfer
                    </div>

                    <div className='WarningBottomSheetStartText'>{CoinCount} coin transferred to “{CountryCode + PhoneNumber}”</div>
                    <div className='WarningBottomSheetStartButtons'>
                        <button className='WarningBottomSheetStartStart' onClick={() => setOpenBottomSheet(false)}>OK</button>
                    </div>
                </div>
            </BottomSheet>
        </div>
    )
}

export default CoinTransfer