import React, { useEffect,useState } from 'react'
import NoItem from './../../Assets/noitem.svg'
const PaymentEmpty = () => {
 


  return (
    <div className='OrderEmpty'>
      <div className='OrderEmptyImg'>
        <img src={NoItem} />
      </div>
      <div className='OrderEmptyText'>
      The list of payments is empty
      </div>

    </div>
  )
}

export default PaymentEmpty