import React from "react";
import { useState, useEffect } from "react";
import Membersgram from "../../Assets/MembersLogo.svg";
import UserStore from "../../Stores/UserStore";
import "./Header.css";
import "./fonts/font.css";
import { Skeleton } from "@material-ui/lab";
import { getSrc } from './../../Utils/File';
import { getPhotoFromChat, getChatUserId, isPrivateChat, isMeChat } from '../../Utils/Chat';
import SearchChannel from './Action/SearchChannel'
import {
  getChatUsername, getChatSubtitle, isChannelChat, getChatTitle, getChatLetters,getChatPhoneNumber
} from '../../Utils/Chat';
import { loadProfileMediaViewerContent, preloadProfileMediaViewerContent, saveOrDownload } from '../../Utils/File';

const Header = () => {

  // localStorage.removeItem("cache")

  const [User, setUser] = useState([])
  const [Photo, setPhoto] = useState('')

  const getMyProfile = async () => {
    let username = getChatUsername(UserStore.getMyId())
    // console.log(username)
    let IsTerminate = localStorage.getItem("terminate")
    if (IsTerminate === 'true') {
      

      // setPhoto(localStorage.getItem('profilePhoto'))
    } else {
      // if (username) {
      //   let chat = await SearchChannel(username)
      //   // console.log(chat)
      //   if (chat.photo) {
      //     const photo = getPhotoFromChat(UserStore.getMyId());
      //     loadProfileMediaViewerContent(UserStore.getMyId(), [photo]);
      //     let blob = getSrc(photo.small);
      //     if (!blob) {
      //       const interval = setInterval(() => {

      //         if (!blob) {

      //           loadProfileMediaViewerContent(UserStore.getMyId(), [photo]);

      //           blob = getSrc(photo.small)
      //           // setPhoto(blob)
      //         } else {
      //           clearInterval(interval)
      //           setPhoto(blob)
      //           localStorage.setItem('profilePhoto', blob)
      //         }
      //       }, 10);
      //       // console.log(blob)
      //     }
      //     else {
      //       setPhoto(blob)
      //       localStorage.setItem('profilePhoto', blob)
      //     }
      //     setUser(chat)
      //   } else {
      //     setPhoto('123')
      //   }

      // } else {
      //   const photo = getPhotoFromChat(UserStore.getMyId());
      //   loadProfileMediaViewerContent(UserStore.getMyId(), [photo]);
      //   // console.log(photo)
      //   let blob = getSrc(photo.small);

      //   if (photo) {
      //     const interval = setInterval(() => {

      //       if (!blob) {

      //         loadProfileMediaViewerContent(UserStore.getMyId(), [photo]);

      //         blob = getSrc(photo.small)
      //         // setPhoto(blob)
      //       } else {
      //         clearInterval(interval)
      //         setPhoto(blob)
      //         localStorage.setItem('profilePhoto', blob)
      //       }
      //     }, 10);
      //   } else {
      //     // console.log(blob)
      //     if (blob == '') {
      //       setPhoto('123')
      //     } else {
      //       let blob = getSrc(photo.small)
      //       setPhoto(blob)
      //       localStorage.setItem('profilePhoto', blob)
      //     }
      //   }

      // }
    }




  };
  let p= localStorage.getItem('profilePhoto')

  useEffect(() => {
    let IsTerminate = localStorage.getItem("terminate")
    if (IsTerminate === 'true') {
      setPhoto(p)
        // localStorage.setItem('cache', localStorage.getItem('myfiles'))
    } else {
        let MyID

        const intervalID = setInterval(() => {
            MyID = UserStore.getMyId()
            if (!MyID) {
                MyID = UserStore.getMyId()
            } else {
                // console.log(MyID)

                clearInterval(intervalID)

            }

        }, 10);
        // let me = JSON.parse(localStorage.getItem('me'))
        // console.log(me)

        const interval = setInterval(() => {

            let full = UserStore.get(MyID)
            const photo = getPhotoFromChat(MyID);
              loadProfileMediaViewerContent(MyID, [photo]);
            // console.log(full)
            // let photo = getPhotoFromChat(full.id)
            let PhoneNumber = getChatPhoneNumber(full.id)
            localStorage.setItem('phoneNumber',PhoneNumber)
            // console.log(photo)
            if(photo.small){

        
            let blob = getSrc(photo.small)
            // console.log(blob)
            if (!blob) {
                loadProfileMediaViewerContent(MyID, [photo]);
                blob = getSrc(photo.small)
            } else {
                // console.log(blob)
                localStorage.setItem('profilePhoto', blob)
                const resourceURL = blob;

                // Use fetch to retrieve the resource content
                fetch(resourceURL)
                    .then(response => response.blob())
                    .then(blob => {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const dataURL = reader.result; // The data URL
                            // console.log(dataURL);
                            localStorage.setItem('profilePhoto', dataURL)
                            setPhoto(dataURL)
                        };
                        reader.readAsDataURL(blob);
                    })
                    .catch(error => {
                        console.error('Error fetching resource:', error);
                    });
                clearInterval(interval)
            } 
           }else{
            localStorage.setItem('profilePhoto', '123')

           }

        }, 10);
    }
    setTimeout(() => {

      getMyProfile();
    }, 1000);


  }, []);

  return (
    <div className="Header">
      <div className="IconHeader">
        <img src={Membersgram} />

      </div>
      <div className="ProfileHeder">
        {
          // User != false ? (
            Photo != '123' && Photo != false ? (
            <img className='Channelimg' src={Photo} />
          ) :
          Photo == '123' ?
              (
                <div className="NotProfilePhoto">
                  <span className="NotProfilePhotoLetter">
                    {getChatLetters(UserStore.getMyId())}
                  </span>
                </div>
              )
              : Photo == false ?
                <Skeleton
                  variant="circle"
                  width={32}
                  height={32}
                  className="Skeletoncircular"
                />
                : null
        }







      </div>
    </div>
  );
};

export default Header;
