import React from 'react'
import Full from './../../Assets/full.svg'

const CoinFilled = () => {
    return (
        <div className='ChannelDetailsJoin'  >
            {
                // Photo != false ?
                //     JoinThisStatus ?
                //         <div className='LoadingForImageJoin'>
                //             <div className='LoadingForImageJoinLoad'>

                //             </div>
                //             <img className='ChannelImageCoinActive' src={Photo} />
                //         </div>
                //         :
                <div className='ImageJoinContainer'>

                    <img src={Full} />
                    {/* <img className='ChannelImageCoin' src={Photo} /> */}
                </div>

                // :
                // <div className='ImageJoinContainer'>
                //     <Skeleton variant="circle" width={127} height={127} className='Skeletoncircular' >
                //     </Skeleton>
                // </div>

            }

            <div className='JoinShowChannelLink'>

            account is filled
            </div>
            <div className='JoinShowChannelLink'>
            In Telegram, you can finally become a member of 500 channels or groups

            </div>
        </div>
    )
}

export default CoinFilled