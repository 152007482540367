import React from 'react'
import { useEffect, useState } from 'react'
import { GetUserJoins } from './Apis/GetUserJoins.js'
import { SendLeftChannels } from './Apis/SendLeftChannels.js'

import { async } from 'tdweb'
import TdLibController from './../../Controllers/TdLibController';
import { isChannelChat, isChatMember, isGroupChat } from '../../Utils/Chat';

const LeftCalc = ({ JoinUser, setJoinUser, UserChat, setUserChat }) => {

    const GetUserChannels = async () => {

        const chats = await TdLibController.send({
            '@type': 'getChats',
            chat_list: { '@type': 'chatListMain' },
            limit: 1000
        });
        // console.log(chats.chat_ids)
        setUserChat(chats.chat_ids)

        // console.log(chats)

    }
    const GetUserJoinApi = async () => {
        let res = await GetUserJoins()
        // console.log(res)
        setJoinUser(res.data.data)
    }
    let ExportArray = []
    let ExportArray_id = []
    const CalcLeftFunction = async () => {

        const timestamp = (new Date()).getTime(); // Get the current timestamp

        // console.log(timestamp)
        const halfHourInMilliseconds = 30 * 60 * 1000;
        // console.log(halfHourInMilliseconds)
        let TargetTimeCalc = timestamp + halfHourInMilliseconds

        // console.log(TargetTimeCalc)



        let TimeCalc = localStorage.getItem('TimeCalc')
        // console.log(timestamp)
        let LastCalc = JSON.parse(TimeCalc)
        // console.log(LastCalc)

        // if (LastCalc === null) {

        // } else{
        if (timestamp >= LastCalc) {
            if (JoinUser.length > 0) {
                if (UserChat.length > 0) {
                    for (let index = 0; index < JoinUser.length; index++) {
                        const itemToCheck = parseInt('-100' + JoinUser[index].tgChannelId);
                        if (UserChat.indexOf(itemToCheck) !== -1) {
                            //   console.log("مقدار وجود دارد در آرایه دوم");
                        } else {
                            //   console.log("مقدار وجود ندارد در آرایه دوم");
                            ExportArray.push(itemToCheck)
                            ExportArray_id.push(JoinUser[index]._id)
                        }
                    }
                    // console.log(ExportArray_id)
                    // for (let index = 0; index < ExportArray.length; index++) {
                    //     var isMember = isChatMember(ExportArray[index]);
                    //     if (isMember) {
                    //         ExportArray[index] = 0
                    //     }
                    // }
                    console.log(ExportArray)
                    localStorage.setItem('TimeCalc', JSON.stringify(TargetTimeCalc))
                    if (ExportArray.length > 0) {

                        let ResSendLeft = await SendLeftChannels(ExportArray_id)
                        console.log(ResSendLeft)
                    }

                }
            }

        }else{
            console.log('time greater than target')
        }
        // }


    }
    useEffect(() => {
        // console.log(UserChat)
        if (UserChat.length > 0) {
            setTimeout(() => {
                let AutojoinStatus = localStorage.getItem("autojoin")
                // console.log(UserChat)
                if (AutojoinStatus !== 'true' && UserChat.length > 0) {
                    CalcLeftFunction()
                }
            }, 1000);
        } else {
            GetUserJoinApi()
            GetUserChannels()
        }



    }, [UserChat])

    return (
        <div>

        </div>
    )
}

export default LeftCalc