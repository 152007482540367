import React from 'react'
import limit from './../../Assets/limit.png'
import LimitSmall from './../../Assets/LimitSmall.png'

const CoinLimit = () => {
  return (
    <div className='ChannelDetailsJoin'  >
      {
        // Photo != false ?
        //     JoinThisStatus ?
        //         <div className='LoadingForImageJoin'>
        //             <div className='LoadingForImageJoinLoad'>

        //             </div>
        //             <img className='ChannelImageCoinActive' src={Photo} />
        //         </div>
        //         :
        <div className='ImageJoinContainer'>

          <img src={limit} />
          {/* <img className='ChannelImageCoin' src={Photo} /> */}
        </div>

        // :
        // <div className='ImageJoinContainer'>
        //     <Skeleton variant="circle" width={127} height={127} className='Skeletoncircular' >
        //     </Skeleton>
        // </div>

      }

      <div className='JoinShowChannelLink'>

        {/* <img src={LimitSmall}/>  */}
        Telegram Join limit
      </div>
      <div className='JoinShowChannelLink'>
        To avoid wasting time, use Auto Join

      </div>
    </div>
  )
}

export default CoinLimit