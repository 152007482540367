import React from 'react';
import { useState, useEffect } from 'react'
import axios from 'axios';
import cryptlib from 'cryptlib'



export async function hello(enc) {

    try {
        let result = await axios.post('https://webapi.membersgram.com/api/v2/register/', {
            data: enc
        },
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                'Content-Type':  'application/json',
                "Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                market: 'WebAppP'
            }
        },)
        // console.log(result)
        let mydata = result.data
        var iv1 = 'D%<{&A^bjha+VA#M';
        var key1 = cryptlib.getHashSha256(iv1, 32)
        let resultRegister = JSON.parse(cryptlib.decrypt(mydata, key1, iv1))
        //    console.log(resultRegister)
        return resultRegister

    } catch (error) {
        return (`err in login : ${error.message}`)
    }

}