import React from 'react'
import axios from 'axios';
import cryptlib from 'cryptlib'

export async function  GetOrders (apitoken,page,filter) {
    // console.log(page,filter)
    const iv = 'D%<{&A^bjha+VA#M'
    const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);
    const user = { data: {} }
    let data = cryptlib.encrypt(JSON.stringify(user), key, iv);
    // let filter = 'در حال انجام'
    //  filter
    try {

        let result = await axios.post(`https://webapi.membersgram.com/api/v2_2/trackweborders?page=${page}&status=${filter}`,
            {
                "data": data
                
            },
            {
                headers: {
                    lan: '',
                    versionc: '',
                    market: 'WebAppP',
                    apitoken: apitoken,
                    'Content-Type': 'application/json',
                    
                }
            },
        );

        let mydata = result.data

        // console.log(mydata)
        var iv1 = 'D%<{&A^bjha+VA#M';
        var key1 = cryptlib.getHashSha256(iv1, 32)
        let resultRegister = JSON.parse(cryptlib.decrypt(mydata, key1, iv1))
        // console.log(resultRegister)
       
        return(resultRegister)
       
    } catch (error) {
        return (`err in getOrders : ${error}`)
    }
}

