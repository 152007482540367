import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
import cryptlib from 'cryptlib'
import { GetCookie } from './../GetCookie'
import { async } from 'tdweb';



export async function GetMembers(apitoken) {





    // let apitoken = GetCookie('RegisterMembersgram')
    // console.log(apitoken)
    const iv = 'D%<{&A^bjha+VA#M'
    const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);
    const user = { data: {} }
    let data = cryptlib.encrypt(JSON.stringify(user), key, iv);




    try {

        let result = await axios.post("https://webapi.membersgram.com/api/v2_1/getMembers",
            {
                "data": data
            },
            {
                headers: {
                    lan: '',
                    versionc: '',
                    market: 'WebAppP',
                    apitoken: apitoken,
                    'Content-Type': 'application/json'
                }
            },
        );

        let mydata = result.data

        // console.log(mydata)
        var iv1 = 'D%<{&A^bjha+VA#M';
        var key1 = cryptlib.getHashSha256(iv1, 32)
        let resultRegister = JSON.parse(cryptlib.decrypt(mydata, key1, iv1))
        // console.log(resultRegister)
        let Coins = resultRegister.data.user.coins
        localStorage.setItem('UserCoins', Coins)
        return ({ data: resultRegister.data.data, coin: Coins })
    } catch (error) {
        return (`err in getMembers : ${error}`)
    }






}

