import React from 'react'
import { useState, useEffect } from 'react';
import './BottomNavigation.css';
import "./fonts/font.css"
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ReceiptOutlined from '@material-ui/icons/ReceiptOutlined';
import { Link, Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import Receipt from '../../Assets/receipt.svg'
import Receiptfilled from '../../Assets/receiptfilled.svg'
// import CoinActive from'../../Assets/ic_get_coin_bottom_navigation_enable.png'
import CoinActive from'../../Assets/CoinActive.svg'

// import Coin from'../../Assets/ic_get_coin_bottom_navigation_disable.png'
import Coin from'../../Assets/CoinNavDeactive.svg'

// import Order from '../../Assets/ic_factor.svg'

const BottomNavigation = ({ url, seturl }) => {

    return (
        <nav className="nav">
            <Link to='/' className={url == '/' || url == undefined || url == '/ChannelsList' ? "nav__link nav__link--active" : 'nav__link nav__link'}
                onClick={(e) => seturl('/')}
            >
                {url == '/' ?
                    <HomeIcon fontSize='medium' />

                    :
                    <HomeOutlinedIcon fontSize='medium' />
                }


                <span className="nav__text">Home</span>
            </Link>
            <Link to='/Coin?Status=join' className={url == '/Coin' ? "nav__link nav__link--active" : 'nav__link nav__link'}
                onClick={(e) => seturl('/Coin')}
            >
                {url == '/Coin' ?
                    <img src={CoinActive} />
                    : <img src={Coin} />}



                <span className="nav__text">Coin</span>
            </Link>
            <Link to='/Orders' className={url == '/Orders' ? "nav__link nav__link--active" : 'nav__link nav__link'}
                onClick={(e) => seturl('/Orders')}
            >
                {url == '/Orders' ?
                    <img src={Receiptfilled} />
                    : <img src={Receipt} />}



                <span className="nav__text">Orders</span>
            </Link>
          
            <Link to='/Profile' className={url == '/Profile' || url =='LogOut' ? "nav__link nav__link--active" : 'nav__link nav__link'}
                onClick={(e) => seturl('/Profile')}
            >
                {url == '/Profile' ? <AccountCircleIcon fontSize='medium' /> : <AccountCircleOutlinedIcon fontSize='medium' />}


                <span className="nav__text">Profile</span>
            </Link>


        </nav>
    )
}

export default BottomNavigation