import React from 'react'
import './NoInternet.css'
import WifiOffIcon from '@material-ui/icons/WifiOff';


const NoInternet = () => {


    return (
        <div className='NoInternetContainer'>
            <div className='Wifi'>
                <WifiOffIcon className='WifiIcon' />
            </div>
            <div className='NoInternetText'>
                Please make sure your device is connected to the internet
            </div>
            <div className='NoInternetButtonContainer'>
                <button className='NoInternetButtonTry' onClick={(e)=>window.location.reload()}>
                    Retry
                </button>
            </div>

        </div>
    )
}

export default NoInternet