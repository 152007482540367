import React from "react";
import "./Profile.css";
import "./fonts/font.css";

import { getSrc, loadChatContent } from './../../Utils/File';
import { useState, useEffect } from "react";
import TdLibController from "../../Controllers/TdLibController";
import UserStore from "../../Stores/UserStore";
import { openChat, openUser } from "../../Actions/Client";
import GetChatDetails from "./Action/GetChatDetails";
import { Skeleton } from "@material-ui/lab";
import { getUserFullName } from "../../Utils/User";
import Logout from "./../../Assets/logout.svg";
import LogoProfile from "./../../Assets/LogoProfile.svg";
import PaymentIcon from "@material-ui/icons/Payment";
// import HelpIcon from "@material-ui/icons/Help";
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import { Link } from 'react-router-dom'
import { loadProfileMediaViewerContent } from '../../Utils/File';
import {
  getChatUsername, getChatSubtitle, isChannelChat, getChatTitle, getChatLetters, getPhotoFromChat, getChatPhoneNumber,
} from '../../Utils/Chat';
import SearchChannel from './Action/SearchChannel'
// import UserStore from '../../Stores/UserStore';

const Profile = ({ seturl,setPhoto,Photo }) => {

  const [User, setUser] = useState([])


  const getMyProfile = async () => {


    
    let IsTerminate = localStorage.getItem("terminate")
    if (IsTerminate === 'true') {
      localStorage.setItem('cache', localStorage.getItem('myfiles'))

    } else {
      let username = getChatUsername(UserStore.getMyId())
      // if (username) {
      //   let chat = await SearchChannel(username)
      //   localStorage.setItem('User', chat.toString())
      //   if (chat.photo) {
      //     const photo = getPhotoFromChat(UserStore.getMyId());
      //     loadProfileMediaViewerContent(UserStore.getMyId(), [photo]);
      //     let blob = getSrc(photo.small);
      //     if (!blob) {
      //       const interval = setInterval(() => {

      //         if (!blob) {

      //           loadProfileMediaViewerContent(UserStore.getMyId(), [photo]);

      //           blob = getSrc(photo.small)
      //           // setPhoto(blob)
      //         } else {
      //           clearInterval(interval)
      //           setPhoto(blob)
      //           // localStorage.setItem('profilePhotoBig', blob)

      //         }
      //       }, 10);
      //       // console.log(blob)
      //     }
      //     else {
      //       setPhoto(blob)
      //       // localStorage.setItem('profilePhotoBig', blob)


      //     }
      //     setUser(chat)
      //   } else {
      //     setPhoto('123')

      //   }


      // } else {
      //   const photo = getPhotoFromChat(UserStore.getMyId());
      //   loadProfileMediaViewerContent(UserStore.getMyId(), [photo]);
      //   // console.log(photo)
      //   let blob = getSrc(photo.small);

      //   if (photo) {
      //     const interval = setInterval(() => {

      //       if (!blob) {

      //         loadProfileMediaViewerContent(UserStore.getMyId(), [photo]);

      //         blob = getSrc(photo.small)
      //         // setPhoto(blob)
      //       } else {
      //         clearInterval(interval)
      //         setPhoto(blob)
      //         // localStorage.setItem('profilePhotoBig', blob)

      //       }
      //     }, 10);
      //   } else {
      //     // console.log(blob)
      //     if (blob == '') {
      //       setPhoto('123')
      //     } else {
      //       let blob = getSrc(photo.small)
      //       setPhoto(blob)
      //       // localStorage.setItem('profilePhotoBig', blob)

      //     }
      //   }


      // }
    }



  };
   let p= localStorage.getItem('profilePhoto')
   let me=JSON.parse(localStorage.getItem('me'))
  useEffect(() => {
    let IsTerminate = localStorage.getItem("terminate")
    if (IsTerminate === 'true') {
        // localStorage.setItem('cache', localStorage.getItem('myfiles'))
        setPhoto(p)
        // console.log(me)
    } else {
        let MyID

        const intervalID = setInterval(() => {
            MyID = UserStore.getMyId()
            if (!MyID) {
                MyID = UserStore.getMyId()
            } else {
                // console.log(MyID)

                clearInterval(intervalID)

            }

        }, 10);
        // let me = JSON.parse(localStorage.getItem('me'))
        // console.log(me)

        const interval = setInterval(() => {

            let full = UserStore.get(MyID)
            const photo = getPhotoFromChat(MyID);
              loadProfileMediaViewerContent(MyID, [photo]);
            // console.log(full)
            // let photo = getPhotoFromChat(full.id)
            let PhoneNumber = getChatPhoneNumber(full.id)
            localStorage.setItem('phoneNumber',PhoneNumber)
            // console.log(photo)
            if(photo.small){

        
            let blob = getSrc(photo.small)
            // console.log(blob)
            if (!blob) {
                loadProfileMediaViewerContent(MyID, [photo]);
                blob = getSrc(photo.small)
            } else {
                // console.log(blob)
                localStorage.setItem('profilePhoto', blob)
                const resourceURL = blob;

                // Use fetch to retrieve the resource content
                fetch(resourceURL)
                    .then(response => response.blob())
                    .then(blob => {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const dataURL = reader.result; // The data URL
                            // console.log(dataURL);
                            localStorage.setItem('profilePhoto', dataURL)
                            setPhoto(dataURL)
                        };
                        reader.readAsDataURL(blob);
                    })
                    .catch(error => {
                        console.error('Error fetching resource:', error);
                    });
                clearInterval(interval)
            } 
           }else{
            localStorage.setItem('profilePhoto', '123')

           }

        }, 10);
    }
    
    
    setTimeout(() => {

      getMyProfile();
    }, 1000);


  }, [p]);



  return (
    <div className="ProfileContainer">
      <div className="HeaderP">
        {/* <img className='Channelimg' src={parse[0].url} /> */}
        <div className="ProfileHeader">
          <div className="ProfileImage">
            {
              Photo != '123' && Photo != false ? (

                <img className='Channelimg' src={Photo} />

              ) :
              Photo == '123' ?
                  (
                    <div className="NotProfilePhotoProfile">
                      <span className="NotProfilePhotoLetter">
                        {getChatLetters(UserStore.getMyId()) ? getChatLetters(UserStore.getMyId()) : getChatLetters(User.id)}
                      </span>
                    </div>
                  )
                  : Photo == false ?
                    <Skeleton
                      variant="circle"
                      width={64}
                      height={64}
                      className="Skeletoncircular"
                    />
                    : null
            }
          </div>

          <div className="ProfileInfo">
            <div className="ProfileName">
              {getUserFullName(UserStore.getMyId()) ? getUserFullName(UserStore.getMyId()) :me.title }
            </div>
            <div className="ProfileNumber">
              {getChatPhoneNumber(UserStore.getMyId()) ? getChatPhoneNumber(UserStore.getMyId()) :localStorage.getItem('phoneNumber')}
            </div>
          </div>
        </div>
        <div className="ProfileContent">
          <Link to='/Payment' className="ProfilePayment">
            <PaymentIcon className="PaymentIcon" seturl={seturl} />
            Payment
          </Link>
          <a href="https://membersgram.com/google/google/" className="ProfileSupport">
            <HelpIcon className="HelpIcon" />
            Support
          </a>
        </div>
        <Link to="/LogOut" className="ProfileLogOut">
          <img className="LogoutIcon" src={Logout} />
          Log out
        </Link>
      </div>

      <div className="FooterP">
        <div className="LogoVersion">
          <img src={LogoProfile} />

          {/* <div className="Version">Version 6.1.0 (16206)</div> */}
        </div>
      </div>
    </div>
  );
};

export default Profile;
