import React from 'react'
import './OrderDetails.css'
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { GetOrders } from './Apis/GetOrders'
import { GetCookie } from './GetCookie'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { BottomSheet } from 'react-spring-bottom-sheet'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useEffect, useState } from 'react'
import OrderDetailsItem from './OrderDetailsItem'
import { async } from 'tdweb';
import SearchChannel from './Action/SearchChannel'
import { getPhotoFromChat } from '../../Utils/Chat';
import { loadProfileMediaViewerContent } from '../../Utils/File';
import { getSrc } from './../../Utils/File';
import { Skeleton } from "@material-ui/lab";
import Loading from './Loading'
import OrderEmpty from './OrderEmpty';
import { useHistory } from "react-router-dom";

const OrderDetails = ({ seturl }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const [ThisOrder, setThisOrder] = useState('')
    const [open, setOpen] = useState(false)
    const [Photo, setPhoto] = useState('')
    const [ShowItem, setShowItem] = useState(0)
    let username = urlParams.get('username');
    let _id = urlParams.get('id');
    const [FilterState, setFilterState] = useState('All')
    const [Title, setTitle] = useState('')
    const [NowPAge, setNowPAge] = useState(1)
    const [ShowMore, setShowMore] = useState(false)

    const GetChat = async () => {
        let details = await SearchChannel(username)
        // console.log(details)

        if (details != undefined) {
            setTitle(details.title)
            let id = details.id
            //    console.log(details)
            if (details.photo) {


                const photo = getPhotoFromChat(id);
                loadProfileMediaViewerContent(id, [photo]);
                let blob = getSrc(photo.small)

                loadProfileMediaViewerContent(id, [photo]);

                blob = getSrc(photo.small)
                if (!blob) {
                    const interval = setInterval(() => {
                        if (!blob) {

                            loadProfileMediaViewerContent(id, [photo]);

                            blob = getSrc(photo.small)
                            // setPhoto(blob)
                        } else {
                            clearInterval(interval)
                            setPhoto(blob)

                        }
                    }, 10);


                } else { setPhoto(blob) }
            } else {
                setPhoto(false)
            }

        } else {
            setPhoto(false)
            setTitle(details.title)
        }

    }
    const GetAllOrders = () => {
        setShowItem(1)
    }
    const MyOrders = async (apitoken) => {
        // console.log(NowPAge)
        let orders = await GetOrders(apitoken, NowPAge);
        // apitoken,page,filter
        let all = orders.data.data.pages
        let page = orders.data.data.page
        page=parseInt(page)
        orders = orders.data.data.docs
 
        if (all > NowPAge) {
            setNowPAge((prevState) => prevState + 1)
            setShowMore(true)
          let button = document.querySelector('.ShowMore')
          button.click()


        } else {
            setShowMore(false)
        }
      
        orders.map(item => {
            if (item.username == username) {
                setThisOrder((prevState) => [...prevState, item]);
            }
        })
    } 

     let apitoken = GetCookie('RegisterMembersgram')
    useEffect(() => {
        GetChat()
      
        MyOrders(apitoken)

    }, [])
    const [StatusFilter, setStatusFilter] = useState('Statuses')
    const [OrderFiltered, setOrderFiltered] = useState('')

    const SetFilter = (filter) => {
        if (filter == "All") {
            setStatusFilter("Statuses")
        } else if (filter == 'در حال انجام') {
            setStatusFilter("In prosess")
        } else if (filter == "اتمام") {
            setStatusFilter("Done")

        } else if (filter == "توقف") {
            setStatusFilter("Stop")

        } else if (filter == "گزارش شده") {
            setStatusFilter("Report")

        }


        let neworder
        // console.log(filter)
        if (filter == "توقف") {
            setFilterState('اتمام')
            neworder = ThisOrder.filter(item => item.status == 'اتمام' && item.requestCount > item.joinCount ? item : null)

        } else if (filter == "اتمام") {
            setFilterState('اتمام')
            neworder = ThisOrder.filter(item => item.status == 'اتمام' && item.requestCount <= item.joinCount ? item : null)
        } else {
            setFilterState(filter)
            neworder = ThisOrder.filter(item => item.status == filter ? item : null)
        }

        // console.log(neworder)
        setOrderFiltered(neworder)
        setOpen(false)
    }

    const history = useHistory();

    const Back = () => {
        // console.log('Back')
        seturl('/Orders')
        history.push("/Orders");
    }
    function onDismiss() {
        setOpen(false)
    }
    //   console.log(Photo)
    const Handlescroll = (e) => {
       
        // let element = document.querySelector('.OrderDetails')
     
        // if (element.scrollTop >= element.offsetHeight || element.scrollTop < element.offsetHeight ) {
        //   let button = document.querySelector('.ShowMore')
        //   // console.log(button)
        //   if (button != null) {
        //     button.click()
        //   }
        // }
    
    
      }
      console.log(ThisOrder)
    return (
        <div className='OrderDetails' onScroll={(e) => Handlescroll(e)}>
            <div className="OrderDetailsHeader">
                {ShowItem == 1 ?
                    <div className="BackButtonOrderDetails" onClick={() => { setShowItem(0); SetFilter('All') }}>
                        <ArrowBackSharpIcon className='BackIconOrderDetails' />
                    </div>
                    : <div className="BackButtonOrderDetails" onClick={() => { Back() }}>
                        <ArrowBackSharpIcon className='BackIconOrderDetails' />
                    </div>}
                <div>
                    {Photo === false ?
                        <div className="NotProfilePhotoOrders">
                            <span className="NotProfilePhotoLetterOrders">
                                {
                                    // getChatLetters(data.title)
                                    username.substr(0, 2).toUpperCase()
                                    // 12
                                }
                            </span>
                        </div>

                        :
                        Photo.length > 0 ?
                            <img src={Photo} className="OrderDetailsImg" />
                            : <Skeleton
                                variant="circle"
                                width={48}
                                height={48}
                                className="Skeletoncircular"
                            />

                    }

                </div>
                <div className="OrderDetailsUsernameAndTitle">
                    <div className='OrderDetailsTitle'>
                        {Title}
                    </div>
                    <div className='OrderDetailsUsername'>
                        @ {username}
                    </div>

                </div>
            </div>
            <div className="OrderDetailsContent">
                {ShowItem == 1 ?
                    <div className='FilterOrder'>
                        <button className={StatusFilter !== 'Statuses' ? 'StatusButtonActive' : 'StatusButton'} onClick={() => setOpen(true)}>
                            {StatusFilter}
                            <ArrowDropDownRoundedIcon />
                        </button>
                    </div> :
                    null}
                {

                    // ThisOrder.length > 0 ?
                    //     ThisOrder.map((item, i) =>
                    //         ShowItem == 0
                    //             ?
                    //             i == 0 ?
                    //                 <OrderDetailsItem item={item} key={i} />
                    //                 : null
                    //             :
                    //             FilterState == 'All' ?
                    //                 <OrderDetailsItem item={item} key={i} />
                    //                 :
                    //                 item.status == FilterState ?
                    //                     <OrderDetailsItem item={item} key={i} />
                    //                     :
                    //                     <div>
                    //                         there are no {FilterState} item
                    //                     </div>
                    //     )
                    //     : null
                    FilterState == 'All' ?

                        ThisOrder != "" ?


                            ThisOrder.map((item, i) =>
                                ShowItem == 0
                                    ?
                                    item._id == _id ?
                                        <OrderDetailsItem item={item} key={i} />
                                        : null
                                    :
                                    <OrderDetailsItem item={item} key={i} />
                            )

                            :

                            <Loading />
                        :
                        OrderFiltered != "" ?

                            OrderFiltered.map((item, i) =>
                                item.status == FilterState ? <OrderDetailsItem item={item} key={i} /> : null
                            )
                            :

                            <OrderEmpty text={FilterState} />
                }

                {ThisOrder.length > 1 ?
                    ShowItem == 0 ?

                        <div className="OrderDetailsShowMore">
                            <button className="OrderDetailsShowMoreButton"
                                onClick={() => GetAllOrders()}>
                                Show all orders
                            </button>

                        </div> : null : null
                }

                <div className="OrderDetailsWarning">
                    <h3>
                        Warning
                    </h3>
                    <ul className="OrderDetailsWarningList">
                        <li>Do not change ID</li>
                        <li>Do not change channel or group from public to private mode</li>
                        <li>Do not enable "Apply to Join"</li>
                        <li>Changing the ID has a negative effect on the left calculation</li>
                    </ul>
                </div>
            </div>
            <BottomSheet open={open} data-body-scroll-lock-ignore="true"
                snapPoints={({ minHeight }) => minHeight}
                blocking={true} onDismiss={onDismiss}>
                <div className='MyBottomSheetStatus'>
                    <div className='MyBottomSheetHeaderStatus'>
                        Status
                    </div>
                    <div className='MyBottomSheetContentStatus'>
                        <div className={StatusFilter == "Status" ? 'AllStatusBottomActive' : "AllStatusBottom"} onClick={() => { SetFilter('All') }}>
                            <div className='AllStatusInner'>All status
                            </div>
                        </div>
                        <div className={StatusFilter == "In prosess" ? 'StatusClassActive' : "StatusClass"} onClick={() => { SetFilter('در حال انجام') }}>
                            <RefreshIcon className='NotRotateicon' />   In prosess
                        </div>
                        <div className={StatusFilter == "Done" ? 'StatusClassActive' : "StatusClass"} onClick={() => { SetFilter('اتمام') }}>
                            <CheckCircleIcon className='BottomSheetIcons' />  Done
                        </div>
                        <div className={StatusFilter == "Stop" ? 'StatusClassActive' : "StatusClass"} onClick={() => { SetFilter('توقف') }}>
                            <CancelIcon className='BottomSheetIcons' />  Stop
                        </div>
                        <div className={StatusFilter == "Report" ? 'StatusClassActive' : "StatusClass"} onClick={() => { SetFilter('گزارش شده') }}>
                            <ErrorIcon className='BottomSheetIcons' /> Report
                        </div>
                    </div>
                </div>
            </BottomSheet>
            {ShowMore ?
                <button className="ShowMore" onClick={() => MyOrders(apitoken)}>
                123
                </button>

                : null}
        </div>
    )
}

export default OrderDetails