import React, { useEffect, useState } from 'react'
import './Orders.css'
import { GetOrders } from './Apis/GetOrders'
import { GetCookie } from './GetCookie'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { async } from 'tdweb';
import OrdersItem from './OrdersItem';
import { BottomSheet } from 'react-spring-bottom-sheet'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import Loading from './Loading'
import SearchChannel from './Action/SearchChannel'
import Snackbar from '@material-ui/core/Snackbar';

import OrderEmpty from './OrderEmpty';
// import InfiniteScroll from "react-infinite-scroll-component";


const Orders = () => {
  const [Orders, setOrders] = useState([])
  const [openb, setOpen] = useState(false)
  const [FilterState, setFilterState] = useState('All')
  const [AllPages, setAllPages] = useState('')
  const [NowPAge, setNowPAge] = useState(1)
  let apitoken = GetCookie('RegisterMembersgram')
  const [InlineLoading, setInlineLoading] = useState(false)
  const [ShowMore, setShowMore] = useState(false)

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  const handleClick = (newState) => {
    setState({ open: true, ...newState });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [SnackMessage, setSnackMessage] = useState('')
  const GetSnackbar = (message) => {
    setSnackMessage(message)
    handleClick({
      vertical: 'bottom',
      horizontal: 'center',
    })
    setTimeout(() => {
      handleClose()
    }, 3000);
  }

  const GetOrdersFunc = async (page, filter) => {

    if (filter == "All") {
      filter = ""
    } else if (filter == null) {
      filter = ""
    }
    // setInlineLoading(true)
    setShowMore(false)
    let orders = await GetOrders(apitoken, page, filter);
    if (orders.data) {

      let All = orders.data.data.pages
      if (orders.data.data.docs.length > 0) {
        orders = orders.data.data.docs
      } else {
        orders = false
      }
      let neworder;
      if (filter == "توقف") {

        neworder = orders.filter(item => item.status == 'اتمام' && item.requestCount > item.joinCount ? item : null)

      } else if (filter == "اتمام") {

        neworder = orders.filter(item => item.status == 'اتمام' && item.requestCount <= item.joinCount ? item : null)
      } else {
        neworder = orders
      }
      if (All > page) {
        setNowPAge((prevState) => prevState + 1)
        setShowMore(true)
        setInlineLoading(true)

      } else {
        setShowMore(false)
        setInlineLoading(false)
      }
      if (neworder != false) {
        // console.log(neworder)

        // setOrders([])
        for (let index = 0; index < neworder.length; index++) {
          // let details = await SearchChannel(orders[index].username)

          // if (details != undefined) {
          //   let image = { image: 'blob' }
          //   let merged = { ...details, ...orders[index], image };
          //   setOrders((prevState) => [...prevState, merged]);
          // }
          // else {
          setOrders((prevState) => [...prevState, neworder[index]]);
          // }

        }

      } else {
        setOrders(false)
      }

      // setInlineLoading(false)
      // if (All > page) {
      //   setShowMore(true)
      // } else {
      //   setShowMore(false)

      // }
    } else {
      GetSnackbar('Network Error')
    }
  }
  const filter = localStorage.getItem("FilterStatus");
  useEffect(() => {

    if (filter == null) {
      setStatusFilter("Statuses")
    } else if (filter == 'در حال انجام') {
      setStatusFilter("In prosess")
    } else if (filter == "اتمام") {
      setStatusFilter("Done")
    } else if (filter == "توقف") {
      setStatusFilter("Stop")
    } else if (filter == "گزارش شده") {
      setStatusFilter("Report")
    }



    GetOrdersFunc(NowPAge, filter);

  }, [filter])

  const [StatusFilter, setStatusFilter] = useState('Statuses')
  const [OrderFiltered, setOrderFiltered] = useState('')

  const SetFilter = (filter) => {
    setOrders([])
    setNowPAge(1)
    if (filter == "All") {
      setStatusFilter("Statuses")
    } else if (filter == 'در حال انجام') {
      setStatusFilter("In prosess")
    } else if (filter == "اتمام") {
      setStatusFilter("Done")
    } else if (filter == "توقف") {
      setStatusFilter("Stop")
    } else if (filter == "گزارش شده") {
      setStatusFilter("Report")
    }
    localStorage.setItem("FilterStatus", filter);


    let neworder

    if (filter == "توقف") {
      setFilterState('اتمام')
      // neworder = Orders.filter(item => item.status == 'اتمام' && item.requestCount > item.joinCount ? item : null)

    } else if (filter == "اتمام") {
      setFilterState('اتمام')
      // neworder = Orders.filter(item => item.status == 'اتمام' && item.requestCount <= item.joinCount ? item : null)
    } else {
      setFilterState(filter)
      // neworder = Orders.filter(item => item.status == filter ? item : null)
    }
    // GetOrdersFunc(NowPAge, filter);

    setOrderFiltered(neworder)
    setOpen(false)
  }

  function onDismiss() {
    setTimeout(() => setOpen(false), 100)
  }

  const Handlescroll = (e) => {
    // let element=document.querySelector('.OrderContainerItem')
    let element = document.querySelector('.OrdersContainer')
    // console.log(element.scrollTop)
    // console.log(element.offsetHeight)
    if (element.scrollTop >= element.offsetHeight || element.scrollTop < element.offsetHeight) {
      let button = document.querySelector('.ShowMore')
      // console.log(button)
      if (button != null) {
        button.click()
      }
    }


  }
  // console.log(Orders)
  return (
    <div className='Orders'>
      <div className='OrdersHeader'>
        Orders
      </div>
      <div className='FilterOrder'>
        <button className={StatusFilter !== 'Statuses' ? 'StatusButtonActive' : 'StatusButton'} onClick={() => setOpen(true)}>
          {StatusFilter}
          <ArrowDropDownRoundedIcon />
        </button>
      </div>
      <div className='OrdersContainer' onScroll={(e) => Handlescroll(e)} >
        <div className='OrderContainerItem' >


          {

            // FilterState == 'All' ?

            // Orders != false ?


            <>
              {
                Orders.length == 0 ?
                  <Loading />
                  :
                  Orders == false ?
                    <OrderEmpty />
                    :
                    Orders.map((item, i) =>
                      < OrdersItem data={item} key={i} id={item.id} />
                    )



              }
              {ShowMore ?
                <button className="ShowMore" onClick={() => GetOrdersFunc(NowPAge, filter)}>
                  <AddIcon className='ShowmoreIcon' />
                </button>

                : null}

              {/* {
                Orders == false ?
                <OrderEmpty />
                :
                <Loading />
              } */}
            </>

            // :




          }
          {InlineLoading ?
            <div className="loadinline"></div>
            :
            null
          }


        </div>
      </div>
      <BottomSheet open={openb} data-body-scroll-lock-ignore="true"
        snapPoints={({ minHeight }) => minHeight}
        blocking={true} onDismiss={onDismiss}>
        <div className='MyBottomSheetStatus'>
          <div className='MyBottomSheetHeaderStatus'>
            Status
          </div>
          <div className='MyBottomSheetContentStatus'>
            <div className={StatusFilter == "Status" ? 'AllStatusBottomActive' : "AllStatusBottom"} onClick={() => { SetFilter('All') }}>
              <div className='AllStatusInner'>All status
              </div>
            </div>
            <div className={StatusFilter == "In prosess" ? 'StatusClassActive' : "StatusClass"} onClick={() => { SetFilter('در حال انجام') }}>
              <RefreshIcon className='NotRotateicon' />   In prosess
            </div>
            <div className={StatusFilter == "Done" ? 'StatusClassActive' : "StatusClass"} onClick={() => { SetFilter('اتمام') }}>
              <CheckCircleIcon className='BottomSheetIcons' />  Done
            </div>
            <div className={StatusFilter == "Stop" ? 'StatusClassActive' : "StatusClass"} onClick={() => { SetFilter('توقف') }}>
              <CancelIcon className='BottomSheetIcons' />  Stop
            </div>
            <div className={StatusFilter == "Report" ? 'StatusClassActive' : "StatusClass"} onClick={() => { SetFilter('گزارش شده') }}>
              <ErrorIcon className='BottomSheetIcons' /> Report
            </div>
          </div>
        </div>
      </BottomSheet>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={SnackMessage}
        key={vertical + horizontal}

      />
    </div>
  )
}

export default Orders