import React from 'react';

export function SetCookie(cname, cvalue, minutes) {

   
        var d = new Date();
        d.setTime(d.getTime() + (minutes*60*5000000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    
}