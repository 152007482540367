import React from 'react'
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import "./fonts/font.css"
import "./Payment.css"
import PaymentItem from './PaymentItem'
// import { useHistory } from "react-router-dom";
import { async } from 'tdweb';
import { useEffect, useState } from 'react';
import { GetPayments } from './Apis/GetPayments'
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom'
import PaymentEmpty from './PaymentEmpty'
import Loading from './Loading'
import { useHistory } from "react-router-dom";

// import moment from "moment-jalaali";

const Payment = ({ seturl, url }) => {
    const [PaymentList, setPaymentList] = useState("init")
    // let history = useHistory();
    // /api/v2_1/getUserTransActions
    // /api/v2_1/getUserTransActions?filter=member
    // /api/v2_1/getUserTransActions?filter=coin
    // /api/v2_1/getUserTransActions?filter=view
    const GetPaymentUser = async () => {
        let res = await GetPayments();
        // console.log(res)
        if (res.code == 200) {
            if (res.data.data.docs.length >= 0) {
                setPaymentList(res.data.data.docs)
            } else {
                setPaymentList(false)
            }
        } else {
            setPaymentList(false)

        }


    }
    useEffect(() => {
        seturl(window.location.pathname)
        GetPaymentUser()
    }, [])
    const history = useHistory();
    const Back = () => {
        // console.log('Back')
        seturl('/Profile')
        history.push("/Profile");
      }
    // console.log(PaymentList)
    return (
        <div className='PaymentContainer'>
            <div className='PaymentHeader'>
                <div onClick={()=>Back()} className='ProfileLinkBack'>
                    <ArrowBackSharpIcon
                        className='BackButtonPayment' />
                </div>

                <div>
                    Payment
                </div>

            </div>
            <div className='PaymentContent'>
                {
                    PaymentList == "init" ?
                        <Loading />
                        : PaymentList == false ? <PaymentEmpty /> :

                            PaymentList.length > 0
                                ?
                                PaymentList.map((item, i) =>
                                    <PaymentItem key={i} data={item} />
                                )

                                :
                                // <Skeleton variant="rounded" animation="wave" className='SkeletonPayments'/>

                                <>
                                    <Skeleton className='SkeletonPayments' />
                                    <Skeleton animation="wave" className='SkeletonPayments' />
                                    <Skeleton animation={false} className='SkeletonPayments' />
                                </>


                }
            </div>


        </div>
    )
}

export default Payment