import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getSrc, loadChatContent } from './../../Utils/File';
import {
    getChatUsername, getChatSubtitle, isChannelChat, getChatLetters, getChatTitle
} from '../../Utils/Chat';
import ChannelIcon from '../../Assets/channelicon.svg'
import GroupIcon from '../../Assets/groupicon.svg'
import { Skeleton } from '@material-ui/lab';
// import { getPhotoFromChat } from '../../Utils/Chat';
import { useEffect } from 'react'
import { getPhotoFromChat, getChatUserId, isPrivateChat, isMeChat } from '../../Utils/Chat';

import { loadProfileMediaViewerContent, preloadProfileMediaViewerContent, saveOrDownload } from '../../Utils/File';
import FileStore from '../../Stores/FileStore';
const SearchInChannels = ({ chat, Package,CheckChannelBeforeFunction }) => {
    const [PhotoNow, setPhotoNow] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            if (PhotoNow == false) {
                setInterval(() => {
                    // console.log(chat.photo)
                    if (chat.photo) {
                        const photo = getPhotoFromChat(chat.id);
                        loadProfileMediaViewerContent(chat.id, [photo]);
                        // preloadProfileMediaViewerContent(chat.id, [photo])
                        // const file = FileStore.get(photo.big.id)
                        // const filesmall = FileStore.get(photo.small.id)
                        // console.log(file, filesmall,getSrc(chat.photo.small) )
                        //    console.log(getSrc(chat.photo.small))   
                        if (getSrc(chat.photo.small) != "") {
                            setPhotoNow(getSrc(chat.photo.small))
                        }
                    }
                }, 10);
            }


        }, 10);

    }, [chat])

    // console.log(PhotoNow)
    return (
        <div
        onClick={(e)=>CheckChannelBeforeFunction(chat.id)}
         className='ListOfChannel' key={chat.id} >

            <div className='Itemchannel'>

                <div className='ImageAndDec'>
                    <div>
                        {
                            chat ?
                                chat.photo ?
                                    <img className='Channelimg'
                                        src={PhotoNow != false ?
                                            PhotoNow :
                                            `data:image/jpeg;base64,${chat.photo.minithumbnail.data}`
                                        }
                                    />
                                    :
                                    <div className='NotProfilePhoto1'>
                                        <span className='NotProfilePhotoLetter1'>
                                            {
                                                getChatLetters(chat.id)
                                            }
                                        </span>
                                    </div>
                                :
                                <Skeleton variant="circle" width={56} height={56} className='Skeletoncircular' >
                                </Skeleton>
                        }
                    </div>

                 
                </div>

                <div  className='itemDesAndButton'>
                <div className='itemDescription'>
                        <div className="UsernameAndIcon">
                            <div>
                                {
                                    isChannelChat(chat.id) ?
                                        <img className='IconChannel' src={ChannelIcon} /> :
                                        <img className='IconChannel' src={GroupIcon} />
                                }
                            </div>
                            <div>
                                {

                                    getChatTitle(chat.id).length > 16 ?
                                        getChatTitle(chat.id).substr(0, 16) + '...' :
                                        getChatTitle(chat.id)
                                }
                            </div>
                        </div>

                        <div>
                            {getChatSubtitle(chat.id)}

                        </div>

                    </div>
                    <button className='ButoonOrder' >
                        Get
                    </button>
                </div>

            </div>




        </div>
    )
}

export default SearchInChannels