import React from 'react'
import BigCoin from '../../Assets/BigCoin.svg'

const CoinAutoJoin = ({ CoinJoin, DelayForJoin, Limit,RadioState}) => {
    // console.log(DelayForJoin,RadioState)
    let desired=false; 
    let Speed = localStorage.getItem("Speed");
    // console.log(Speed)
    if (Speed == 5 || Speed == 10) {
        DelayForJoin = 'Quick'
    } else if (Speed == 20 || Speed == 30) {
        DelayForJoin = 'Medium'
    } else if (Speed >= 40) {
        DelayForJoin = 'Slowly'

    }else {
       
        if (Speed == 'Quick') {
            DelayForJoin = 'Quick'
        } else if (Speed =='Medium') {
            DelayForJoin = 'Medium'
        } else if (Speed=="Slowly") {
            DelayForJoin = 'Slowly'
    
        }
    }
    return (
        <div className='ChannelDetailsJoin'  >
            {
                <div className='LoadingForBigCoinContainer'>
                    <div className='LoadingForBigCoin'>
                        <img className={DelayForJoin == 'Slowly' ? 'BigCoin10S' : DelayForJoin == 'Medium' ? 'BigCoin5S' : DelayForJoin == 'Quick' ? 'BigCoin2S' : 'BigCoin2S'} src={BigCoin} />
                    </div>
                    <div className='CoinCount'>{CoinJoin > 0 ? '+' + CoinJoin : CoinJoin}</div>
                </div>

                // <div className='ImageJoinContainer'>
                //     <img className='BigCoin' src={BigCoin} />
                // </div>



            }
            {Limit ? <> 
             <div className='JoinShowChannelLink'>

                    Telegram Join limit
                </div>
                 <div className='JoinShowChannelLink'>

                 Join speed : {RadioState} 
             </div>
            </>
              
                :
                <div className='JoinShowChannelLink'>

                    Join speed : {RadioState}
                </div>
            

              
            }


        </div>
    )
}

export default CoinAutoJoin