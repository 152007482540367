import React, { useEffect } from 'react'
import { getSrc } from './../../Utils/File';
import { getPhotoFromChat } from '../../Utils/Chat';
import { loadProfileMediaViewerContent } from '../../Utils/File';
import Logo from '../../Assets/bg.jpg'
import PersonGrey from '../../Assets/PersonGrey.png'
import { useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import TdLibController from '../../Controllers/TdLibController';
import hiddenitem from '../../Assets/hiddenitem.png'
import {
    getChatUsername, getChatSubtitle, isChannelChat, getChatLetters, getChatTitle
} from '../../Utils/Chat';
const CoinHidden = ({ List, JoinThisStatus,Photo,setPhoto ,setHiddenChannel}) => {
    // const [Photo, setPhoto] = useState(false)

    // const GetImage = async (username) => {

    //     let rea = await TdLibController.send({
    //         '@type': 'searchPublicChat',
    //         username: username
    //     })

    //     let id = rea.id
    //     if (id == 'error') {
    //         setPhoto('123')
    //     } else if (id) {
    //         const photo = await getPhotoFromChat(id);
    //         // console.log(photo)
    //         loadProfileMediaViewerContent(id, [photo]);
    //         let blob = getSrc(photo.small)

    //         loadProfileMediaViewerContent(id, [photo]);

    //         blob = getSrc(photo.small)
    //         if (!blob) {
    //             const interval = setInterval(() => {
    //                 if (!blob) {

    //                     loadProfileMediaViewerContent(id, [photo]);

    //                     blob = getSrc(photo.small)
    //                     setPhoto('123')

    //                 } else {
    //                     clearInterval(interval)
    //                     setPhoto(blob)

    //                 }
    //             }, 1);


    //         } else { setPhoto(blob) }
    //     }

    // }


    useEffect(() => {
        // setTimeout(() => {
            setHiddenChannel(true)
        // setPhoto(false)
        // console.log(List)
        // if (List.tgChannelId) {
        //     // console.log(List.tgChannelId)
        //     GetImage(List.username)
        // }


        // }, 3000);

    }, [JoinThisStatus,List])



    // console.log(Photo)
    return (
        
        <div className='ChannelDetailsJoin'  >
            {
             
                   
                        <div className='ImageJoinContainer'>
                            <img className='ChannelImageCoin' src={hiddenitem} />
                        </div>

                   

            }

            <div className='JoinShowChannelTitle'>

            Secret ID
            </div>
            <div className='JoinShowChannelLink'>
                {/* <img src={PersonGrey} /> */}
                Channel/group ID hidden at admin's request

            </div>
        </div>
    )
}

export default CoinHidden