import React from 'react'
import Icon from '../../Assets/person_black.svg'
import { Link, Switch, BrowserRouter as Router, Route } from 'react-router-dom';

import { useState } from 'react';

const PackageItem = (props) => {
    // console.log(props)
    return (
        // {/Notice?id=${chat.id}}
        <Link to={`/ChannelsList?Package=${props.props.id}&&by_coin=${false}`} className='BodyContentItem'>
            <div className='BodyContentSep'>
                <div className='IconItem'>
                    <img src={Icon} />
                </div>
                <div className='countItem'>
                    <div className='countItem1'>
                        {props.props.member_count}
                        {/* <img src={Fire} /> */}
                    </div>
                    {props.props.discount_member_count!=undefined ?
                        <div className='countItem2'>
                            {props.props.discount_member_count}

                        </div> : null

                    }

                </div>
            </div>

            <div className='BodyContentSep'>
                <div className='OfferContainer'>
                    {props.props.discountString ?
                        <div className='Off'>
                            {/* {props.props.discountString.replace('OFF', '')} */}
                            {props.props.discountString}

                        </div> :
                        null}

                    {/* <div className='BestOffer'>
                        Best offer
                    </div> */}
                </div>

                <div className='OrderAndOff'>
                    <div>
                        <button className='SecondaryButton'> {props.props.price} $</button>

                    </div>
                    {props.props.discountweb ?
                        <div className='PrevPrice'>

                            {/* {props.props.price * 100 / props.props.discountString.match(/\d+/)} $ */}
                            {props.props.discountweb} 

                            
                        </div>
                        : null}

                </div>
            </div>

        </Link>
    )
}

export default PackageItem