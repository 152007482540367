import React from 'react';
import { useState, useEffect } from 'react'
import axios from 'axios';
import cryptlib from 'cryptlib'
import { SetCookie } from '../SetCookie';


export async function TokenRegister(enc) {
    // console.log(enc)
    let Status
    try {
        let result = await axios.post('https://webapi.membersgram.com/api/v2/verifyRegister', {
            data: enc
        },
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                'Content-Type':  'application/json',
                "Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                market: 'WebAppP'
            }
        },)
        // console.log(result)
        let mydata = result.data
        var iv1 = 'D%<{&A^bjha+VA#M';
        var key1 = cryptlib.getHashSha256(iv1, 32)
        let resultRegister = JSON.parse(cryptlib.decrypt(mydata, key1, iv1))
        // console.log(resultRegister)
      
        if (resultRegister.status == "success") {
            localStorage.setItem("RegisterMembersgram", resultRegister.data.data.token);

            SetCookie("RegisterMembersgram", resultRegister.data.data.token, 2000)
            Status = resultRegister
        } else {
            Status = 'error'
        }
        // return Status


    } catch (error) {
        // console.log(error)
        // return (`err in login : ${error.message}`)
        Status = error.message

    }
    return Status

}