import React from 'react'
import { Link } from 'react-router-dom'
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import './LogOut.css'
import Quiz from './../../Assets/quiz.svg'
import LogoutRed from './../../Assets/logoutRed.svg'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useState } from 'react';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import TdLibController from './../../Controllers/TdLibController';
import Loading from "./Loading"
import { SetCookie } from './SetCookie'
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
const LogOut = ({ seturl, url }) => {
  const [LoadingState, setLoadingState] = useState(false)
  const [open, setOpen] = useState(false)
  const history = useHistory();
   useEffect(() => {
     
      seturl(window.location.pathname)
  
    }, [])
  const HandleLogout = () => {
   
    setLoadingState(true)
    // RegisterMembersgram
    // Notice
    localStorage.setItem("Notice", null);

    localStorage.setItem("RegisterMembersgram", null);
    localStorage.setItem("autojoin", null);
    localStorage.setItem("MyIndex", null);
    SetCookie("RegisterMembersgram", "", null);
    TdLibController.logOut();
    seturl('/')
    history.push("/");
  }
  function onDismiss() {
    setOpen(false)
  }
  const Back = () => {
    // console.log('Back')
    seturl('/Profile')
    history.push("/Profile");
  }
  return (
    LoadingState ? <Loading /> :
      <div className='LogoutContainer'>
        <div className='LogoutHeader'>
          <div onClick={()=>Back()} className='LogoutLinkBack'>
            <ArrowBackSharpIcon

              className='BackButton' />
          </div>

          <div>
            Logout
          </div>

        </div>
        <div className='LogoutContent'>
          <div className='LogoutItem'>
            <div>
              <MailOutlineIcon className='EmailIconLogout' />
            </div>
            <a href="mailto:membersgramapp@gmail.com?body=Hello," className='LogoutItemInfo'>
              <div className='LogoutDes'>
                Send email to support
              </div>
              <div className='LogoutDes1'>
                Email us if you are dissatisfied
              </div>
            </a>

          </div>
          <a href="https://membersgram.com/google/google/" className='LogoutItem'>
            <div>
              <img src={Quiz} className='QuizIconLogout' />
            </div>
            <div className='LogoutItemInfo'>
              <div className='LogoutDes'>
                Frequently Asked Questions
              </div>
              <div className='LogoutDes1'>
                If you have a problem, here is the answer
              </div>
            </div>

          </a>


        </div>
        <div className='LogoutContainerAction' onClick={() => setOpen(true)} >
          <div className='LogoutImageAction'>
            <img src={LogoutRed} />
          </div>
          <div className='LogoutTextAction'>
            Log out
          </div>

        </div>
        {/* <button onClick={() => setOpen(true)}>Open</button> */}
        <BottomSheet open={open} onDismiss={onDismiss}>
          <div className='MyBottomSheet'>
            <div className='MyBottomSheetHeader'>
              <ReportProblemOutlinedIcon className='MyBottomSheetIcon' />
              Log out
            </div>
            <div className='MyBottomSheetContent'>
              Are you sure to log out of the user account?
            </div>
            <div className='MyBottomSheetFooter'>
              <button className='PrimaryButtonLogOut' onClick={() => setOpen(false)}>Cancel</button>
              <button className='SecondryButtonLogOut' onClick={HandleLogout}>Log out</button>
            </div>
          </div>
        </BottomSheet>

      </div>
  )
}

export default LogOut