import React from 'react'
import PersonIcon from '@material-ui/icons/Person';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'jalali-moment'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import Snackbar from '@material-ui/core/Snackbar';
// import ContentCopyIcon from '@material-ui/icons/ContentCopy';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
const OrderDetailsItem = ({ item }) => {
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (newState) => {
        setState({ open: true, ...newState });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const TokenClicked = (token) => {
        handleClick({
            vertical: 'bottom',
            horizontal: 'center',
        })
        setTimeout(() => {
            handleClose()
        }, 3000);
        navigator.clipboard.writeText(token);

    }
    // console.log(item)
    return (
        <div className="OrderDetailsContentItem">
            <div className="OrderDetailsContentItemRow">
                
                <div className="OrderDetailsContentItemRowKey">Status</div>
                <div className="OrderDetailsContentItemRowValue">
                    {
                        item.status == 'اتمام' ?
                            item.requestCount > item.joinCount ?
                                <div className='Stoped'>Stop <CancelIcon className='CancelIcon' /></div>
                                :
                                <div className='DoneOrders'>Done <CheckCircleIcon className="OrderDetailsItemCheckCircleIcon" /></div>
                            :
                            item.status == "در حال انجام" ?
                                <div className='InProcessOrder'>In Process <RefreshRoundedIcon className='RefreshIcon' /></div>
                                :
                                item.status == "گزارش شده" ?
                                    <div className='Reported'>Report <ErrorIcon className='ErrorIcon' /> </div>
                                    :
                                    // item.status == "توقف" ?
                                    //     <div className='Stoped'>Stoped <CancelIcon className='CancelIcon' /></div>
                                    //     :
                                        null
                    }
                </div>
            </div>
            <div className="OrderDetailsContentItemRow">
            {/* {item.username} */}
                <div className="OrderDetailsContentItemRowKey">Requested</div>
                <div className="OrderDetailsContentItemRowValue">{item.requestCount} <PersonIcon className='OrderDetailsItemPersonicon' /></div>
            </div>
            <div className="OrderDetailsContentItemRow">
                <div className="OrderDetailsContentItemRowKey">Received</div>
                <div className="OrderDetailsContentItemRowValue">{item.joinCount} <PersonIcon className='OrderDetailsItemPersonicon' /></div>
            </div>
            <div className="OrderDetailsContentItemRow">
                <div className="OrderDetailsContentItemRowKey">Left ( 7 days )</div>
                <div className="OrderDetailsContentItemRowValue">{item.leftCount} <PersonIcon className='OrderDetailsItemPersonicon' /></div>
            </div>
            <div className="OrderDetailsContentItemRow">
                <div className="OrderDetailsContentItemRowKey">Start time</div>
                <div className="OrderDetailsContentItemRowValue">{moment.from(item.date, 'fa', 'YYYY/MM/DD').format('YYYY/MM/DD')}</div>
            </div>
            <div className="OrderDetailsContentItemRow">
                <div className="OrderDetailsContentItemRowKey">End time</div>
                <div className="OrderDetailsContentItemRowValue">{moment.from(item.finishDate, 'fa', 'YYYY/MM/DD').format('YYYY/MM/DD')}</div>
            </div>
            <div className="OrderDetailsContentItemRow">
                <div className="OrderDetailsContentItemRowKey">Order tracking</div>
                <div className="OrderDetailsContentItemRowValue CopyToken"
                    onClick={() => { TokenClicked(item._id); }}>
                    {item._id.length > 16 ? item._id.substr(0, 16) : item._id}
                    <FileCopyOutlinedIcon className='CopyIcon' /></div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message="Order tracking was copied"
                key={vertical + horizontal}
                bodyStyle={{ maxWidth: '100%', height: '200px' }}
            />
        </div>
    )
}

export default OrderDetailsItem