import React from 'react'
import Banned from './../../Assets/Banned.svg'
import './Banned.css'
import "./fonts/font.css"
import TdLibController from './../../Controllers/TdLibController';
import { SetCookie } from './SetCookie'

import { useHistory, Switch,Link, BrowserRouter as Router, Route } from 'react-router-dom';

const BannedUser = ({SetTabsStatusTransfer,url}) => {
    // console.log(url)
    const LogOut=()=>{
        // setLoadingState(true)
        // RegisterMembersgram
        // Notice
        localStorage.setItem("Notice", null);
    
        localStorage.setItem("RegisterMembersgram", null);
        localStorage.setItem("autojoin", null);
        localStorage.setItem("MyIndex", null);
        SetCookie("RegisterMembersgram", "", null);
        TdLibController.logOut();
        // seturl('/')
        // history.push("/");
    }
  const urlParams = new URLSearchParams(window.location.search);

    const GoTransfer=()=>{
        SetTabsStatusTransfer()
        // urlParams.set('Status', 'transfer');
        // let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
        // window.history.pushState({ path: newurl }, '', newurl);
    }
    return (
        
            <Route>
                <div className='BannedUser'>
                    <div className='BannedImg'>
                        <img src={Banned} />
                    </div>
                    <div className='BannedBody'>
                        <div className='BannedBodyTitle'>
                            Telegram session terminated
                        </div>
                        <div className='BannedBodyDes'>
                            To avoid losing your coins, transfer them to another account and log in again on the app
                        </div>
                    </div>
                    <div className='BannedFooter'>
                        {/* <div> */}
                        <button className='BannedLogOutButton' onClick={()=>LogOut()}>
                            Log out
                        </button>

                        {/* </div>
                <div> */}
                {url?
                <Link to="/Coin?Status=transfer" className='BannedCoinTransfer' >
                     Coin transfer
                </Link>
                :
                <button  className='BannedCoinTransfer'  onClick={()=>GoTransfer()}>
                            Coin transfer
                        </button>
                }
                        

                        {/* </div> */}
                    </div>
                    {/* <BottomNavigation /> */}
                </div>
                
            </Route>

        

    )
}

export default BannedUser