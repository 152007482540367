import React from 'react';
import { useState, useEffect } from 'react'
import './Packages.css';
import PackageItem from './PackageItem'
import PackageItemCoin from './PackageItemCoin'


import PackageItemBest from './PackageItemBest'
import { GetMembers } from './Apis/GetMembers';
// import { GetCoin } from './Apis/GetCoin';
import Loading from './Loading'
import NoInternet from './NoInternet'
import { BottomSheet } from 'react-spring-bottom-sheet'
import CheckGreen from './../../Assets/checkgreen.svg'
import Update from './../../Assets/Update.svg'

import CongratulationsPic from './../../Assets/Congratulations.png'

import PersonIcon from '@material-ui/icons/Person';
import { getChatUsername } from '../../Utils/Chat';
import { GetCookie } from './GetCookie'


function Packages() {
    localStorage.removeItem("cache")
    localStorage.removeItem("cache")

    const [open, setOpen] = useState(false)
    const [Congratulations, setCongratulations] = useState(false)
    const [Package, setPackage] = useState([])
    const [CoinPackage, setCoinPackage] = useState([])
    const [Stateloading, setStateloading] = useState(false);
    const [Return, setReturn] = useState('')
    const [Id, setId] = useState(false)
    const [MemberCount, setMemberCount] = useState(false)
    const [UserCoin, setUserCoin] = useState(0)
    const [BuyStatus, setBuyStatus] = useState(true)
    const [BuyStatusBottomSheet, setBuyStatusBottomSheet] = useState(false)
    let apitoken = GetCookie('RegisterMembersgram')
    const get = async (apitoken) => {
        let resp = await GetMembers(apitoken)
        // let GetCoinresp = await GetMembers(apitoken)
        setUserCoin(resp.coin)
        // console.log(resp.data,resp.coin)
        setCoinPackage(resp.data.coin_getmember)
        setPackage(resp.data.purchase_getmember)
        setStateloading(true)

    }



    useEffect(() => {
        let NewUser = localStorage.getItem('NewUser')
        if (NewUser == 'true') {
            setCongratulations(true)
            localStorage.setItem('NewUser', false)
        }

        setTimeout(() => {

            if (apitoken) {
                get(apitoken)
            }
        }, 3000);
        const interval = setInterval(() => {
            if (!apitoken) {
                // console.log(apitoken)
                apitoken = GetCookie('RegisterMembersgram')
            } else {
                get(apitoken)
                clearInterval(interval)
            }
        }, 1000);

    }, [apitoken])
    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        let ReturnOrder = urlParams.get('ReturnOrder');
        let id = urlParams.get('id');
        let member_count = urlParams.get('member_count');
        if (id) {
            setId(id)
        }
        if (member_count) {
            setMemberCount(member_count)
        }
        if (ReturnOrder) {
            setReturn(ReturnOrder)
        }

        if (ReturnOrder) {
            setOpen(true)
        }
    }, [])

    // console.log(Package)
    function onDismiss() {
        setTimeout(() => setOpen(false), 100)
    }
    function onDismiss1() {
        setTimeout(() => setCongratulations(false), 100)
    }
    // console.log(Id)
    return (

        <div className='Packages'>

            <div className='Body'>
                <div className='Banner'>
                    <div className='BannerImage'>
                         <img src={Update} width={24} height={24} /> 
                         <div className='BannerImageText'>
                         New update is available
                         </div>
                    </div>
                    <div className='BannerBody'>
                    This version will be <b>deprecated</b> soon, please use the new version and add your accounts to it
                    </div>
                    <div className='GoToNewVersion'>
                        <a className='GoToNewVersionButton' href="https://webapp.membersgram.com/">
                        Go to new version
                        </a>
                    </div>
                    
                </div>
                <div className='BodyHeader'>
                    {Package[0] ?
                        'Get Member'
                        : null}
                </div>



                <div className='BodyContent'>

                    {
                        Stateloading ?
                            Package[0].is_active ?
                                Package.map((item, i) =>
                                    <>
                                        <PackageItem key={i} props={item} />

                                    </>

                                )
                                :
                                // console.log(Package)
                                // <NoInternet />
                                null
                            :
                            <Loading />
                    }


                    {/* <PackageItemBest />  */}

                </div>
                <div className='BodyHeader1'>
                    {Package[0] ?
                        'Get Member by coins'
                        : null}
                </div>
                <div className='BodyContent'>

                    {
                        Stateloading ?
                            CoinPackage[0].is_active ?
                                CoinPackage.map((item, i) =>
                                    <>
                                        <PackageItemCoin setBuyStatusBottomSheet={setBuyStatusBottomSheet} BuyStatusBottomSheet={BuyStatusBottomSheet} setBuyStatus={setBuyStatus} BuyStatus={BuyStatus} key={i} props={item} UserCoin={UserCoin} />

                                    </>

                                )
                                :

                                null
                            :
                            <Loading />
                    }


                    {/* <PackageItemBest />  */}

                </div>

            </div>

            <BottomSheet open={open} onDismiss={() => onDismiss()}>
                <div className={Return == 'true' ? 'MyBottomSheetPaySuccess' : 'MyBottomSheetPayUnSuccess'}>
                    <div className='MyBottomSheetHeaderPaySuccess'>
                        {Return == 'true' ?
                            <>
                                <img src={CheckGreen} />
                                Successful order
                            </>
                            :
                            <>
                                Unsuccessful payment!
                            </>}




                    </div>
                    <div className='MyBottomSheetContentPaysuccess'>

                        {Return == 'true' ? <>
                            <div>{MemberCount}</div>
                            <PersonIcon className='PersonIcon' />
                            <div>
                                were ordered for
                            </div>

                            <div className='ChannelIdPaySuccess'>
                                {/* {getChatUsername(Id)} */}
                                {Id}
                            </div>
                        </>
                            : null

                        }

                    </div>
                    <div className='MyBottomSheetFooter'>
                        <button className='SecondryButtonPay'
                            onClick={(e) => setOpen(false)} >Got it</button>

                    </div>
                </div>
            </BottomSheet>
            <BottomSheet open={Congratulations} onDismiss={() => onDismiss1()} >
                <div className='Congratulations'>
                    <div className='CongratulationsTitle'>
                        Congratulations
                    </div>
                    <div className='CongratulationsPic'>
                        <img src={CongratulationsPic} />
                    </div>
                    <div className='CongratulationsDes'>
                        15 gift coins is your first entry into the program
                    </div>
                    <div className='CongratulationsButton'>
                        <button className='SecondryButtonPay'
                            onClick={(e) => setCongratulations(false)} >Got it</button>
                    </div>
                </div>

            </BottomSheet>
        </div>

    );
}

export default Packages;