import React from 'react'
import Icon from '../../Assets/person_black.svg'
import CoinNew from '../../Assets/Coin.svg'

import { Link, Switch, BrowserRouter as Router, Route } from 'react-router-dom';

import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useHistory } from "react-router-dom";
const PackageItemCoin = ({ setBuyStatus, BuyStatus, props, UserCoin,BuyStatusBottomSheet,setBuyStatusBottomSheet }) => {
    const history = useHistory();
   
    // console.log(props, props.UserCoin)
    // useEffect(() => {
    //     if (UserCoin < props.coin) {
    //         // اگر سکه کاربر کمتر از بسته بود
    //         setBuyStatus(true)
           
    //     } else {
    //         setBuyStatus(false)

    //     }
    //     console.log(BuyStatus)

    // }, [props])
    const CheckBuy = (coin) => {
        // console.log(UserCoin, props.coin, BuyStatus)

        // Status

        if (UserCoin < coin) {
            // اگر سکه کاربر کمتر از بسته بود
            setBuyStatus(true)
             
        // if(BuyStatus==true){
           setBuyStatusBottomSheet(true)   
        // }
        } else {
            setBuyStatus(false)
            history.push(`/ChannelsList?Package=${props.id}&&by_coin=${true}`);

        }
      
      

    }
    return (
        // {`/Notice?id=${chat.id}`}
        <>
            <Link
                onClick={()=>CheckBuy(props.coin)}
                to={localStorage.getItem("coinStatus") == true ? `/ChannelsList?Package=${props.id}&&by_coin=${true}` : null}
                key={props.id}
                className='BodyContentItem'
            >
                <div className='BodyContentSep'>
                    <div className='IconItem'>
                        <img src={Icon} />
                    </div>
                    <div className='countItemCoin'>
                        <div className='countItem1'>
                            {props.member_count_real}
                            {/* <img src={Fire} /> */}
                        </div>
                        {/* <div className='countItem2'>
                        {props.props.member_count}

                    </div> */}
                    </div>
                </div>

                <div className='BodyContentSep'>
                    <div className='OfferContainer'>
                        {/* <div className='Off'>
                        25%
                    </div> */}
                        {/* <div className='BestOffer'>
                        Best offer
                    </div> */}
                    </div>

                    <div className='OrderAndOff'>
                        <div>
                            <button className='SecondaryButtonCoin'><img className="CoinIconNew" src={CoinNew} />{props.coin} </button>

                        </div>
                        {/* <div className='PrevPrice'>
                        100,900 $
                    </div> */}
                    </div>
                </div>

            </Link>
            <BottomSheet open={BuyStatusBottomSheet}>
                <div className='MyBottomSheetPayUnSuccess'>
                    <div className='MyBottomSheetHeaderPaySuccess'> Insufficient Balance  </div>
                    <div className='MyBottomSheetContentPaysuccess'>

                        <div>
                            You don’t have enough coins to buy this bundle.
                        </div>


                    </div>
                    <div className='MyBottomSheetFooter'>
                        <button className='SecondryButtonPay'
                            onClick={(e) => setBuyStatusBottomSheet(false)} >Got it</button>

                    </div>
                </div>
            </BottomSheet>
        </>

    )
}

export default PackageItemCoin