import React from 'react'
import { async } from 'tdweb';
import TdLibController from './../../../Controllers/TdLibController';


export async function SearchChannel(username) {

    const chat = await TdLibController.send({
        '@type': 'searchPublicChat',
        username:username
      }).catch((error) => {
        // this.setState({ error: true });
        // console.log(error)
        // console.log('error')
        return error
      });
      return(chat)
}

export default SearchChannel