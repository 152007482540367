import React from 'react'
import { async } from 'tdweb';
import TdLibController from './../../../Controllers/TdLibController';


export async function GetChatDetails(chats) {

    const chat = await TdLibController.send({
        '@type': 'getChat',
        chat_id: chats
    });
    return chat;
}

export default GetChatDetails