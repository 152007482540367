import React from 'react'
import Tea from './../../Assets/Tea.svg'

const CoinEndList = () => {
    return (
        <div className='ChannelDetailsJoin'  >
            {
                // Photo != false ?
                //     JoinThisStatus ?
                //         <div className='LoadingForImageJoin'>
                //             <div className='LoadingForImageJoinLoad'>

                //             </div>
                //             <img className='ChannelImageCoinActive' src={Photo} />
                //         </div>
                //         :
                <div className='ImageJoinContainer'>

                    <img src={Tea} />
                    {/* <img className='ChannelImageCoin' src={Photo} /> */}
                </div>

                // :
                // <div className='ImageJoinContainer'>
                //     <Skeleton variant="circle" width={127} height={127} className='Skeletoncircular' >
                //     </Skeleton>
                // </div>

            }

            <div className='JoinShowChannelLink'>

                End of list
            </div>
            <div className='JoinShowChannelLink'>
            To avoid wasting time, use automatic subscription

            </div>
        </div>
    )
}

export default CoinEndList