import React from "react";
import { useState, useEffect } from "react";
import { BottomSheet } from 'react-spring-bottom-sheet'
// import 'react-spring-bottom-sheet/dist/style.css'
import GetChatDetails from './Action/GetChatDetails'
import {
    getChatUsername, getChatSubtitle, isChannelChat, getChatLetters, getChatTitle
} from '../../Utils/Chat';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import "./Pay.css"
import { useHistory } from "react-router-dom";
import { getSrc, loadChatContent } from './../../Utils/File';
import { Skeleton } from '@material-ui/lab';
import PayPalIcon from './../../Assets/paypal.svg'
import SecurePay from './../../Assets/SecurePay.svg'
import { async } from "tdweb";
import { GetMembers } from './Apis/GetMembers';
import { GetPayApi } from './Apis/GetPayApi'
import { getChatPhoneNumber } from "../../Utils/Chat";
import UserStore from "../../Stores/UserStore";
import cryptlib from 'cryptlib'
import "./fonts/font.css"
const Pay = () => {
    const [open, setOpen] = useState(true)
    // const [Data, setData] = useState(false)
    const [Price, setPrice] = useState([])
    const [Sku, setSku] = useState([])
    const [Photo, setPhoto] = useState([])
    const [Data, setData] = useState('')
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    let Package = urlParams.get('Package');
    let channelid = urlParams.get('id');
    if (!Package) {
        history.push("/");
    }
    if (!channelid) {
        history.push("/");
    }

    useEffect(() => {
        if (Package) {
            GetMemerpack();
        }
    }, [])

    // console.log(Data)

    const GetMemerpack = async () => {
        let resp = await GetMembers()
        resp.map(pack => {
            if (pack._id == Package) {
                setSku(pack.sku)
                setPrice(pack.price)
            }
        })
    }

    const GetData = async () => {
        let element = await GetChatDetails(channelid);
        setData(element)
    }
    useEffect(() => {
        setTimeout(() => {
            GetData()
        }, 5000);
        setTimeout(() => {
            GetPhoto(Data)
        }, 8000);

    }, [Data])




    const GetPhoto = (Data) => {
        if (Data.photo) {
            setPhoto(getSrc(Data.photo.small))
        }

    }

    let dataen;
    const GetPay = async () => {
        let phone = getChatPhoneNumber(UserStore.getMyId());
        phone = phone.replace('+', '')
        phone = phone.replace(/\s/g, '')
        let data = {
            "phonenumber": phone,
            "sku": Sku,
            "price": Price,
            "username": getChatUsername(Data.id),
            "tgChannelId": channelid,
            "title": Data.title
        }
        // console.log(data)
        // let respons = await GetPayApi(data)
        const iv = 'D%<{&A^bjha+VA#M'
        const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);

        dataen = cryptlib.encrypt(JSON.stringify(data), key, iv);
        // setDataEncript(dataen)
        // console.log(respons)
    }






    if (Data.id) {


        // console.log(getChatUsername(Data.id), Photo, Data.title, Sku, Price)
        if (getChatUsername(Data.id) && Photo && Data.title && Sku && Price) {
            GetPay()
            // console.log("okkkkkkkkkkkkkkkkkkkkk")
        }


    }
// console.log(Photo)
    return (
        <div >

            <BottomSheet open={open}>
                {/* <div className='MyBottomSheetPay'> */}
                <div className='MyBottomSheetPay'>
                    <div className='MyBottomSheetHeaderPay'>
                        <div className="RowBottomSheet">
                            <div className="ImageChannelBottomSheet">
                                {Photo.length != 0 ?
                                    <img src={Photo} className="ImageChannelPay" />
                                    :
                                    <Skeleton variant="circle" width={48} height={48} className='Skeletoncircular' >
                                    </Skeleton>
                                }
                                <div className="PackageAndChannelNameBottomSheet">
                                    <div className="PackageBottomSheet">
                                        {Sku?Sku:
                                        <Skeleton variant="text" width={120} height={16} />
                                        }
                                        
                                        
                                    </div>
                                    <div className="ChannelBottomSheet">
                                      
                                        {getChatUsername(Data.id) ? getChatUsername(Data.id):
                                          <Skeleton variant="text" width={100} height={16} className="SkeletSku"/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="PriceBottomSheet">
                                {Price} $
                            </div>
                        </div>
                    </div>
                    <div className='MyBottomSheetContentPay'>
                        <div>
                            <img src={PayPalIcon} />
                        </div>
                        <div className="SecureTextAndIcon">
                            <img src={SecurePay} className="SecureIcon" />
                            Secure payment with PayPal
                        </div>

                    </div>
                    <div className='MyBottomSheetFooter'>
                        <a href={`https://webapi.membersgram.com/api/v2_2/paypal/webpurchasememberpaypal?data=${dataen}`} className='SecondryButtonPay' type="submit">Pay</a>
                        {/* <button className='SecondryButton' >Log out</button> */}
                    </div>
                </div>
            </BottomSheet>
        </div>
    );
};

export default Pay;
