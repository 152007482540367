import React from 'react'
import SearchChannel from './Action/SearchChannel'
import GetChannels from './Action/GetChannels';
import { useEffect, useState } from 'react';
import GetChatDetails from './Action/GetChatDetails'
import './Channels.css';
import Loading from './Loading'
import {
  getChatUsername, getChatSubtitle, isChannelChat, getChatLetters, getChatTitle
} from '../../Utils/Chat';
import ChannelIcon from '../../Assets/channelicon.svg'
import GroupIcon from '../../Assets/groupicon.svg'
import CoinNew from '../../Assets/Coin.svg'
import { Skeleton } from '@material-ui/lab';
import { getSrc, loadChatContent } from './../../Utils/File';
import SearchInput from './../ColumnLeft/Search/SearchInput'
import { useHistory } from "react-router-dom";
import TdLibController from './../../Controllers/TdLibController';
import FileStore from '../../Stores/FileStore';
import { loadUsersContent } from '../../Utils/File';
import { Link } from 'react-router-dom'
import SearchInChannels from './SearchInChannels'
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import SearchIcon from '../../Assets/Icons/Search';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { CheckChannelBefore } from './Apis/CheckChannelBefore'
// import { draftEquals, getChatDraft, getChatDraftReplyToMessageId, getChatFullInfo, isMeChat, isPrivateChat, isSupergroup } from '../../Utils/Chat';
import { GetCookie } from './GetCookie'
import { GetMembers } from './Apis/GetMembers';
import { StoreChannelByCoin } from './Apis/StoreChannelByCoin';
import PayPalIcon from './../../Assets/paypal.svg'
import SecurePay from './../../Assets/SecurePay.svg'
import UserStore from "../../Stores/UserStore";
import cryptlib from 'cryptlib'

import { loadProfileMediaViewerContent } from '../../Utils/File';
import { getPhotoFromChat } from '../../Utils/Chat';
import PersonIcon from '@material-ui/icons/Person';
import CheckGreen from './../../Assets/checkgreen.svg'

import { getChatPhoneNumber, isPrivateChat, getChatFullInfo } from "../../Utils/Chat";
// import InfiniteScroll from "react-infinite-scroll-component";
import { async } from 'tdweb';
// import { canSendMediaMessages, canSendMessages, getChatFullInfo, getChatMedia, getSupergroupId, isChannelChat, isGroupChat, isMeChat } from '../../Utils/Chat';
// import SupergroupStore from '../../Stores/SupergroupStore';
// import 'react-spring-bottom-sheet/dist/style.css';
const Channels = ({ setChatList, ChatList, seturl, Searching, setSearching, setState, State, Price, setPrice, setSku, Sku }) => {
  const history = useHistory();
  const [Loadingstate, setLoadingstate] = useState(true)
  const [OpenChanellProgress, setOpenChanellProgress] = useState(false)
  const [open, setOpen] = useState(false)

  const [Photo, setPhoto] = useState([])
  const [Data, setData] = useState('')
  const [Member, setMember] = useState('')
  const [PriceCoin, setPriceCoin] = useState('')
  const [PackId, setPackId] = useState('')
  const [openSuccess, setopenSuccess] = useState(false)
  const [Return, setReturn] = useState(false)

  const urlParams = new URLSearchParams(window.location.search);
  let Package = urlParams.get('Package');
  let channelid = urlParams.get('id');
  let by_coin = urlParams.get('by_coin');

  let Notice = localStorage.getItem("Notice")
  let apitoken = GetCookie('RegisterMembersgram')
  let dataen1;
  const [DataEn, setDataEn] = useState('')
  const GetPay = async (chat) => {
    let phone = getChatPhoneNumber(UserStore.getMyId());
    phone = phone.replace('+', '')
    phone = phone.replace(/\s/g, '')
    // console.log(chat)
    let TgId;

    if (chat) {
      TgId = chat.id.toString()
      TgId = TgId.replace('-100', '');

      TgId = Number(TgId)
    }

    let data = {
      "phonenumber": phone,
      "sku": Sku,
      "price": Price,
      "username": chat ? getChatUsername(chat.id) : null,
      "tgChannelId": TgId,
      "title": chat ? chat.title : null
    }
    // console.log(data)
    const iv = 'D%<{&A^bjha+VA#M'
    const key = cryptlib.getHashSha256('D%<{&A^bjha+VA#M', 32);
    setDataEn(cryptlib.encrypt(JSON.stringify(data), key, iv))
    dataen1 = cryptlib.encrypt(JSON.stringify(data), key, iv);
    // console.log(dataen1, data)

  }
  const GetImage = async (id) => {
    const photo = getPhotoFromChat(id);
    loadProfileMediaViewerContent(id, [photo]);
    let blob = getSrc(photo.small)

    loadProfileMediaViewerContent(id, [photo]);

    blob = getSrc(photo.small)
    if (!blob) {
      const interval = setInterval(() => {
        if (!blob) {

          loadProfileMediaViewerContent(id, [photo]);

          blob = getSrc(photo.small)
          // setPhoto(blob)
        } else {
          clearInterval(interval)
          setPhoto(blob)

        }
      }, 10);


    } else { setPhoto(blob) }
  }
  // const GetData = async (Data) => {

  // let element = await GetChatDetails(channelid);
  // setData(element)
  //     console.log(Data.id)
  //  console.log(await)
  //   }
  useEffect(() => {
    setTimeout(() => {
      // GetData(Data)
    }, 1000);


  }, [Data])


  const GetMemerpack = async (apitoken) => {

    let resp = await GetMembers(apitoken)
    if (by_coin == "true") {
      resp.data.coin_getmember.map(pack => {
        if (pack._id == Package) {
          setMember(pack.member_count)
          setPriceCoin(pack.coin)
          setPackId(pack.Id)
        }
      })
    } else if (by_coin == "false") {
      if (resp.data.purchase_getmember) {
        // console.log(resp)
        resp.data.purchase_getmember.map(pack => {
          if (pack._id == Package) {
            // console.log(pack.sku, pack)
            setSku(pack.sku)
            setPrice(pack.price)
            setMember(pack.member_count)

          }
        })

      }
    }


  }
  useEffect(() => {
    setTimeout(() => {
      if (apitoken) {
        GetMemerpack(apitoken)
      }
    }, 3000);
    const interval = setInterval(() => {
      if (!apitoken) {
        // console.log(apitoken)
        apitoken = GetCookie('RegisterMembersgram')
      } else {
        GetMemerpack(apitoken)
        clearInterval(interval)
      }
    }, 1000);
  }, [])
  const Run = async (setChatList) => {
    setLoadingstate(false)

    let list = await GetChannels();
    setChatList([])
    for (let index = 0; index < list.length; index++) {

      if (list[index] != undefined) {
        let element = await GetChatDetails(list[index])
        const username = getChatUsername(list[index]);
        // let fuul = await getChatFullInfo(list[index])

        let Private1 = await PrivateChat(username)
        let Private = {
          private: Private1
        }
        // let Admin = {
        //   admin: fuul.administrator_count
        // }
        let Admin = {
          admin: 0
        }
        let merged = { ...element, ...Private, ...Admin };
        setChatList((prevState) => [...prevState, merged]);
        // }
      }

    }
    setLoadingstate(false)

  }
  const TimerLoad = () => {

    setTimeout(() => {
      Run(setChatList)
    }, 20);
  }

  useEffect(() => {
    TimerLoad()
    seturl(window.location.pathname)

  }, [])



  if (!Package) {
    history.push("/");
  }

  const getdet = async () => {
    setSearching([])


    if (State.searchItems) {
      if (State.searchItems.chat_ids) {
        setSearching([])
        let NewSearchArray = State.searchItems.chat_ids
        // console.log(NewSearchArray)

       
        // console.log(NewSearchArray)
        for (let index = 0; index < State.searchItems.chat_ids.length; index++) {
          if (State.searchItems.chat_ids[index] < 0) {

            let element = await GetChatDetails(State.searchItems.chat_ids[index])
           
            setSearching((prevState) => [...prevState, element]);
          }
        }
        setLoadingstate(false)
      }

    }
  }

  // console.log(Searching)
  const [InputValue, setInputValue] = useState('')

  const handleSearchGlobal = async (text) => {
    setInputValue(text)
    const query = text.trim();
    if (!query) {
      setSearching([])
      setState({
        searchItems: null
      });
      return;
    }


    const promises = [];
    // promises.push(
    //   await TdLibController.send({
    //     '@type': 'searchChatsOnServer',
    //     query,
    //     limit: 100
    //   })
    // );
    promises.push(
      await TdLibController.send({
        '@type': 'searchPublicChats',
        query
      })

    );
    // console.log(promises)



    const [searchItems, publicChats] = await Promise.all(promises);
    console.log(publicChats, searchItems)
    const store = FileStore.getStore();
    loadUsersContent(store, searchItems.user_ids);

    setState({ searchItems });
  }
  const handleSearch = async (text) => {
    setInputValue(text)
    const query = text.trim();
    if (!query) {
      setSearching([])
      setState({
        searchItems: null
      });
      return;
    }


    const promises = [];
    promises.push(
      await TdLibController.send({
        '@type': 'searchChatsOnServer',
        query,
        limit: 100
      })
    );
    // promises.push(
    //   await TdLibController.send({
    //     '@type': 'searchPublicChats',
    //     query
    //   })

    // );
    // console.log(promises)



    const [searchItems, publicChats] = await Promise.all(promises);
    // console.log(publicChats, searchItems)
    const store = FileStore.getStore();
    loadUsersContent(store, searchItems.user_ids);

    setState({ searchItems });
  }
  useEffect(() => {
    getdet()
  }, [State])
  const [SearchClass, setSearchClass] = useState(false)

  const FocusSearch = (e) => {
    setSearchClass(true)
  }
  const BlurSearch = (e) => {
    setSearchClass(false)
  }
  const BlurSearchAndClear = (e) => {
    setInputValue('')
    BlurSearch();

    setState({});
    setSearching([])

  }
  const Back = () => {
    // console.log('Back')
    seturl('/')
    history.push("/");
  }


  const PrivateChat = async (username) => {
    let chat = await SearchChannel(username)
    // console.log(chat)
    if (chat) {
      let privatee = isPrivateChat(chat.id)
      return privatee
    } else {
      return true
    }

    // return privatee
  }
  const [NoUsername, setNoUsername] = useState(false)
  const CheckChannelBeforeFunction = async (id) => {
    // console.log(id)
    setLoadingstate(true)
    const username = getChatUsername(id);
    let chat = await SearchChannel(username)
    // let img;
    // console.log(chat)

    if (chat) {
      setData(chat)
    }
    if (chat) (
      GetImage(chat.id)
    )

    // console.log(img)
    GetPay(chat)
    if (username) {

      let result = await CheckChannelBefore(id, getChatUsername(id))
      // console.log(result.code)
      if (result.code == 200) {
        // console.log(by_coin)
        // setLoadingstate(false)
        // console.log(Notice)
        if (by_coin == 'false' || by_coin == null || by_coin == 'null') {
          Notice == 'true' ?
            setOpen(true)
            :
            history.push(`/Notice?Package=${Package}&username=${username}&by_coin=false`)
        } else if (by_coin == 'true') {
          // bottomsheet buy member by coin
          Notice == 'true' ?
          setOpen(true)
          :
          history.push(`/Notice?Package=${Package}&username=${username}&by_coin=true`)
         
          // setOpenChanellProgress(true)
        }

      } else {
        setNoUsername(false)
        setOpenChanellProgress(true)
      }
    } else {
      // setLoadingstate(false)
      setNoUsername(true)
      setOpenChanellProgress(true)
    }
    setLoadingstate(false)

  }
  function onDismiss() {
    setTimeout(() => setOpen(false), 100)

  }
  function onDismiss1() {
    setTimeout(() => setOpenChanellProgress(false), 100)


  }
  const PayByCoin = async () => {
    let TgId = Data.id
    let Username = getChatUsername(Data.id)
    let Title = getChatTitle(Data.id)
    // let PackId = Package
    setOpen(false)
    let resp = await StoreChannelByCoin(TgId, Username, Title, PackId)
    // console.log(resp)
    if (resp.status == "success") {
      setopenSuccess(true)
      setReturn(true)
    } else if (resp.status == "error") {
      if (resp.code == 302) {
        setopenSuccess(true)
        setReturn('coin')
      } else if (resp.code == 300) {
        setopenSuccess(true)
        setReturn('error')

      } else if (resp.code == 301) {
        setopenSuccess(true)
        setReturn('error')

      } else if (resp.code == 303) {
        setopenSuccess(true)
        setReturn('error')

      }


    }


  }
  // console.log(ChatList)
  let NewSearchArray
  if(Searching.length>0){
    NewSearchArray = [...new Map(Searching.map(o => [o.id, o])).values()]

  }
  let NewChatListArray

   if(ChatList.length>0){
    NewChatListArray = [...new Map(ChatList.map(o => [o.id, o])).values()]

  }
  
  return (

    <div className='Channels' key={Package} >
      <BottomSheet open={open}
        data-body-scroll-lock-ignore="true"
        snapPoints={({ minHeight }) => minHeight}
        blocking={true} onDismiss={onDismiss} id="PayBottomSheet">
        {
          by_coin == 'false' ?
            <div className='MyBottomSheetPay'>
              <div className='MyBottomSheetHeaderPay'>
                <div className="RowBottomSheet">
                  <div className="ImageChannelBottomSheet">
                    {Photo.length != 0 ?
                      <img src={Photo} className="ImageChannelPay" />
                      :
                      <Skeleton variant="circle" width={48} height={48} className='Skeletoncircular' >
                      </Skeleton>
                    }
                    <div className="PackageAndChannelNameBottomSheet">
                      <div className="PackageBottomSheet">
                        {Member ? Member + " Member" :
                          <Skeleton variant="text" width={120} height={16} />
                        }


                      </div>
                      <div className="ChannelBottomSheet">

                        {
                          Data ? getChatUsername(Data.id) ? getChatUsername(Data.id) :
                            <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                            :

                            <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                        }
                      </div>
                    </div>
                  </div>

                  <div className="PriceBottomSheet">
                    {Price} $
                  </div>
                </div>
              </div>
              <div className='MyBottomSheetContentPay'>
                <div>
                  <img src={PayPalIcon} />
                </div>
                <div className="SecureTextAndIcon">
                  <img src={SecurePay} className="SecureIcon" />
                  Secure payment with PayPal
                </div>

              </div>
              <div className='MyBottomSheetFooter'>
                <a href={`https://webapi.membersgram.com/api/v2_2/paypal/webpurchasememberpaypal?data=${DataEn}`} className='SecondryButtonPay'>Pay</a>
                {/* <button className='SecondryButton' >Log out</button> */}
              </div>
            </div>
            :
            by_coin == 'true' ?
              <div className='MyBottomSheetPayCoin'>
                <div className='MyBottomSheetPayCoinImageAndTitle'>
                  <div className='ImageChannelBottomSheet'>
                    {Photo.length != 0 ?
                      <img src={Photo} className="ImageChannelPay" />
                      :
                      <Skeleton variant="circle" width={48} height={48} className='Skeletoncircular' >
                      </Skeleton>
                    }
                  </div>
                  <div className='PayCoinTitleAndCount'>
                    <div className='PayCoinTitle'>
                      {
                        Data ? getChatTitle(Data.id) ? getChatTitle(Data.id) :
                          <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                          :

                          <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                      }
                    </div>
                    <div className='PayCoinMember'>
                      {getChatSubtitle(Data.id)}
                    </div>
                  </div>
                </div>
                <div className='MyBottomSheetPayCoinDetails'>
                  <div className='MyBottomSheetPayCoinDetailsItem'>
                    <div className='PayCoinTitleItem'>Price</div>
                    <div className='PayCoinValueItem'>{PriceCoin} <img className='CoinIconPayCoin' src={CoinNew} /></div>
                  </div>
                  <div className='MyBottomSheetPayCoinDetailsItem'>
                    <div className='PayCoinTitleItem'>Count</div>
                    <div className='PayCoinValueItem'>{Member} <PersonIcon className='PersonPayCoin' /></div>
                  </div>
                  <div className='MyBottomSheetPayCoinDetailsItem'>
                    <div className='PayCoinTitleItem'>Username</div>
                    <div className='PayCoinValueItem'> {
                      Data ? getChatUsername(Data.id) ? getChatUsername(Data.id) :
                        <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                        :

                        <Skeleton variant="text" width={100} height={16} className="SkeletSku" />
                    }</div>
                  </div>
                </div>
                <div className='PayCoinSubmitContainer'>
                  <button className='PayCoinSubmit' onClick={(e) => { PayByCoin() }}>
                    Continue
                  </button>
                </div>
              </div>
              : null
        }
      </BottomSheet>



      <BottomSheet blocking={true} data-body-scroll-lock-ignore="true" snapPoints={({ minHeight }) => minHeight} open={OpenChanellProgress} onDismiss={onDismiss1}>
        <div className='MyBottomSheetTooMany'>
          <div className='MyBottomSheetHeaderTooMany'>
            {NoUsername ? 'Private channel' : 'Too many orders in progress'}
          </div>
          <div className='MyBottomSheetContentTooMany'>
            {NoUsername ? 'The channel is private. Please change the channel to public mode' : '  This channel has 1 order in progress. Please re-order when done'}

          </div>
          <div className='MyBottomSheetFooterTooMany'>


            {NoUsername ?
              <>
                <button className='PrimaryButton' onClick={() => setOpenChanellProgress(false)}>Got it</button>
                {/* <button className='SecondryButton' onClick={() => setOpenChanellProgress(false)}>Learn more</button> */}
                <a target='_blank' href="https://membersgram.com/google/google/" className="SecondryButtonLink">

                  Learn more
                </a>
              </>

              :
              <button className='SecondryButton' onClick={() => setOpenChanellProgress(false)}>Got it</button>}


          </div>
        </div>
      </BottomSheet>




      <BottomSheet open={openSuccess}>
        <div className={Return == true ? 'MyBottomSheetPaySuccess' : 'MyBottomSheetPayUnSuccess'}>
          <div className='MyBottomSheetHeaderPaySuccess'>
            {Return == true ?
              <>
                <img src={CheckGreen} />
                Successful order
              </>
              :
              Return == 'coin' ?
                <>
                  Insufficient Balance
                </>

                :
                Return == 'error' ?
                  'Unsuccessful payment!'
                  :
                  null
            }




          </div>
          <div className='MyBottomSheetContentPaysuccess'>

            {Return == true ? <>
              <div>{Member}</div>
              <PersonIcon className='PersonIcon' />
              <div>
                were ordered for
              </div>

              <div className='ChannelIdPaySuccess'>
                {getChatUsername(Data.id)}
              </div>
            </>
              :
              Return == "coin" ?
                <div>
                  You don’t have enough coins to buy this bundle.
                </div>
                : null
            }

          </div>
          <div className='MyBottomSheetFooter'>
            <button className='SecondryButtonPay'
              onClick={(e) => setopenSuccess(false)} >Got it</button>

          </div>
        </div>
      </BottomSheet>
      {
        Loadingstate ?
          <Loading />
          :
          <>
            <div className={!SearchClass ? 'SearchWrapper' : 'SearchWrapperActive'}>
              <div className='BackSearch'>
                {!SearchClass ?
                  <ArrowBackSharpIcon className='BackSearchIcon' onClick={(e) => { Back(e) }} />
                  :
                  <ArrowBackSharpIcon className='BackSearchIcon' onClick={(e) => { BlurSearchAndClear(e) }} />
                }
              </div>
              <div className='InputSearchChannel'>
                <input id="InputSearchChannelId" placeholder={!SearchClass ? 'Select or search' : 'Search or enter link'}
                  className={!SearchClass ? 'InputSearchDeActive' : 'InputSearchActive'}
                  onChange={(e) => { handleSearch(e.target.value); handleSearchGlobal(e.target.value) }}
                  onFocus={(e) => FocusSearch(e)}
                  // onBlur={(e) => { BlurSearch(e) }}
                  value={InputValue}
                  autoComplete="off"
                />
              </div>

              <div className='SearchIcon'>
                {!SearchClass ?
                  <SearchIcon />
                  : null}

              </div>
            </div>

          </>


      }

      {
        Loadingstate ?
          <Loading />
          :

          State.searchItems != null ?
            Searching.length > 0 ?


            NewSearchArray.map((chat, i) =>
                <SearchInChannels chat={chat}
                  Package={Package} key={i}
                  CheckChannelBeforeFunction={CheckChannelBeforeFunction} />

              )

              // SearchingLocal.map((chat, i) =>
              //   <SearchInChannels 
              //   chat={chat} Package={Package}
              //    key={i} CheckChannelBeforeFunction={CheckChannelBeforeFunction} />

              //   )


              :

              <div className='NotResult'>
                No results found!
              </div>

            :

            ChatList.length > 0 ?
              ChatList.map((chat, i) =>


                <>

                  <div
                    onClick={(e) => { CheckChannelBeforeFunction(chat.id) }}
                    className='ListOfChannel' key={i} >

                    <div className='Itemchannel'>

                      <div className='ImageAndDec'>
                        <div>
                          {
                            chat ?
                              chat.photo ?

                                <img className='Channelimg'
                                  src={getSrc(chat.photo.big) ?
                                    getSrc(chat.photo.big) :
                                    getSrc(chat.photo.small) ?
                                      getSrc(chat.photo.small) :
                                      `data:image/jpeg;base64,${chat.photo.minithumbnail.data}`
                                  } />
                                :
                                <div className='NotProfilePhoto1'>
                                  <span className='NotProfilePhotoLetter1'>

                                    {
                                      getChatLetters(chat.id)

                                    }

                                  </span>
                                </div>
                              :
                              <Skeleton variant="circle" width={56} height={56} className='Skeletoncircular' >
                              </Skeleton>
                          }
                        </div>


                      </div>
                      <div className='itemDesAndButton'>
                        <div className='itemDescription'>
                          <div className="UsernameAndIcon">
                            <div>
                              {
                                isChannelChat(chat.id) ?
                                  <img className='IconChannel' src={ChannelIcon} /> :
                                  <img className='IconChannel' src={GroupIcon} />
                              }
                            </div>
                            <div className='ChannelTitleText'>
                              {

                                getChatTitle(chat.id).length > 16 ?
                                  getChatTitle(chat.id).substr(0, 16) + '...' :
                                  getChatTitle(chat.id)
                              }
                            </div>
                          </div>
                          <div className='ChannelSubtitleText'>
                            <span className='ChannelIsPrivate'>
                              {
                                chat.private ? "Private ." : null
                              }
                            </span>
                            <span className='ChannelIsPrivate'>
                              {
                                chat.admin > 0 ? "Admin ." : null
                              }
                            </span>
                            <span>
                              {
                                getChatSubtitle(chat.id)

                              }
                            </span>
                          </div>
                        </div>
                        <button className='ButoonOrder' >
                          Get
                        </button>
                      </div>

                    </div>

                  </div>




                </>
              )


              :
              <Loading />
      }

    </div>
  )
}

export default Channels