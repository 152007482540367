import React from 'react';

import './Loading.css';


function JoinBot({ props }) {


    return (
        <div className='LoadingContainer'>
            <div className="load"></div>
        </div>

    );
}

export default JoinBot;